import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'commons/pipes/pipes.module';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { QuillModule } from 'ngx-quill';
import { ScCompanyCardV2Component } from 'shared/solution-center-v2/company-card/company-card.component';
import { ImageSizeSliderComponent } from './image-size-slider/image-size-slider.component';
import { ImagePositionComponent } from "./image-position/image-position.component";
import { ScCompanyFullCardV2Component } from 'shared/solution-center-v2/company-full-card/company-full-card.component';
import { ScCfcV2OverviewComponent } from 'shared/solution-center-v2/company-full-card/partials/overview.component';

const exportComponents = [
    ScCompanyCardV2Component,
    ScCompanyFullCardV2Component,
    ScCfcV2OverviewComponent,
    ImageSizeSliderComponent,
    ImagePositionComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
        DirectivesModule,
        QuillModule,
        PipesModule,
    ],
    declarations: [
        ...exportComponents,
    ],
    providers: [
    ],
    exports: [
        ...exportComponents,
    ],
})
export class ShareSolutionCenterV2Module {
}
