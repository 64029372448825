<div>
    <div class="gray-head flex-head remote-resource" [class.resolved]="!resolving">
        <div class="col-fit">
            <h2>
                <span *ngIf="!resolving">{{product.productName}}</span>
            </h2>
        </div>
        <div class="col-min">
            <ul *ngIf="!resolving" class="tab-menu col-min">
                <li *ngFor="let tab of productDetailsTabs" [class.active]="isTabSelected(tab.stateId)">
                    <a [uiSref]="tab.stateId">
                        <b>{{tab.title}}</b>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <ui-view></ui-view>
</div>

