import { ChangeDetectionStrategy, Component, computed, inject, input, model, output, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIRouterGlobals } from '@uirouter/core';
import { Params } from '@angular/router';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { ChannelsSummary } from 'lender/clients/channels/channels-summary.interface';
import { channelForm } from 'lender/clients/channels/new-channel-card/channel.form';
import { NewInlineEditComponent } from 'commons/components/inline-edit/inline-edit.component';
import { ChannelCardService } from 'lender/clients/channels/channel-card.service';
import { DatePipe } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { MinDateValidator } from 'commons/validators/date.validator';

@Component({
    selector: 'new-channel-card',
    templateUrl: './new-channel-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewChannelCardComponent {
    expanded = input<boolean>(false);
    lenderInfo = input.required<AccountInformation>();
    clientId = computed(() => this.lenderInfo().identifier.tpoId);
    channel = model<ChannelsSummary>(null);
    protected minLength = 10;

    protected user: Signal<UserProfile> = toSignal(inject(UserService).profile$);
    protected params: Signal<Params> = toSignal(inject(UIRouterGlobals).params$);
    protected channelCardService = inject(ChannelCardService);

    canManage = computed(() => this.user().can('EDIT_RELATIONSHIP_STATUS'));
    canManageEligibility = computed(() => this.canManage() && this.channel().eligibilityDateEditable);
    form = channelForm();
    update = output();
    tomorrow: Date;

    constructor() {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        this.tomorrow = date;
        this.form.get('eligibilityDate').setValidators([MinDateValidator(this.tomorrow)]);
    }


    protected inlineConfig = (name: string) => {
        const control = this.form.get(name);
        const channel = this.channel();

        return {
            name,
            control,
            value: { [name]: channel?.[name] },
        };
    };

    protected patch = async (value: Partial<ChannelsSummary>, inlineRef: NewInlineEditComponent) => {
        inlineRef.resolved.set(false);
        try {
            const changes = await this.saveChanges(value);
            this.channel.update(oldValue => ({ ...oldValue, ...changes }));
            this.update.emit();
            inlineRef.cancel();
        } catch ({ error }) {
            inlineRef.setServerErrors(error);
            inlineRef.resolved.set(true);
        }
    };

    protected async saveChanges(value: Partial<ChannelsSummary>): Promise<Partial<ChannelsSummary>> {
        const { channelId } = this.channel();
        const { organization: { id: investorId } } = this.user();
        const { id: lenderId } = this.params();
        const { eligibilityDate } = value;
        const fields = Object.keys(value);

        if (fields.includes('eligibilityDate')) {
            const dateString = eligibilityDate ? new DatePipe('en-US').transform(eligibilityDate, 'MM/dd/yyyy') : null;
            return firstValueFrom(this.channelCardService.updateEligibilityDate(investorId, lenderId, channelId, dateString));
        }

        return {};
    }
}
