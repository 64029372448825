import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { RealmHttpClient } from 'commons/services/http';
import { Line } from 'commons/components/image-cropper/image-cropper.component';

import { UserService } from 'angularjs-providers/user.provider';
import {
    SCCompanyInfo,
    Tag,
    LoanProgram,
} from 'shared/solution-center-v2/solution-center.common';

export interface InvestorCompanyInfo extends SCCompanyInfo {
    originalCompanyLogo?: string;
    companyLogoCoordinates: Line;
    isCompanyLogoModified?: boolean;
    visible: boolean;
}

export interface InvestorCompanyOverview {
    organizationId: number;
    description: string;
    hasMarketingLogo: boolean;
    marketingLogoCoordinates: {
        end: {
            x: number;
            y: number;
        };
        start: {
            x: number;
            y: number;
        };
    };
    marketingLogoPageWidth: number;
    marketingLogo?: string;
    originalMarketingLogo?: string;
    isMarketingLogoModified?: boolean;
    marketingLogoImagePosition: 'LEFT' | 'CENTER' | 'RIGHT';
}

// export type SCProduct = {
//     email: string;
//     productDescription: string;
//     productId: number
//     productName: string;
//     type: string; // maybe change to enum?
//     visibilityAllowedByComergence: boolean;
//     visibilityAllowedByCustomer: boolean;
//     loading?: boolean;
//     productPlanId: number;
//     paymentPlanStatus: PlanPaymentStatus;
// }
//
// export enum CallToAction {
//     CONTACT_US = 'MARKETING',
//     SIGN_UP = 'INTEGRATION'
// }

export const SupportedFileTypes = [
    {
        type: 'Image',
        regexp: /^.+\.(JPG|JPE|JPEG|PNG|WEBP)$/i,
    },
];

const processCompanyInfo = (card: InvestorCompanyInfo): InvestorCompanyInfo => {
    const { hasCompanyLogo, organizationId } = card;
    const ts = Date.now();
    return {
        ...card,
        ...hasCompanyLogo ? {
            originalCompanyLogo: `/api/rest/organizations/${organizationId}/solution-center/company-logo/original?nocache=${ts}`,
            companyLogo: `/api/rest/organizations/${organizationId}/solution-center/company-logo?nocache=${ts}`,
        } : {},
    };
}

const processCustomerOverview = (overview: InvestorCompanyOverview): InvestorCompanyOverview => {
    const { hasMarketingLogo, organizationId } = overview;
    const ts = Date.now();
    return {
        ...overview,
        ...hasMarketingLogo ? {
            originalMarketingLogo: `/api/rest/organizations/${organizationId}/solution-center/marketing-logo/original?nocache=${ts}`,
            marketingLogo: `/api/rest/organizations/${organizationId}/solution-center/marketing-logo?nocache=${ts}`,
        } : {},
    };
}

declare let apiPath: string;

@Injectable()
export class InvestorSolutionCenterV2Service {
    private readonly profile = inject(UserService).profile;
    private readonly organizationId: number = this.profile.organization.organizationId;
    private readonly lenderId: number = this.profile.organization.id;
    private readonly http: RealmHttpClient = inject(RealmHttpClient);

    getCustomerData(): Observable<InvestorCompanyInfo> {
        return this.http.request<InvestorCompanyInfo>(
            'GET',
            `${apiPath}/organizations/${this.organizationId}/solution-center/company-info`,
        ).pipe(
            map(processCompanyInfo),
            shareReplay(1),
        );
    }

    async urlToBlob(url: string): Promise<Blob> {
        return await fetch(url).then(r => r.blob());
    }

    async updateCustomerData({ companyLogo, originalCompanyLogo, ...overviewData }: InvestorCompanyInfo): Promise<Observable<InvestorCompanyInfo>> {
        const formData = new FormData();
        const {isCompanyLogoModified} = overviewData;
        if (isCompanyLogoModified && originalCompanyLogo) {
            formData.append('companyLogo', await this.urlToBlob(companyLogo), 'companyLogo.png');
            formData.append('originalCompanyLogo', await this.urlToBlob(originalCompanyLogo), 'originalCompanyLogo.png');
        }

        formData.append(
            'companyInfo',
            new Blob(
                [JSON.stringify(overviewData)],
                { type: 'application/json' }),
        );

        return this.http.requestWithHeaders<InvestorCompanyInfo>(
            'PUT',
            `${apiPath}/organizations/${this.organizationId}/solution-center/company-info`,
            {},
            formData,
            {
                'enctype': 'multipart/form-data',
            },
        ).pipe(
            map(processCompanyInfo),
        );
    }

    updateVisibility(visible: boolean): Observable<void> {
        return this.http.request<void>(
            'PUT',
            `${apiPath}/organizations/${this.organizationId}/solution-center/visible`,
            {},
            { visible },
        );
    }

    getAvailableTags(): Observable<Tag[]> {
        return this.http.request<Tag[]>(
            'GET',
            `${apiPath}/organizations/${this.organizationId}/solution-center/available-tags`,
        );
    }

    getAvailableLoanPrograms(): Observable<LoanProgram[]> {
        return this.http.request<LoanProgram[]>(
            'GET',
            `${apiPath}/organizations/${this.organizationId}/solution-center/available-loan-programs`,
        );
    }

    getCompanyOverview(): Observable<InvestorCompanyOverview> {
        return this.http.request<InvestorCompanyOverview>(
            'GET',
            `${apiPath}/organizations/${this.organizationId}/solution-center/overview`,
        ).pipe(
            map(processCustomerOverview),
            shareReplay(1),
        );
    }

    async updateCustomerOverview({ marketingLogo, originalMarketingLogo, ...companyOverview }: InvestorCompanyOverview): Promise<Observable<InvestorCompanyOverview>> {
        const formData = new FormData();
        const {isMarketingLogoModified} = companyOverview;
        if (isMarketingLogoModified && originalMarketingLogo) {
            formData.append('marketingLogo', await this.urlToBlob(marketingLogo), 'marketingLogo.png');
            formData.append('originalMarketingLogo', await this.urlToBlob(originalMarketingLogo), 'originalMarketingLogo.png');
        }

        formData.append(
            'companyOverview',
            new Blob(
                [JSON.stringify(companyOverview)],
                { type: 'application/json' }),
        );

        return this.http.requestWithHeaders<InvestorCompanyOverview>(
            'PUT',
            `${apiPath}/organizations/${this.organizationId}/solution-center/overview`,
            {},
            formData,
            {
                'enctype': 'multipart/form-data',
            },
        )
    }

    // saveProductOverview(data: SCProductOverview): Observable<SCProductOverview> {
    //     return this.http.request<SCProductOverview>(
    //         'PUT',
    //         `${apiPath}/customer-products/${this.organizationId}/products-overview`,
    //         {},
    //         data,
    //     );
    // }
    //
    // getProductList(): Observable<SCProduct[]> {
    //     return this.http.request<SCProduct[]>(
    //         'GET',
    //         `${apiPath}/customer-products/${this.organizationId}/product-information-list`,
    //     );
    // }
    //
    // updateProductVisibility(productId: number, isVisible: boolean): Observable<SCProduct> {
    //     return this.http.request<SCProduct>(
    //         'PUT',
    //         `${apiPath}/customer-products/${this.organizationId}/product/${productId}/visibility`,
    //         null,
    //         isVisible,
    //     )
    // }
    //
    // updateProductDescriptionAndType(updatedProduct: SCProduct) {
    //     return this.http.request<SCProduct>(
    //         'PUT',
    //         `${apiPath}/customer-products/${this.organizationId}/product/${updatedProduct.productId}/description-and-type`,
    //         null,
    //         updatedProduct,
    //     )
    // }
    //
    // getActiveChannels(): Observable<LenderChannel[]> {
    //     return this.http.request<LenderChannel[]>(
    //         'GET',
    //         `${apiPath}/lenders/${this.lenderId}/channels/preview`,
    //     ).pipe(
    //         map(channels => processChannel(channels)),
    //     );
    // }
}
