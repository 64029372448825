<div class="remote-resource" [class.resolved]="!resolving">
    <div *ngFor="let group of entitiesGroupedByType" >
        <h3>
            {{ group.entityTypeName }}
            <div *ngIf="userCanAddEntity" class="pull-right">
                <button class="btn btn-secondary" (click)="addNewEntity(group.entityTypeId)">
                    <span class="glyphicon glyphicon-plus small-icon"></span>
                    Add
                </button>
            </div>
        </h3>

        <!-- TODO: When other types of entities are implemented, use templates to configure the layout -->
        <ng-container *ngTemplateOutlet="workspace; context: { entities : group.entities}"></ng-container>
    </div>
</div>

<ng-template #workspace let-entities="entities">
    <div class="rows-bordered">
        <div class="row-fluid header">
            <div class="col-md-6 va-m">Name</div>
        </div>
        <div *ngFor="let entity of entities; let i = index" class="row">
            <individual-product-entity-row
                [entity]="entity"
                [editing]="!entity.productId"
                [userCanAddEntity]="userCanAddEntity"
                [canBeDeleted]="entities.length > 1"
                (removeEntity)="removeEntityRow(entities, i)"
                (updateEntity)="updateEntity($event, entities, i)"
            >
            </individual-product-entity-row>
        </div>
    </div>
    <br />
</ng-template>
