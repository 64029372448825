import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { PipesModule } from 'commons/pipes/pipes.module';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { LenderSolutionCenterV2Service } from 'tpo/solution-center-v2/solution-center.service';
import { LenderSolutionCenterListV2Component } from 'tpo/solution-center-v2/list/solution-center-list.component';
import { ShareSolutionCenterV2Module } from 'shared/solution-center-v2/solution-center.module';
import { CompanyCardContainerComponent } from 'tpo/solution-center-v2/single/company-card-container.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
        DirectivesModule,
        ShareSolutionCenterV2Module,
        PipesModule,
    ],
    declarations: [
        LenderSolutionCenterListV2Component,
        CompanyCardContainerComponent,
    ],
    providers: [
        LenderSolutionCenterV2Service,
    ],
})
export class LenderSolutionCenterV2Module {
}
