<div class="column-padding">
    <h4>
        Select payment method
    </h4>
    <div [ngSwitch]="paymentMethodSelection()">
        <label class="display-block margin-bottom-sm text-light">
            <input type="radio" name="payment_method" [value]="'OP'" [ngModel]="paymentMethodSelection()" (ngModelChange)="changePaymentType($event)" > Use card on file
        </label>
        <br *ngSwitchCase="'OP'">
        <div [class.hidden]="paymentMethodSelection() == 'NP'">
            <form [formGroup]="subscriptionExistingPaymentForm" id="subscriptionExistingPaymentForm" (onValidSubmit)="purchasePlanWithExistingPaymentMethod()">
                <form-errors [form]="subscriptionExistingPaymentForm"></form-errors>
                <div class="row-fluid">
                    <div class="col-md-12 payments-method-selector form-group row" has-error>
                        <ng-select
                            formControlName="paymentMethodId"
                            [items]="paymentMethods()"
                            placeholder="Select Card"
                            bindValue="id"
                        >
                            <ng-template ng-label-tmp let-item="item">
                                <span class="pull-left">{{ item.nickname }}</span>
                                <span class="pull-right" >ending in {{ item.lastFourDigits }}</span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item">
                                <span class="pull-left">{{ item.nickname }}</span>
                                <span class="pull-right">ending in {{ item.lastFourDigits }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                &nbsp;
                <div class="row-fluid">
                    <div class="col-sm-6 form-group" has-error>
                        <label class="control-label">{{subscriptionExistingPaymentForm.get('poNumber').label}}</label>
                    </div>
                    <div class="col-sm-6 form-group" has-error>
                        <label class="control-label">{{subscriptionExistingPaymentForm.get('costCenter').label}}</label>
                    </div>
                </div>
                <div class="row-fluid">
                    <div class="col-sm-6 form-group" has-error>
                        <input type="text" class="form-control" formControlName="poNumber" />
                    </div>
                    <div class="col-sm-6 form-group" has-error>
                        <input type="text" class="form-control" formControlName="costCenter" />
                    </div>
                </div>
            </form>
        </div>
        <br *ngSwitchCase="'OP'">
        <label class="display-block margin-bottom-sm text-light">
            <input type="radio" [value]="'NP'" name="payment_method" [ngModel]="paymentMethodSelection()" (ngModelChange)="changePaymentType($event)" > Add payment method
        </label>
    </div>
    <br/>
    <div [class.hidden]="paymentMethodSelection() == 'NP'">
        <div class="row-fluid">
            <div class="col-sm-12 ">
                <button type="submit" class="btn btn-lg btn-primary text-center sub-button" form="subscriptionExistingPaymentForm">Purchase</button>
            </div>
        </div>
        &nbsp;
        <div class="row-fluid">
            <div class="col-sm-12">
                <a uiSref="solution-center" class="btn btn-lg btn-default text-center sub-button">Cancel</a>
            </div>
        </div>
    </div>
    <div [class.hidden] ="paymentMethodSelection() == 'OP'">
        <form [formGroup]="subscriptionPaymentForm" (onValidRecurlySubmit)="purchasePlanWithNewPaymentMethod()">
            <form-errors [form]="subscriptionPaymentForm"></form-errors>
            <div class="row-fluid">
                <div class="col-sm-6 form-group" has-error>
                    <label class="control-label required">{{subscriptionPaymentForm.get('first_name').label}}</label>
                </div>
                <div class="col-sm-6 form-group" has-error>
                    <label class="control-label required">{{subscriptionPaymentForm.get('last_name').label}}</label>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-sm-6 form-group" has-error>
                    <input type="text" class="form-control" data-recurly="first_name" formControlName="first_name" />
                </div>
                <div class="col-sm-6 form-group" has-error>
                    <input type="text" class="form-control" data-recurly="last_name" formControlName="last_name" />
                </div>
            </div>
            &nbsp;
            <div class="row-fluid">
                <div class="col-sm-12 form-group" has-error>
                    <label class="control-label required">{{subscriptionPaymentForm.get('paymentMethodNickname').label}}</label>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-sm-12 form-group" has-error>
                    <input type="text" class="form-control" data-recurly="nick_name" formControlName="paymentMethodNickname" />
                </div>
            </div>
            &nbsp;
            <div class="row-fluid">
                <div class="col-sm-12 form-group" has-error>
                    <label class="control-label required">{{subscriptionPaymentForm.get('email').label}}</label>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-sm-12 form-group" has-error>
                    <input type="text" class="form-control" data-recurly="email" formControlName="email" />
                </div>
            </div>
            &nbsp;
            <div class="row-fluid">
                <div class="col-sm-12 form-group" has-error>
                    <label class="control-label required">{{subscriptionPaymentForm.get('card').label}}</label>
                </div>
            </div>
            <div class="row-fluid">
                 <div class="col-sm-12" [recurlyError]="subscriptionPaymentForm.get('card')" [class.has-error]="subscriptionPaymentForm.get('card').showError">
                        <div data-recurly="card"></div>
                </div>
            </div>
            &nbsp;
            <div class="row-fluid">
                <div class="col-sm-6 form-group" has-error>
                    <label class="control-label">{{subscriptionPaymentForm.get('poNumber').label}}</label>
                </div>
                <div class="col-sm-6 form-group" has-error>
                    <label class="control-label">{{subscriptionPaymentForm.get('costCenter').label}}</label>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-sm-6 form-group" has-error>
                    <input type="text" class="form-control" data-recurly="poNumber" formControlName="poNumber" />
                </div>
                <div class="col-sm-6 form-group" has-error>
                    <input type="text" class="form-control" data-recurly="costCenter" formControlName="costCenter" />
                </div>
            </div>
            &nbsp;
            <div class="row-fluid">
                <div class="col-sm-12 ">
                    <button type="submit" class="btn btn-lg btn-primary text-center sub-button">Purchase</button>
                </div>
            </div>
            &nbsp;
            <div class="row-fluid">
                <div class="col-sm-12">
                    <a uiSref="solution-center" class="btn btn-lg btn-default text-center sub-button">Cancel</a>
                </div>
            </div>
        </form>
    </div>
    <br/>
    <div class="row-fluid">
        <div class="col-sm-12">
            <span class="text-smaller">You can review important information from Comergence, LLC. on their <b>Terms of Service and Privacy Policy</b> pages.</span>
        </div>
    </div>
    <div class="row-fluid">
        <div class="col-sm-12">
            <span class="text-smaller">By providing your credit card information you allow Comergence, LLC. to charge your card for future payments in accordance with their terms.</span>
        </div>
    </div>
</div>
