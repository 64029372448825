import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

type InputDate = string | number | Date;
const processDate = (date: InputDate): Date => {
    const newDate = typeof date === 'string' ? new Date(Date.parse(date)) : typeof date === 'number' ? new Date(date) : date;
    if (!(newDate instanceof Date) || isNaN(newDate.getTime())) throw new Error('Invalid date');
    newDate.setHours(0, 0, 0, 0);
    return newDate;
}

export function MinDateValidator(rawTargetDate: InputDate): ValidatorFn {
    const targetDate = processDate(rawTargetDate);

    return (control: AbstractControl): ValidationErrors | null => {
        const { value } = control;
        if (processDate(value) < targetDate) {
            return {
                date: {
                    value,
                    message: `Invalid entry`,
                },
            }
        }
        return null;
    };
}

export function MaxDateValidator(rawTargetDate: InputDate): ValidatorFn {
    const targetDate = processDate(rawTargetDate);

    return (control: AbstractControl): ValidationErrors | null => {
        const { value } = control;
        if (processDate(value) > targetDate) {
            return {
                date: {
                    value,
                    message: `Invalid entry`,
                },
            }
        }
        return null;
    };
}
