import { isEqual } from 'lodash';
import { firstValueFrom } from 'rxjs';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    effect,
    ElementRef,
    HostListener,
    inject,
    OnDestroy,
    Signal,
    signal,
    untracked,
    viewChild,
    WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Transition, UIRouterGlobals } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { InvestorCompanyInfo, InvestorCompanyOverview, InvestorSolutionCenterV2Service } from 'lender/solution-center-v2/solution-center.service';
import { alwaysVisibleTabs, SC_CARD_TAB, SC_TABS } from 'shared/solution-center-v2/solution-center.common';
import '@uirouter/rx/lib/core.augment';
import { SCFullCompany } from 'tpo/solution-center-v2/solution-center.service';
import { ConfirmModalComponent } from 'commons/components/modals';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

@Component({
    selector: 'solution-center-v2',
    templateUrl: './solution-center.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolutionCenterV2Component implements AfterViewInit, OnDestroy {
    public companyInfo: WritableSignal<InvestorCompanyInfo> = signal<InvestorCompanyInfo>(null, { equal: isEqual });
    public overview: WritableSignal<any> = signal<InvestorCompanyOverview>(null, { equal: isEqual });
    public fullCompany = computed<SCFullCompany>(() => {
        const companyInfo = this.companyInfo();
        const overview = this.overview() || {} as InvestorCompanyOverview;
        return { ...companyInfo, ...overview } as SCFullCompany;
    });
    // public productList: WritableSignal<SCProduct[]> = signal<SCProduct[]>(null, { equal: isEqual });
    // public channelList: WritableSignal<LenderChannel[]> = signal<LenderChannel[]>(null, { equal: isEqual });
    public resolving = signal(false);

    public tabList = SC_TABS;
    public modalRef: BsModalRef;

    public User: Signal<UserProfile> = toSignal(inject(UserService).profile$);
    protected success: Signal<Transition> = toSignal(inject(UIRouterGlobals).success$);
    public currentTab = computed(() => {
        const state = this.success()?.targetState()?.name();
        const tab = SC_TABS.find(({ investorRoute }) => state === investorRoute);
        return tab?.code;
    });
    public visibleTabs = computed(() => {
        const { availableTabs = [] } = this.companyInfo() || {};
        const tabs = [...alwaysVisibleTabs, ...availableTabs];
        return Object.fromEntries(tabs.map(value => [value, true]));
    });

    public previewVisible = signal(true);
    previewScale = signal<number>(1.0);
    previewMaxHeight = signal<string>('100%');
    private scalableElement = viewChild('scalableElement', { read: ElementRef });
    @HostListener('window:resize') private onResize() {
        this.updateScale();
    }

    public isVisibilityBlocked = signal(false);
    public visibilityResolving = signal(false);

    protected readonly modalService = inject(BsModalService);
    protected readonly investorSolutionCenterService = inject(InvestorSolutionCenterV2Service);
    protected readonly cd = inject(ChangeDetectorRef);
    protected readonly notificationService = inject(GlobalNotificationsService);

    constructor() {
        this.loadCompanyInfo();
        effect(() => {
            const tab = this.currentTab();

            untracked(() => {
                switch (tab) {
                    case SC_CARD_TAB.OVERVIEW:
                         return this.loadOverview();
                    // case SC_CARD_TAB.PRODUCTS:
                    //     return this.loadProducts();
                    // case SC_CARD_TAB.CHANNELS:
                    //     return this.loadChannels();
                }
            })
        });
    }

    async loadOverview() {
        if (this.overview()) return;
        this.resolving.set(true);
        this.overview.set(await firstValueFrom(this.investorSolutionCenterService.getCompanyOverview()));
        this.resolving.set(false);
       // this.updateVisibilityToggle();
    }

    private observer: ResizeObserver;
    ngAfterViewInit() {
        this.observer = new ResizeObserver(() => this.updateScale());
        this.observer.observe(this.scalableElement().nativeElement as Element);
    }

    ngOnDestroy() {
        this.observer.disconnect();
    }

    togglePreview() {
        this.previewVisible.set(!this.previewVisible());
    }

    updateScale() {
        if (!this.scalableElement() || !this.previewVisible()) return;
        const padding = 30;
        const el = this.scalableElement().nativeElement as Element;
        const scale = (el.parentElement.clientWidth - padding * 2) / el.clientWidth;
        const height = Math.ceil(el.clientHeight * scale);
        this.previewScale.set(Math.min(scale, 1));
        this.previewMaxHeight.set(`${height}px`);
        this.cd.detectChanges();
    }

    async loadCompanyInfo() {
        if (this.companyInfo()) return;
        this.resolving.set(true);
        this.companyInfo.set(await firstValueFrom(this.investorSolutionCenterService.getCustomerData()));
        this.resolving.set(false);
        // this.updateVisibilityToggle();
    }

    updateCard(delta: Partial<InvestorCompanyInfo>) {
        this.companyInfo.update(card => ({ ...card, ...delta }));
    }

    updateOverview(delta: Partial<InvestorCompanyOverview>) {
        this.overview.update(overview => ({ ...overview, ...delta }));
    }

    // async loadChannels() {
    //     if (this.channelList()) return;
    //     this.resolving.set(true);
    //     this.channelList.set(await firstValueFrom(this.investorSolutionCenterService.getActiveChannels()));
    //     this.resolving.set(false);
    //     this.updateVisibilityToggle();
    // }
    //
    // async loadProducts() {
    //     if (this.productOverview()) return;
    //     this.resolving = true;
    //     const [ overview, list] = await Promise.all([
    //         await firstValueFrom(this.investorSolutionCenterService.getProductOverview()),
    //         await firstValueFrom(this.investorSolutionCenterService.getProductList()),
    //     ]);
    //     this.productOverview.set(overview);
    //     this.productList.set(list);
    //     this.resolving = false;
    // }


    async changeVisibility() {
        const { visible } = this.companyInfo();
        try {
            this.visibilityResolving.set(true);
            await firstValueFrom(this.investorSolutionCenterService.updateVisibility(!visible));
            this.companyInfo.update(card => ({ ...card, ...{ visible: !this.companyInfo().visible } }));
        } catch ({error: { message }}) {
            this.notificationService.add({ type: GlobalNotificationType.ERROR, message });
        } finally {
            this.visibilityResolving.set(false);
        }
    }

    blockVisibilityToggle(state: boolean) {
        this.isVisibilityBlocked.set(state);
    }

    changeVisibilityConfirmModal(): void {
        this.modalRef = this.modalService.show<ConfirmModalComponent>(
            ConfirmModalComponent,
            {
                initialState: {
                    title: 'Solution Center',
                    message: 'Clients will not see you in the Solution Center. Are you sure you want to proceed?',
                    confirmText: 'Yes',
                    onConfirm: () => {
                        this.changeVisibility();
                        this.modalRef.hide();
                    },
                },
                class: 'modal-smd modal-new',
            },
        );
    }

    onChangeVisibility() {
        if (this.companyInfo().visible) {
            this.changeVisibilityConfirmModal();
            return;
        }
        this.changeVisibility();
    }
    protected readonly SC_CARD_TAB = SC_CARD_TAB;
}
