import { Component, OnInit } from '@angular/core';
import { TpoProduct } from 'shared/tpo-products/tpo-product.interface';
import { TpoProductsService } from 'shared/tpo-products/tpo-products.service';
import { StateService, UIRouter } from '@uirouter/core';
import { firstValueFrom } from 'rxjs';
import { UserService } from 'angularjs-providers/user.provider';
import { ProductNameService } from 'shared/tpo-products/product-details/product-name.service';

@Component({
    templateUrl: 'tpo-product-details.component.html'
})
export class TpoProductDetailsComponent implements OnInit {

    productId: number
    product: TpoProduct;
    tpoId: number;
    resolving: boolean = false;
    productDetailsTabs: { title: string, stateId: string } [] = [
        {
            title: 'Billing Settings',
            stateId: '.settings'
        },
        {
            title: 'User Licenses',
            stateId: '.user-licenses'
        },
        {
            title: 'Add-Ons',
            stateId: '.add-ons'
        },
    ]

    constructor(
        private tpoProductsService: TpoProductsService,
        private readonly uiRouter: UIRouter,
        private readonly stateService: StateService,
        private readonly userService: UserService,
        private productNameService: ProductNameService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.tpoId = this.uiRouter.globals.params.id || this.userService.profile.organization.id;
        this.productId = this.uiRouter.globals.params.productId;
        await this.retrieveProduct();
    }

    private async retrieveProduct(): Promise<void> {
        try {
            this.resolving = true;
            this.product = await firstValueFrom(this.tpoProductsService.getProductByTpoId(this.tpoId, this.productId));
            this.productNameService.productName.set(this.product.productName);
        } finally {
            this.resolving = false;
        }
    }

    protected isTabSelected(stateId : string): boolean {
        return this.stateService.includes(`**${stateId}`);
    }

}
