import { Component, forwardRef } from '@angular/core';
import { ListParams, NewPagedListComponent } from 'commons/components/new-list/list.component';
import { TpoOrganizationUser } from 'shared/tpo-products/tpo-product.interface';
import { UIRouter } from '@uirouter/core';
import { ListComponent } from 'commons/components/list';
import { TpoProductsService } from 'shared/tpo-products/tpo-products.service';
import { Observable } from 'rxjs';
import { PagedData } from 'commons/services/http';
import { UserService } from 'angularjs-providers/user.provider';
import { LenderUserPanelProductAccessModalComponent } from 'tpo/people/products/lender-user-panel-product-access.modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LenderUserPanelProductLicenseStatus } from 'tpo/people/products/lender-user-panel-products.interface';
import { ProductNameService } from 'shared/tpo-products/product-details/product-name.service';

@Component({
    selector: 'tpo-product-user-licenses-list',
    templateUrl: 'tpo-product-user-licenses.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => TpoProductUserLicensesComponent) },
        { provide: NewPagedListComponent, useExisting: forwardRef(() => TpoProductUserLicensesComponent) },
    ],
})
export class TpoProductUserLicensesComponent extends NewPagedListComponent<TpoOrganizationUser> {
    static listName: string = 'tpoProductUserLicensesList';
    productId: number;
    tpoId: number;
    canManagePayments: boolean;
    productName: string;

    constructor(
        private tpoProductsService: TpoProductsService,
        private readonly uiRouter: UIRouter,
        private readonly userService: UserService,
        private readonly modalService: BsModalService,
        private productNameService: ProductNameService,
    ) {
        super(uiRouter);
        this.tpoId = this.userService.profile.organization.id;
        this.productId = this.uiRouter.globals.params.productId;
        this.canManagePayments = this.userService.profile.can('TPO_MANAGE_PAYMENTS');
    }

    protected loadList(params: ListParams): Observable<PagedData<TpoOrganizationUser[]>> {
        return this.tpoProductsService.getActiveUsersLicense(this.tpoId, this.productId, params);
    }

    openAssignLicenseModal() {
        this.modalService.show(
            LenderUserPanelProductAccessModalComponent,
            {
                initialState: {
                    productId: this.productId,
                    title: 'Assign License',
                    licenseStatus: 'I' as LenderUserPanelProductLicenseStatus,
                    showUserList: true,
                    reloadProducts: async () => {
                        this.updateList();
                    },
                },
                class: 'modal-new modal-smd',
                backdrop: 'static',
            }
        );
    }

    openManageLicenseModal(user: TpoOrganizationUser) {
        this.productName = this.productNameService.productName();
        this.modalService.show(
            LenderUserPanelProductAccessModalComponent,
            {
                initialState: {
                    productId: this.productId,
                    title: `Manage ${this.productName}`,
                    licenseStatus: 'A' as LenderUserPanelProductLicenseStatus,
                    contactId: user.userId,
                    showUserList: false,
                    reloadProducts: async () => {
                        this.updateList();
                    },
                },
                class: 'modal-new modal-smd',
                backdrop: 'static',
            }
        );
    }
}
