import { NgModule } from '@angular/core';
import { TpoProductsComponent } from './tpo-products.component';
import { TpoProductsService } from './tpo-products.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { TpoAddProductModalComponent } from './tpo-add-product.modal.component';
import { TpoProductSettingsComponent } from './product-details/product-settings/tpo-product-settings.component';
import { TpoProductDetailsComponent } from './product-details/tpo-product-details.component';
import { TpoProductAddOnsComponent } from './product-details/product-add-ons/tpo-product-add-ons.component';
import {
    IndividualProductEntityRowComponent
} from './product-details/product-add-ons/individual-product-entity-row/individual-product-entity-row.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
    TpoProductUserLicensesComponent
} from './product-details/product-user-licenses/tpo-product-user-licenses.component';
import { ProductNameService } from './product-details/product-name.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DirectivesModule,
        ComponentsModule,
        ReactiveFormsModule,
        BsDropdownModule,
    ],
    declarations: [
        TpoProductsComponent,
        TpoAddProductModalComponent,
        TpoProductAddOnsComponent,
        TpoProductDetailsComponent,
        TpoProductSettingsComponent,
        IndividualProductEntityRowComponent,
        TpoProductUserLicensesComponent,
    ],
    providers: [
        TpoProductsService,
        ProductNameService,
    ],
})
export class TpoProductsModule {
}
