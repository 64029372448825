<input
    #rangeInput
    type="range"
    min="30"
    max="100"
    step="10"
    [value]="size"
    (input)="onSizeChange($event)"
/>
<div class="images-row">
    <img src="/static/images/solution-center/image-size.svg" alt="Image Size" class="small-image" />
    <img src="/static/images/solution-center/image-size.svg" alt="Image Size" class="large-image" />
</div>
