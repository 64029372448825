<div class="remote-resource" [class.resolved]="!resolving">
    <h2>Products</h2>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <div class="col-md-4">
                Products
            </div>
            <div class="col-md-4">
                Plan Name
            </div>
            <div class="col-md-2">
                License Status
            </div>
            <div class="col-md-2"></div>
        </div>

        <div class="row-fluid" *ngFor="let item of list()">
            <div class="col-md-4 va-m">
                {{ item.productName }}
            </div>
            <div class="col-md-4 va-m">
                {{ item.productPlanName }}
            </div>
            <div class="col-md-2 va-m">
                {{ LenderUserPanelProductLicenseStatus[item.status] || 'Inactive' }}
            </div>
            <div class="col-md-2 va-m text-right">
                <button class="btn btn-default"
                        *ngIf="canManagePayments"
                        (click)="openProductLicenseModal(item.productId, item.productName, item.status)"
                >
                    Manage
                </button>
            </div>
        </div>

        <div class="row-fluid" *ngIf="!resolving && !list()?.length">
            <span class="col-md-12 text-muted text-center text-bigger">No products</span>
        </div>
    </div>
</div>
