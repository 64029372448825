import { Component, OnInit } from '@angular/core';
import { RealmFormArray, RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import {
    EntityListItem,
    LenderUserPanelProductLicenseStatus,
    LicenseStatusOptions
} from './lender-user-panel-products.interface';
import {
    GlobalNotificationsService,
    GlobalNotificationType
} from 'global-elements/notication-center/notifications.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LenderUserPanelProductsService } from './lender-user-panel-products.service';
import { CustomRequiredValidator } from 'commons/validators';
import { firstValueFrom } from 'rxjs';
import { isNil } from 'lodash';
import { TpoOrganizationUser } from 'shared/tpo-products/tpo-product.interface';

@Component({
    templateUrl: 'lender-user-panel-product-access.modal.component.html'
})
export class LenderUserPanelProductAccessModalComponent implements OnInit {
    contactId: number;
    productId: number;
    title: string;
    showUserList: boolean;
    licenseStatus: LenderUserPanelProductLicenseStatus;
    reloadProducts: () => Promise<void>;

    resolving: boolean;
    manageLicenseForm: RealmFormGroup;
    productEntityList: EntityListItem[];
    entitiesAssignedToUser: number[];
    organizationUser: TpoOrganizationUser[];


    constructor(
        private lenderUserPanelProductsService: LenderUserPanelProductsService,
        private readonly globalNotificationsService: GlobalNotificationsService,
        protected readonly modalRef: BsModalRef,
    ) {
    }

    async populateEntityList(): Promise<void> {
        this.productEntityList = await firstValueFrom(this.lenderUserPanelProductsService.getEntityListForProduct(this.contactId, this.productId));
    }

    async populateCurrentlyAssignedEntities(): Promise<void> {
        this.entitiesAssignedToUser = await firstValueFrom(this.lenderUserPanelProductsService.getEntitiesAssignedToContact(this.contactId, this.productId));
    }

    async getEntityList(): Promise<void> {
        this.productEntityList = await firstValueFrom(this.lenderUserPanelProductsService.getEntitiesGroupedByType(this.productId));
    }

    async getInactiveLicenseUsers(): Promise<void> {
        this.organizationUser = await firstValueFrom(this.lenderUserPanelProductsService.getInactiveLicenseUsers(this.productId));
    }

    async ngOnInit(): Promise<void> {
        if(!this.showUserList && !isNil(this.contactId)) {
            try {
                this.resolving = true;

                await Promise.all([this.populateCurrentlyAssignedEntities(), this.populateEntityList()]);

                this.initForm();
            } catch ({error: {message}, message: httpError}) {
                this.globalNotificationsService.add({
                    type: GlobalNotificationType.ERROR,
                    message,
                });
            } finally {
                this.resolving = false;
            }
        }
        if(this.showUserList) {
            try {
                this.resolving = true;

                await Promise.all([this.getEntityList(), this.getInactiveLicenseUsers()]);

                this.initForm();
            } catch ({error: {message}, message: httpError}) {
                this.globalNotificationsService.add({
                    type: GlobalNotificationType.ERROR,
                    message,
                });
            } finally {
                this.resolving = false;
            }
        }
    }

    close() {
        this.modalRef.hide();
    }

    initForm() {
        this.manageLicenseForm = new RealmFormGroup({
            licenseStatus: new RealmFormControl(
                'licenseStatus', { label: 'License Status', value: this.licenseStatus }, Validators.required,
            )
        })

        if (this.showUserList) {
            this.manageLicenseForm.addControl(
                'contactId',
                new RealmFormControl(
                    'contactId',
                    {label: 'Users', updateOn: 'change',},
                    Validators.required
                )
            );
        }

        const entitiesFormControl = new RealmFormArray([]);
        const entityListDisabled =
            LenderUserPanelProductLicenseStatus[this.licenseStatus] === LenderUserPanelProductLicenseStatus.I;

        this.productEntityList.forEach(entityType => entitiesFormControl.push(
            new RealmFormControl(
                entityType.entityTypeName,
                {
                    label: entityType.entityTypeName,
                    updateOn: 'change',
                    disabled: entityListDisabled,
                    value: entityType.entities
                        .map(entity => entity.entityId)
                        .filter(id => this.entitiesAssignedToUser?.includes(id)),
                },
                CustomRequiredValidator('Please select at least one value for <b>{field}</b>'),
            )
        ));

        this.manageLicenseForm.addControl('entities', entitiesFormControl);
    }

    async updateLicense(): Promise<void> {
        const formValue = this.manageLicenseForm.value;
        const entityIds = formValue.entities?.flat(1);
        if(this.showUserList) {
            this.contactId = formValue.contactId;
        }

        const licenseStatus = formValue.licenseStatus as LenderUserPanelProductLicenseStatus;

        try {
            this.resolving = true;

            await firstValueFrom(
                this.lenderUserPanelProductsService.updateLicenseStatusAndAssignedEntities(
                    this.contactId, this.productId, licenseStatus, entityIds
                )
            );

            await this.reloadProducts();

            this.close();
        } catch ({error: {message}, message: httpError}) {
            this.manageLicenseForm.setServerError({ message: message });
        } finally {
            this.resolving = false;
        }
    }

    toggleEntitiesControlBasedOnLicenseStatus(status: string): void {
        const selectedStatus = LenderUserPanelProductLicenseStatus[status];
        const entitiesFormControl = this.manageLicenseForm.get('entities');

        switch (selectedStatus) {
            case LenderUserPanelProductLicenseStatus.A:
                entitiesFormControl.enable();
                break;
            case LenderUserPanelProductLicenseStatus.I:
            default:
                entitiesFormControl.disable();
                break;
        }
    }

    trackByIndex = (index: number): number => index;

    protected readonly licenseStatusOptions = LicenseStatusOptions;
}
