import { Validators } from '@angular/forms';

import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import {
    CustomRequiredValidator, FileSizeValidator,
    PhoneValidator, SmLinkValidator,
} from 'commons/validators';
import { SmLinksRegExp } from 'commons/regexp/sm-links';

export const CompanyInfoForm = () => new RealmFormGroup(
    {
        companyName: new RealmFormControl(
            'companyName',
            {
                label: 'Company Display Name',
            },
            Validators.required,
        ),
        companyContactName: new RealmFormControl(
            'companyContactName',
            {
                label: 'Company Contact',
            },
        ),
        companyPhone: new RealmFormControl(
            'companyPhone',
            {
                label: 'Company Phone',
            },
            PhoneValidator(),
        ),
        companyEmail: new RealmFormControl(
            'companyEmail',
            {
                label: 'Contact Email',
            },
            Validators.email,
        ),
        companyWebsite: new RealmFormControl(
            'companyWebsite',
            {
                label: 'Company Website',
            },
        ),
        tags: new RealmFormControl(
            'tags',
            {
                label: 'Tags',
            },
        ),
        loanPrograms: new RealmFormControl(
            'loanPrograms',
            {
                label: 'Loan Programs',
            },
        ),
        companyAddress: new RealmFormControl(
            'companyAddress',
            {
                label: 'Company Address',
            },
        ),
        companyDescription: new RealmFormControl(
            'companyDescription',
            {
                label: 'Company Description',
                updateOn: 'change',
            },
            Validators.required,
        ),
        medialinks: new RealmFormGroup(
            {
                FB: new RealmFormControl(
                    'FB',
                    {
                        label: 'Facebook URL',
                    },
                    [SmLinkValidator(SmLinksRegExp.FB)],
                ),
                IG: new RealmFormControl(
                    'IG',
                    {
                        label: 'Instagram URL',
                    },
                    [SmLinkValidator(SmLinksRegExp.IG)],
                ),
                LI: new RealmFormControl(
                    'LI',
                    {
                        label: 'LinkedIn URL',
                    },
                    [SmLinkValidator(SmLinksRegExp.LI)],
                ),
                YT: new RealmFormControl(
                    'YT',
                    {
                        label: 'YouTube URL',
                    },
                    [SmLinkValidator(SmLinksRegExp.YT)],
                ),
                X: new RealmFormControl(
                    'X',
                    {
                        label: 'X (Twitter) URL',
                    },
                    [SmLinkValidator(SmLinksRegExp.X)],
                ),
            },
        ),
        originalCompanyLogo: new RealmFormControl(
            'originalCompanyLogo',
            {
                label: 'Logo',
            },
            FileSizeValidator(20 * Math.pow(2, 20)),
        ),
        companyLogo: new RealmFormControl(
            'companyLogo',
            {
                label: 'Company Logo',
            }, [
                CustomRequiredValidator('Please upload {field}'),
            ],
        ),
        isCompanyLogoModified: new RealmFormControl(
            'isCompanyLogoModified',
            {},
        ),
        companyLogoCoordinates: new RealmFormControl(
            'companyLogoCoordinates',
            {},
        ),
        hasCompanyLogo: new RealmFormControl(
            'hasCompanyLogo',
            {},
        ),
    },
);
