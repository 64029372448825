<ng-container *ngIf="product() as product">
    <div class="flex-grow">
        <b class="text-big">{{ product.productName }}</b>
        <hr>
        <div class="product-description" *ngIf="product.productDescription as description">
            <quill-view [content]="description"></quill-view>
        </div>
    </div>

    <div class="text-center" *ngIf="!isProductSubscribed(); else showBadge">
        <a class="btn btn-lg btn-primary" (click)="action.emit(product)">
            {{ getButtonText() }}
        </a>
    </div>

    <ng-template #showBadge>
        <div class="badge-holder">
            <span class="c-icon c-icon-check text-success"></span>
            <span class="text-smaller text-muted">
                You are already subscribed
            </span>
        </div>
    </ng-template>
</ng-container>

