<div class="modal show" bsModal [config]="{ backdrop: 'static', show: true }">
    <div class="modal-dialog modal-new modal-card-full">
        <div class="modal-content">
            <sc-company-full-card-v2
                [companyData]="companyData()"
                [resolving]="resolving()"
                [currentTab]="currentTab()"
                [visibleTabs]="visibleTabs()"
                (closeCard)="closeCard()"
            ></sc-company-full-card-v2>
        </div>
    </div>
</div>
