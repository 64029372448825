/* eslint-disable comma-dangle */
import { LoginLogComponent, ActionLogComponent } from 'shared/user-management/action-log';
import { AccountInformationComponent, AccountHistoryListComponent } from 'shared/account/company-information';
import { NewContactAuditLogComponent } from 'shared/new-contacts/audit-log';

import { ComergenceContactsPageComponent } from 'comergence/contacts/contacts-page.component';
import { ComergenceContactsListComponent } from 'comergence/contacts/contacts/list.component';
import { ComergenceOwnersListComponent } from 'comergence/contacts/owners/list.component';
import { ApiSettingsComponent } from 'comergence/customers/services/api-settings';

import { ComergenceUsersListComponent, ComergenceUserComponent } from 'comergence/ccm-user-management';
import { ComergenceCustomerUsersListComponent, ComergenceCustomerUserComponent } from 'comergence/customers/user-management';

import SharedRoutes from './sharedRoutes';
import {
    ComergenceDocumentComponent,
    ComergenceDocumentHistoryComponent,
    ComergenceDocumentListComponent,
    ComergenceDocumentsComponent,
    ComergenceGlobalDocumentDetailsComponent,
} from 'comergence/documents';
import { EmptyContainerComponent } from 'commons/components';
import { CcmContactDetailsComponent } from 'comergence/new-contacts/details/ccm-contact-details.component';
import { CcmContactGeneralInformationComponent } from 'comergence/new-contacts/details/general-information/ccm-company-general.component';
import { NewContactRemovedSocialAccountsComponent } from 'shared/new-contacts/social-accounts';
import { NewContactSocialAccountsComponent } from 'shared/new-contacts/social-accounts/social-accounts.component';
import { NewContactWebPresenceComponent } from 'shared/new-contacts/web-presence/web-presence.component';
import { IndividualPublishingPermissionsComponent } from 'tpo/social-compliance/publishing-permissions';
import { SharedContactSettingsComponent } from 'shared/new-contacts/settings/contact-settings.component';
import { NewContactsAuditsListComponent } from 'shared/new-contacts/compliance/audits/new-contacts-audits-list.component';
import { NewContactFindingsListComponent } from 'shared/new-contacts/compliance/findings/new-findings-list.component';
import { PeopleCompanyDetailsComponent } from 'tpo/people/contacts/company-details/people-company-details.component';
import { SocialMediaBillingComponent } from 'comergence/tpo/billing/social-media/social-media.component';
import { CustomerListComponent } from 'comergence/customers/list/customer-list.component';
import { CcmCustomerInformationComponent } from 'comergence/customers/customer-information/ccm-customer-information.component';
import { NewCcmCustomerInformationComponent } from 'comergence/customers/customer-information/new-customer/new-ccm-customer-information.component';
import { TpoServiceStatusComponent } from 'comergence/customers/tpo-services/tpo-service-status.component';
import { InvestorMarketingServiceHistoryComponent } from 'comergence/customers/investor-marketing-service/investor-marketing-service-history.component';
import { UIRouter } from '@uirouter/core';
import {
    AccountInformationService,
    accountIdentifier$,
    lenderInfo$
} from 'shared/account/company-information/account-information.service';
import { InvestorMarketingStatusComponent } from 'comergence/customers/investor-marketing-service/investor-marketing-status.component';
import { CcmCustomersComponent, customerInformation$ } from 'comergence/customers/ccm-customers/ccm-customers.component';
import { CustomerService } from 'comergence/customers/services/customer-list/customer.service';
import { DbaListComponent } from 'shared/account/dba/dba-list.component';
import { FederalRegistrationsListComponent } from 'shared/account/federal-registrations/federal-registrations-list.component';
import { LocationsListComponent } from 'shared/account/locations/locations-list.component';
import { InsuranceBondsComponent } from 'shared/account/information/insurance-bonds/insurance-bonds.component';
import { AgencyComponent } from 'tpo/account/agency/agency.component';
import { InvestorsComponent } from 'tpo/account/investors/investors.component';
import { SharedLicenseListComponent } from 'shared/account/licenses';
import { GlobalSearchHeaderComponent } from 'shared/global-search/global-search-header.component';
import { SearchClientsListComponent } from 'shared/global-search/clients/clients.component';
import { UserManagementHeaderComponent } from 'shared/user-management/header/user-management-header.component';
import { SearchIndividualsListComponent } from 'shared/global-search/individulals/individuals.component';
import { SearchUsersListComponent } from 'shared/global-search/users/users.component';
import { AffiliatesAddEditViewComponent } from 'tpo/account/affiliates/affiliates-add-edit-view.component';
import { AffiliatesListComponent } from 'tpo/account/affiliates/affiliates-list.component';
import { UnderwritingComponent } from 'tpo/account/underwriting/underwriting.component';
import { CcmHomeComponent } from 'comergence/home/home.component';
import { IndividualNmlsComponent } from 'shared/nmls/individual/individual-nmls.component';
import { CcmDataMatchReportViewEditComponent } from 'comergence/data-match/data-match-report-view-edit/ccm-data-match-report-view-edit.component';
import { CcmDataMatchHistoryComponent } from 'comergence/data-match/data-match-history/ccm-data-match-history.component';
import { NewDataMatchComponent } from 'comergence/data-match/new-data-match/new-data-match.component';
import { SharedTechOpsComponent } from 'shared/account/information/tech-ops/tech-ops.component';
import { ScorecardSummaryComponent } from 'shared/due-diligence/scorecard/scorecard-summary/scorecard-summary.component';
import {
    ScorecardReviewsTableComponent
} from 'shared/due-diligence/scorecard/scorecard-reviews/scorecard-reviews-table.component';
import {
    ScorecardCommentsListComponent
} from 'shared/due-diligence/scorecard/scorecard-comments/scorecard-comments-list.component';

import { ScorecardSummaryOwnerComponent } from 'shared/due-diligence/scorecard/scorecard-summary-owners/scorecard-summary-owner.component';
import {
    ScorecardReviewsHistoryListComponent
} from 'shared/due-diligence/scorecard/scorecard-reviews/scorecard-review-history/scorecard-review-history-list.component';
import { ScorecardAddNewFindingComponent } from 'shared/due-diligence/scorecard/scorecard-add-findings/scorecard-add-new-finding.component';
import { DueDiligenceListComponent } from 'comergence/due-diligence/due-diligence-list.component';
import { ScorecardFindingsSummaryComponent } from 'shared/due-diligence/scorecard/scorecard-findings-summary/scorecard-findings-summary.component';
import { ScorecardFindingComponent } from 'shared/due-diligence/scorecard/scorecard-finding/scorecard-finding.component';
import {
    ScorecardFindingHistoryComponent
} from 'shared/due-diligence/scorecard/scorecard-finding-history/scorecard-finding-history.component';
import {
    InvestorContactDetailsComponent
} from 'lender/new-contacts/details/investor-contact-details.component';

import { CustomFieldsEntitiesComponent } from 'shared/fields';
import { CommonProductionComponent } from 'shared/production/production.component';
import { ComergenceApplicationsListComponent } from 'comergence/applications/applications-list.component';
import { CCMClientMenuContainerComponent } from 'comergence/tpo/$id/client-menu-container.component';
import { NonQmComponent } from 'comergence/non-qm';
import { TpoProductsComponent } from 'shared/tpo-products/tpo-products.component';
import {
    TpoProductAddOnsComponent
} from 'shared/tpo-products/product-details/product-add-ons/tpo-product-add-ons.component';
import {
    TpoProductDetailsComponent
} from 'shared/tpo-products/product-details/tpo-product-details.component';
import {
    TpoProductSettingsComponent
} from 'shared/tpo-products/product-details/product-settings/tpo-product-settings.component';
import { TpoProductUserLicensesComponent } from 'shared/tpo-products/product-details/product-user-licenses/tpo-product-user-licenses.component';
import { CreateReadOnlyUserComponent } from 'comergence/ccm-user-management/create-read-only-user/create-read-only-user.component';
import { CcmCustomerProductsComponent } from 'comergence/ccm-customer-product/ccm-product/ccm-customer-products.component';
import { CcmCustomerProductsComponentNew } from 'comergence/ccm-customer-product-new/ccm-product/ccm-customer-products-new.component';
import { NewWarehousesComponent } from 'shared/account/information/new-warehouse/warehouses.component';
import commonRoutes from 'routes/commonRoutes';

const contactRoutes = (prefix: string = 'tpo.:id') => [
    {
        name: '.company',
        abstract: true,
        views: {
            '^': {
                component: EmptyContainerComponent,
                // component: TpoContactDetailsComponent,
            },
        },
        children: [
            {
                name: '.:contactId',
                url: '/{contactId:int}',
                component: PeopleCompanyDetailsComponent,
            },
        ],
    },
    {
        name: '.new',
        views: {
            '^': {
                component: CcmContactGeneralInformationComponent,
            },
        },
    },
    {
        name: '.:contactId',
        url: '/{contactId:int}',
        redirectTo: `${prefix}.contacts.:contactId.information`,
        views: {
            '@': {
                component: CcmContactDetailsComponent,
            },
        },
        children: [
            {
                name: '.information',
                component: CcmContactGeneralInformationComponent,
            },
            {
                name: '.settings',
                component: SharedContactSettingsComponent,
            },
            {
                name: `.audit-log?${NewContactAuditLogComponent.listName}`,
                params: {
                    [NewContactAuditLogComponent.listName]: {
                        type: 'jsonWithDate',
                    },
                },
                reloadOnSearch: false,
                component: NewContactAuditLogComponent,
            },
            {
                name: `.social-media?${NewContactSocialAccountsComponent.listName}`,
                component: NewContactSocialAccountsComponent,
                reloadOnSearch: false,
                params: {
                    [NewContactSocialAccountsComponent.listName]: {
                        type: 'json',
                    },
                },
            },
            {
                name: `.web-presence?${NewContactWebPresenceComponent.listName}`,
                component: NewContactWebPresenceComponent,
                reloadOnSearch: false,
                params: {
                    [NewContactWebPresenceComponent.listName]: {
                        type: 'json',
                    },
                },
            },
            {
                name: `.publishing-permissions?{${IndividualPublishingPermissionsComponent.listName}:json}`,
                url: '/publishing-permissions/:linkId',
                reloadOnSearch: false,
                views: {
                    '@': {
                        component: IndividualPublishingPermissionsComponent,
                    },
                },
            },
            {
                name: `.removed?${NewContactRemovedSocialAccountsComponent.listName}`,
                params: {
                    [NewContactRemovedSocialAccountsComponent.listName]: {
                        type: 'json',
                    },
                },
                reloadOnSearch: false,
                component: NewContactRemovedSocialAccountsComponent,
            },
            {
                name: `.compliance`,
                abstract: true,
                component: EmptyContainerComponent,
                children: [
                    {
                        name: `.audits?${NewContactsAuditsListComponent.listName}`,
                        params: {
                            [NewContactsAuditsListComponent.listName]: {
                                type: 'json',
                            },
                        },
                        reloadOnSearch: false,
                        component: NewContactsAuditsListComponent,
                    },
                    {
                        name: `.findings?${NewContactFindingsListComponent.listName}`,
                        params: {
                            [NewContactFindingsListComponent.listName]: {
                                type: 'json',
                            },
                        },
                        reloadOnSearch: false,
                        component: NewContactFindingsListComponent,
                    },
                ],
            },
        ],
    },
];

export default [
        {
            name: 'home',
            component: CcmHomeComponent,
        },
        ...commonRoutes,
        {
            name: 'search?q',
            component: GlobalSearchHeaderComponent,
        },
        {
            name: `search.clients?${SearchClientsListComponent.listName}`,
            reloadOnSearch: false,
            component: SearchClientsListComponent,
            params: {
                [SearchClientsListComponent.listName]: {
                    type: 'json',
                },
            },
        },
        {
            name: `search.individuals?${SearchIndividualsListComponent.listName}`,
            component: SearchIndividualsListComponent,
            reloadOnSearch: false,
            params: {
                [SearchIndividualsListComponent.listName]: {
                    type: 'json',
                },
            },
        },
        {
            name: `search.users?${SearchUsersListComponent.listName}`,
            component: SearchUsersListComponent,
            reloadOnSearch: false,
            params: {
                [SearchUsersListComponent.listName]: {
                    type: 'json',
                },
            },
        },
		//TODO: refactor to use NMLS everywhere
		{
			name: 'nmls',
            abstract: true,
			component: EmptyContainerComponent,
		},
		{
			name: 'nmls.individual',
            abstract: true,
			component: EmptyContainerComponent,
		},
		{
			name: 'nmls.individual.:nmlsId',
			params: {
				nmlsId: {
					type: 'int',
				},
			},
			component: IndividualNmlsComponent,
		},
		{
			name: 'nmls.client',
            abstract: true,
			component: EmptyContainerComponent,
		},
		{
			name: 'nmls.client.:id',
			abstract: true,
			component: CCMClientMenuContainerComponent,
            resolve: [
                { token: 'lenderInfo', deps: [ UIRouter, AccountInformationService ], resolveFn: lenderInfo$ },
            ],
		},
		{
			name: 'nmls.client.:id.account',
            abstract: true,
			component: EmptyContainerComponent,
		},
		{
			name: 'nmls.client.:id.account.information',
			component: AccountInformationComponent,
            children: [
                {
                    name: `.history?${AccountHistoryListComponent.listName}`,
                    reloadOnSearch: false,
                    views: {
                        '^':{
                            component: AccountHistoryListComponent,
                            resolve: [
                                { token: 'lenderInfo', deps: [ UIRouter, AccountInformationService ], resolveFn: lenderInfo$ },
                            ],
                        }
                    },
                    params: {
                        [AccountHistoryListComponent.listName]: {
                            type: 'json',
                        },
                    },
                }
            ],
		},
		{
            name: `nmls.client.:id.account.locations?{${LocationsListComponent.listName}:json}`,
            reloadOnSearch: false,
            component: LocationsListComponent,
			children: SharedRoutes.locations
		},
		{
            name: `nmls.client.:id.account.dba?{${DbaListComponent.listName}:json}`,
            reloadOnSearch: false,
            component: DbaListComponent,
		},
		{
			name: `nmls.client.:id.account.federal-registration?{${FederalRegistrationsListComponent.listName}:json}`,
			reloadOnSearch: false,
			component: FederalRegistrationsListComponent,
		},
		{
			name: `nmls.client.:id.account.licenses?${SharedLicenseListComponent.listName}`,
			params: {
				[SharedLicenseListComponent.listName]: {
					type: 'json',
				},
			},
			reloadOnSearch: false,
			component: SharedLicenseListComponent,
		},
		{
			name: 'nmls.client.:id.account.licenses.nmls',
			abstract: true,
            component: EmptyContainerComponent,
		},
		{
			name: 'nmls.client.:id.account.licenses.nmls.:nmlsId',
			views: {
				'@nmls.client.:id': {
					component: IndividualNmlsComponent,
				}
			}
		},
        {
            name: `nmls.client.:id.contacts`,
            redirectTo: 'nmls.client.:id.contacts.:contactId.social-media',
            component: EmptyContainerComponent,
            children: [
                {
                    name: '.:contactId',
                    url: '/{contactId:int}',
                    redirectTo: 'nmls.client.:id.contacts.:contactId.social-media',
                    views: {
                        '@': {
                            component: InvestorContactDetailsComponent,
                        },
                    },
                    children: [
                        {
                            name: `.social-media?${NewContactSocialAccountsComponent.listName}`,
                            component: NewContactSocialAccountsComponent,
                            reloadOnSearch: false,
                            params: {
                                [NewContactSocialAccountsComponent.listName]: {
                                    type: 'json',
                                },
                            },
                        }
                    ]
                }
            ]
        },
        {
            name: 'nmls.client.:id.production',
            params: {
                prefix: 'nmls.client.:id.',
            },
            component: CommonProductionComponent,
            resolve: [
                { token: 'lenderInfo', deps: [ UIRouter, AccountInformationService ], resolveFn: lenderInfo$ },
            ],
        },
		...SharedRoutes.SocialAccounts('nmls.client.:id.'),
		//ENDTODO
        {
            name: 'documents',
            component: ComergenceDocumentsComponent,
            redirectTo: 'documents.list',
            children: [
                {
                    name: `.list?${ComergenceDocumentListComponent.listName}`,
                        params: {
                        [ComergenceDocumentListComponent.listName]: { type: 'json' },
                    },
                    reloadOnSearch: false,
                    component: ComergenceDocumentListComponent,
                    children: [
                        {
                            name: '.:documentId',
                            views: {
                                '@': {
                                    component: ComergenceGlobalDocumentDetailsComponent,
                                },
                            },
                            children: [
                                {
                                    name: `.history?${ComergenceDocumentHistoryComponent.listName}`,
                                    params: {
                                        [ComergenceDocumentHistoryComponent.listName]: { type: 'json' },
                                    },
                                    reloadOnSearch: false,
                                    views: {
                                        '@': {
                                            component: ComergenceDocumentComponent,
                                        },
                                        'history': {
                                            component: ComergenceDocumentHistoryComponent,
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    name: '.new',
                    views: {
                        '@': {
                            component: ComergenceGlobalDocumentDetailsComponent,
                        },
                    },
                },
                {
                    name: '.:documentId',
                    params: {
                        documentId: { type: 'int' },
                    },
                    views: {
                        '@': {
                            component: ComergenceGlobalDocumentDetailsComponent,
                        },
                    },
                    children: [
                        {
                            name: '.edit',
                            views: {
                                '@': {
                                    component: ComergenceGlobalDocumentDetailsComponent,
                                },
                            },
                        },
                    ],
                },
            ],
        },
		{
			name: `customers?${CustomerListComponent.listName}`,
			component: CustomerListComponent,
			reloadOnSearch: false,
			params: {
				[CustomerListComponent.listName]: {
					type: 'json',
				},
			},
		},
		{
			name: 'customers.:customerType',
			abstract: true,
			views: {
				'@': {
					component: EmptyContainerComponent,
				},
			},
			children: [
				{
					name: '.new',
					component: NewCcmCustomerInformationComponent,
					param: {
						customerType: 'customer'
					},
				},
			],
		},
		{
			name: 'customers.:customerType.:organizationId',
			redirectTo: 'customers.:customerType.:organizationId.information',
			component: CcmCustomersComponent,
			resolve: [
				{
					token: 'customerInformation',
					deps: [ UIRouter, CustomerService ],
					resolveFn: customerInformation$,
				},
			],
			children: [
				{
					name: '.information',
                    component: CcmCustomerInformationComponent
				},
				{
					name: `.manage?${ComergenceCustomerUsersListComponent.listName}`,
					reloadOnSearch: false,
					component: ComergenceCustomerUsersListComponent,
					params: {
						[ComergenceCustomerUsersListComponent.listName]: {
							type: 'json',
						},
					},
					children: [
						{
							name: '.:actionType',
							component: EmptyContainerComponent,
							redirectTo: 'customers.:customerType.:organizationId.manage.:actionType.:id',
						},
						{
							name: `.:actionType.:id?${LoginLogComponent.listName}&${ActionLogComponent.listName}`,
							reloadOnSearch: false,
							views: {
								'@': {
									component: ComergenceCustomerUserComponent
								}
							},
							params: {
								[LoginLogComponent.listName]: {
									type: 'jsonWithIntdate',
								},
								[ActionLogComponent.listName]: {
									type: 'jsonWithIntdate',
								},
							},
						},
					],
				},
				{
					name: '.services',
					abstract: true,
					component: EmptyContainerComponent,
					children: [
						{
							name: '.tpo',
							component: TpoServiceStatusComponent,
							isNew: true
						},
						{
							name: `.crm`,
							component: InvestorMarketingStatusComponent,
							params: {
								notificationMsg: null,
							},
						},
						{
							name: '.crm.new',
							views: {
								'@customers.:customerType.:organizationId.services': {
									component: InvestorMarketingStatusComponent,
								},
							},
						},
						{
							name: `.crm.history?${InvestorMarketingServiceHistoryComponent.listName}`,
							reloadOnSearch: false,
							views: {
								'@customers.:customerType.:organizationId.services': {
									component: InvestorMarketingServiceHistoryComponent,
								},
							},
							params: {
								[InvestorMarketingServiceHistoryComponent.listName]: { type: 'json' },
							},
						},
						{
							name: '.api',
							component: ApiSettingsComponent,
							isNew: true
						},
                        {
                            name: '.products',
                            component: CcmCustomerProductsComponent,
                            isNew: true
                        },
                        {
                            name: '.products-new',
                            component: CcmCustomerProductsComponentNew,
                            isNew: true
                        },
					],
				},
			],
		},
		{
			name: 'tpo',
			abstract: true,
			component: EmptyContainerComponent,
		},
		{
			name: 'tpo.:id',
			component: CCMClientMenuContainerComponent,
            resolve: [
                { token: 'lenderInfo', deps: [ UIRouter, AccountInformationService ], resolveFn: lenderInfo$ },
            ],
		},
		{
			name: 'tpo.:id.account',
			abstract: true,
			component: EmptyContainerComponent,
		},
		{
			name: 'tpo.:id.account.information',
			component: AccountInformationComponent,
            children: [
                {
                    name: `.history?${AccountHistoryListComponent.listName}`,
                    reloadOnSearch: false,
                    views: {
                        '^':{
                            component: AccountHistoryListComponent,
                            resolve: [
                                { token: 'lenderInfo', deps: [ UIRouter, AccountInformationService ], resolveFn: lenderInfo$ },
                            ],
                        }
                    },
                    params: {
                        [AccountHistoryListComponent.listName]: {
                            type: 'json',
                        },
                    },
                }
            ],
		},
		{
			name: `tpo.:id.account.federal-registration?{${FederalRegistrationsListComponent.listName}:json}`,
			reloadOnSearch: false,
			component: FederalRegistrationsListComponent,
		},
		{
			name: `tpo.:id.account.licenses?${SharedLicenseListComponent.listName}`,
			params: {
				[SharedLicenseListComponent.listName]: {
					type: 'json',
				},
			},
			reloadOnSearch: false,
			component: SharedLicenseListComponent,
		},
		{
			name: 'tpo.:id.account.licenses.nmls',
            abstract: true,
            component: EmptyContainerComponent,
		},
		{
			name: 'tpo.:id.account.licenses.nmls.:nmlsId',
			views: {
				'@tpo.:id': {
					component: IndividualNmlsComponent,
				}
			}
		},
		{
            name: `tpo.:id.account.locations?{${LocationsListComponent.listName}:json}`,
            reloadOnSearch: false,
            component: LocationsListComponent,
			children: SharedRoutes.locations
		},
		{
            name: `tpo.:id.account.dba?{${DbaListComponent.listName}:json}`,
            reloadOnSearch: false,
            component: DbaListComponent,
		},
		{
			name: 'tpo.:id.relationships',
			abstract: true,
			component: EmptyContainerComponent,
		},
        {
            name: `tpo.:id.relationships.affiliates?${AffiliatesListComponent.listName}`,
            params: {
                [AffiliatesListComponent.listName]: {
                    type: 'json',
                },
            },
            component: AffiliatesListComponent,
            children: [
                {
                    name: '.:affiliateId',
                    views:{
                        '^':{
                            component: AffiliatesAddEditViewComponent,
                        }
                    }
                }
            ]
        },
        {
            name: 'tpo.:id.relationships.agency',
            component: AgencyComponent,
        },
        {
            name: 'tpo.:id.relationships.investors',
            component: InvestorsComponent,
        },
        {
            name: 'tpo.:id.relationships.warehouse',
            component: NewWarehousesComponent,
        },
		{
			name: 'tpo.:id.relationships.insuranceBonds',
			component: InsuranceBondsComponent,
		},
		{
			name: 'tpo.:id.relationships.underwriting',
            component: UnderwritingComponent,
		},
        {
            name: 'tpo.:id.relationships.tech-ops',
            component: SharedTechOpsComponent,
        },
		{
			name: `tpo.:id.contacts?${ComergenceOwnersListComponent.listName}&${ComergenceContactsListComponent.listName}`,
			component: ComergenceContactsPageComponent,
			reloadOnSearch: false,
			params: {
				[ComergenceOwnersListComponent.listName]: {
					type: 'json',
				},
				[ComergenceContactsListComponent.listName]: {
					type: 'json',
				},
			},
			children: contactRoutes('tpo.:id'),
		},
		{
			name: 'tpo.:id.scorecard',
			abstract: true,
			component: EmptyContainerComponent,
		},
		{
			name: 'tpo.:id.scorecard.summary',
			params: {
				notification: null
			},
			component: ScorecardSummaryComponent,
		},
		{
			name: 'tpo.:id.scorecard.summary.add',
			views: {
				'@tpo.:id': {
                    component: ScorecardAddNewFindingComponent,
				}
			},
		},
		{
			name: 'tpo.:id.scorecard.owner',
			abstract: true,
			component: EmptyContainerComponent,
		},
		{
			name: `tpo.:id.scorecard.owner.:ownerId?${ScorecardSummaryOwnerComponent.listName}`,
			reloadOnSearch: false,
            params: {
                [ScorecardSummaryOwnerComponent.listName]: {
                    type: 'json',
                },
            },
			views: {
				'@tpo.:id': {
                    component: ScorecardSummaryOwnerComponent,
				}
			}
		},
		{
			name: 'tpo.:id.scorecard.finding',
			abstract: true,
			component: EmptyContainerComponent,
		},
		{
			name: `tpo.:id.scorecard.finding.:sType?${ScorecardFindingsSummaryComponent.listName}`,
			reloadOnSearch: false,
            params: {
                [ScorecardFindingsSummaryComponent.listName]: {
                    type: 'json',
                },
            },
			views: {
				'@tpo.:id': {
                    component: ScorecardFindingsSummaryComponent,
				},
			},
		},
		{
			name: 'tpo.:id.scorecard.finding.:sType.:sid',
			views: {
				'@tpo.:id': {
					component: ScorecardFindingComponent,
				}
			}
		},
        {
            name: 'tpo.:id.scorecard.finding.:sType.:sid.history',
            views: {
                '@tpo.:id': {
                    component: ScorecardFindingHistoryComponent,
                }
            }
        },
		{
			name: `tpo.:id.scorecard.reviews?${ScorecardReviewsTableComponent.listName}`,
            component: ScorecardReviewsTableComponent,
            params: {
                [ScorecardReviewsTableComponent.listName]: {
                    type: 'json',
                },
            },
		},
		{
            name: 'tpo.:id.scorecard.reviews.history',
            url: `/history/:reviewId?{${ScorecardReviewsHistoryListComponent.listName}: json}`,
            views: {
                '@tpo.:id': {
                    component: ScorecardReviewsHistoryListComponent,
                }
            }
		},
		{
			name: `tpo.:id.scorecard.comments?${ScorecardCommentsListComponent.listName}`,
			component: ScorecardCommentsListComponent,
            params: {
                [ScorecardCommentsListComponent.listName]: {
                    type: 'json',
                },
            },
		},
        {
            name: `tpo.:id.production`,
			params: {
                prefix: 'tpo.:id.',
            },
			component: CommonProductionComponent,
            resolve: [
                { token: 'lenderInfo', deps: [ UIRouter, AccountInformationService ], resolveFn: lenderInfo$ },
            ],
        },
		{
			name: 'tpo.:id.billing',
			abstract: true,
            component: EmptyContainerComponent,
		},
		{
			name: 'tpo.:id.billing.social-compliance',
            component: SocialMediaBillingComponent,
        },
        {
            name: 'tpo.:id.billing.products',
            component: TpoProductsComponent,
            resolve: [
                { token: 'accountIdentifier', deps: [ UIRouter ], resolveFn: accountIdentifier$ },
            ],
        },
        {
            name: 'tpo.:id.billing.products.:productId',
            redirectTo: 'tpo.:id.billing.products.:productId.settings',
            views: {
                '@tpo.:id.billing': { component: TpoProductDetailsComponent, }
            },
            children: [
                {
                    name: '.settings',
                    component: TpoProductSettingsComponent,
                },
                {
                    name: '.add-ons',
                    component: TpoProductAddOnsComponent,
                },
                {
                    name: '.user-licenses',
                    component: TpoProductUserLicensesComponent,
                },
            ],
        },

		...SharedRoutes.SocialAccounts('tpo.:id.'),
		{
			name: `applications?${ComergenceApplicationsListComponent.listName}?sort&applicationStatus&createdAfter&applicationType&lenderId`,
			component: ComergenceApplicationsListComponent,
			reloadOnSearch: false,
			params: {
				[ComergenceApplicationsListComponent.listName]: {
					type: 'jsonWithDate',
				},
			},
		},
		{
			name: `reviews?${DueDiligenceListComponent.listName}`,
			reloadOnSearch: false,
            component: DueDiligenceListComponent,
            params: {
                [DueDiligenceListComponent.listName]: {
                    type: 'json',
                },
            },
		},
		{
			name: 'users-management',
            component: UserManagementHeaderComponent,
		},
		{
			name: `users-management.users?${ComergenceUsersListComponent.listName}`,
			component: ComergenceUsersListComponent,
			params: {
				[ComergenceUsersListComponent.listName]: {
					type: 'json',
				},
			},
			children: [
				{
					name: '.:actionType',
                    component: EmptyContainerComponent,
					redirectTo: 'users-management.users.:actionType.:id',
				},
				{
					name: `.:actionType.:id?${LoginLogComponent.listName}&${ActionLogComponent.listName}`,
					reloadOnSearch: false,
					views: {
						'@': {
							component: ComergenceUserComponent
						}
					},
					params: {
						[LoginLogComponent.listName]: {
							type: 'jsonWithIntdate',
						},
						[ActionLogComponent.listName]: {
							type: 'jsonWithIntdate',
						},
                    },
				},
			],
		},
        {
			name: 'users-management.desktop-support',
            component: EmptyContainerComponent,
			redirectTo: 'users-management.users.:actionType.:id',
        },
        {
			name: 'users-management.desktop-support.new',
            reloadOnSearch: false,
            views: {
                '@': {
                    component: CreateReadOnlyUserComponent,
                },
            },
            params: {
				id: {
					type: 'int',
                    value: null,
				},
                actionType: {
                    value: 'new',
                },
            },
        },
		...SharedRoutes.roles,
		{
			name: `data-match?${CcmDataMatchHistoryComponent.listName}`,
            component: CcmDataMatchHistoryComponent,
            params: {
                [CcmDataMatchHistoryComponent.listName]: {
                    type: 'json',
                },
            },
            reloadOnSearch: false,
		},
		{
			name: 'data-match.new',
            reloadOnSearch: false,
			views: {
				'@': {
					component: NewDataMatchComponent,
				},
			},
		},
		{
			name: 'data-match.report',
			url: '/{id}',
			params: {
				dataMatch: null
			},
			views: {
				'@': {
                    component: CcmDataMatchReportViewEditComponent,
				}
			}
		},
        {
			name: `non-qm?${NonQmComponent.listName}`,
            params: {
                [NonQmComponent.listName]: {
                    type: 'json',
                },
            },
			component: NonQmComponent,
		},
        ...SharedRoutes.CustomFields(),
		SharedRoutes.SocialCompliance,
	];
