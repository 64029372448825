import { Component } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { ComergenceApplicationsListComponent } from 'comergence/applications/applications-list.component';

import { NavigationMenuItem } from 'utils/navigation/navigation.interface';

import { AbstractRoleNavComponent } from '../abstract-role-nav.component';

const {
    featureToggle_thinkific_enabled: thinkificEnabled,
} = window.env

@Component({
	selector: 'comergence-topnav',
	templateUrl: './role-topnav.component.html',
})
export class ComergenceTopNavComponent extends AbstractRoleNavComponent {
    constructor(
            stateService: StateService,
            userService: UserService) {
        super(stateService, userService);
    }

    // Override
    protected rebuildMenuItems(userProfile: UserProfile): NavigationMenuItem[] {
        const menuItems: NavigationMenuItem[] = [];

        menuItems.push({
            sref: 'home',
            title: 'Home',
            isActive: () => this.stateService.includes('home'),
        });

        if (userProfile.can('CCM_VIEW_CUSTOMER')) {
            menuItems.push({
                sref: 'customers',
                title: 'Customers',
                isActive: () => this.stateService.includes('customers'),
            });
        }
        if (userProfile.can('CCM_VIEW_APPLICATIONS')) {
            const today = new Date();
            const prior30days = new Date(new Date().setDate(today.getDate() - 30));

            menuItems.push({
                sref: 'applications',
                srefParams: {
                    [ComergenceApplicationsListComponent.listName]: {
                        applicationStatus: [ 'DR', 'LR', 'NS', 'UR' ],
                        createdAfter: prior30days,
                    },
                },
                title: 'Applications',
                isActive: () => this.stateService.includes('applications'),
            });
        }
        if (userProfile.can('CCM_VIEW_REVIEWS')) {
            menuItems.push({
                sref: 'reviews',
                srefParams: {
                    ['due-diligence']: {
                        status: [ 'N', 'P', 'Q' ],
                    },
                },
                title: 'Due Diligence',
                isActive: () => this.stateService.includes('reviews'),
            });
        }
        if (userProfile.can('CCM_SMC_VIEW_ALL')) {
            menuItems.push({
                sref: 'social-compliance.reviews',
                srefOptions: { reload: true },
                title: 'Social Media',
                isActive: () => this.stateService.includes('social-compliance.reviews'),
            });
        }

        menuItems.push({
            sref: '/api/rest/tableau/redirect',
            rawHref: true,
            rawHrefTarget: '_blank',
            srefOptions: { reload: true },
            title: 'Reports',
        });

        if (thinkificEnabled === 'true') {
            menuItems.push({
                sref: '/api/rest/thinkific/redirect',
                rawHref: true,
                rawHrefTarget: '_blank',
                srefOptions: {reload: true},
                title: 'Academy',
            });
        }

        return menuItems;
    }
}
