<form [formGroup]="productForm"
      class="remote-resource col-md-12"
      [class.resolved]="!resolving"
      (onValidSubmit)="doSave()"
>
    <div class="row-fluid">
        <div class="col-md-a">
            <div class="flex-row gap form-group" has-error [ngSwitch]="isEditing">
                <ng-container *ngSwitchDefault>
                    <div class="control-value flex-grow">{{ item().productName }}</div>
                </ng-container>
                <ng-container *ngSwitchCase="true">
                    <input type="text" name="productName" formControlName="productName" class="form-control flex-grow" maxlength="50" tabindex="1" />
                    <button class="btn btn-icon btn-tertiary" type="submit">
                        <span class="glyphicon glyphicon-ok"></span>
                    </button>
                    <a class="btn btn-icon btn-tertiary" (click)="doCancel()">
                        <span class="glyphicon glyphicon-remove"></span>
                    </a>
                </ng-container>
            </div>
        </div>
        <div class="col-md-a">
            <toggle
                #creditCardToggle
                [ngModel]="item().isCreditCard"
                (ngModelChange)="toggleFeature(item(), $event, productFeature.CreditCard, creditCardToggle)"
                [disabled]="isEditing || !userCanAddEdit()"
                [ngModelOptions]="{standalone: true}"
            >
            </toggle>
        </div>
        <div class="col-md-a">
            <toggle
                #visibilityToggle
                [ngModel]="item().visibilityAllowedByComergence"
                (ngModelChange)="toggleFeature(item(), $event, productFeature.Visibility, visibilityToggle)"
                [disabled]="isEditing || !userCanAddEdit()"
                [ngModelOptions]="{standalone: true}"
            >
            </toggle>
        </div>
        <div class="col-md-a">
            {{ item().isGoLive ? 'On' : 'Off' }}
        </div>
        <div class="col-md-a">
            <toggle
                #ssoToggle
                [ngModel]="item().isSSO"
                (ngModelChange)="toggleFeature(item(), $event, productFeature.SSO, ssoToggle)"
                [disabled]="isEditing || !userCanAddEdit()"
                [ngModelOptions]="{standalone: true}"
            >
            </toggle>
        </div>
        <div class="col-f-60 button-holder text-right">
            <div *ngIf="userCanAddEdit()" class="inline-menu" dropdown>
                <a class="dropdown-toggle btn btn-tertiary btn-icon text-bigger text-very-light" dropdownToggle>
                    <span class="c-icon c-icon-dot-3"></span>
                </a>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                    <li><a (click)="doEdit()" class="dropdown-item">Edit Name</a></li>
                </ul>
            </div>
        </div>
    </div>
</form>
