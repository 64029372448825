<div>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">&times;</button>
        <h4 class="modal-title">{{ title }}</h4>
    </div>
    <div class="modal-body remote-resource" [class.resolved]="!resolving">
        <form
            id="manageLicenseForm"
            *ngIf="!resolving"
            [formGroup]="manageLicenseForm"
            (onValidSubmit)="updateLicense()"
        >
            <form-errors [form]="manageLicenseForm"></form-errors>
            <div *ngIf="showUserList" class="form-group" has-error>
                <div class="col-md-a">
                    <label class="control-label required">
                        {{manageLicenseForm?.getFieldLabel('contactId')}}
                    </label>
                    <ng-select
                        formControlName="contactId"
                        [items]="organizationUser"
                        placeholder="Select Users"
                        bindValue="userId"
                        bindLabel="userName"
                    ></ng-select>
                </div>
            </div>
            <div class="form-group" has-error>
                <div class="col-md-a">
                    <label class="control-label required">
                        {{manageLicenseForm?.getFieldLabel('licenseStatus')}}
                    </label>
                    <ng-select
                        formControlName="licenseStatus"
                        [items]="licenseStatusOptions"
                        (change)="toggleEntitiesControlBasedOnLicenseStatus($event?.value)"
                        placeholder="Select Access"
                        bindValue="value"
                        bindLabel="label"
                    ></ng-select>
                </div>
            </div>
            <div *ngFor="let entityType of productEntityList; index as i; trackBy: trackByIndex"
                 class="form-group"
                 formArrayName="entities"
                 has-error
            >
                <div class="col-md-a">
                    <label class="control-label required">
                       {{entityType.entityTypeName}}
                    </label>
                    <ng-select
                        [formControlName]="i"
                        [items]="entityType.entities"
                        [multiple]="true"
                        [closeOnSelect]="false"
                        placeholder="Select"
                        bindValue="entityId"
                        bindLabel="entityName"
                    />
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div class="btn-group pull-right">
            <button
                [disabled]="resolving"
                type="submit"
                form="manageLicenseForm"
                class="btn btn-primary"
            >
                Confirm
            </button>
            <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
        </div>
    </div>
</div>
