import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

type ImagePosition = 'LEFT' | 'CENTER' | 'RIGHT';

const IMAGE_POSITIONS: ImagePosition[] = ['LEFT', 'CENTER', 'RIGHT'];

@Component({
    selector: 'image-position',
    templateUrl: './image-position.component.html',
})
export class ImagePositionComponent implements OnChanges {
    @Input() initialPosition: ImagePosition;
    @Output() positionChange = new EventEmitter<ImagePosition>();

    currentPosition: ImagePosition = 'CENTER';

    ngOnChanges(changes: SimpleChanges) {
        if (changes.initialPosition) {
            this.currentPosition = changes.initialPosition.currentValue;
        }
    }

    setPosition(position: ImagePosition) {
        this.currentPosition = position;
        this.positionChange.emit(this.currentPosition);
    }

    get positions(): ImagePosition[] {
        return IMAGE_POSITIONS;
    }
}
