import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PipesModule } from 'commons/pipes/pipes.module';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { SharedProspectingModule } from 'shared/prospecting/prospecting.module';

import { ApplicationsModule } from 'lender/applications';
import { ChannelCardListComponent } from '../../channels/channels-card-list/channel-card-list.component';
import { ChannelsService } from 'lender/clients/$id/channels/channels.service';
import { ChannelInviteComponent } from 'lender/clients/$id/channels/invite/invite.component';
import { InviteHistoryComponent } from 'lender/clients/$id/channels/invite-history/invite-history.component';
import { ChannelApplicationModule } from 'lender/clients/$id/channels/applications/channel-application.module';
import { SingleChannelComponent } from 'lender/clients/$id/channels/single/single-channel.component';
import { ChannelLocationsComponent } from 'lender/clients/$id/channels/single/locations/channel-locations.component';
import { ChannelAuditLogComponent } from 'lender/clients/$id/channels/single/audit-log/channel-audit-log.component';
import { ChannelPurchaseModalComponent } from 'lender/clients/$id/channels/single/details/purchase-modal/purchase.modal';
import { StatusReasonComponent } from 'lender/clients/$id/channels/single/details/status-reason-modal/status-reason.component';
import { ChangeStatusReasonModal } from 'lender/clients/$id/channels/single/details/status-reason-modal/change-status-reason.modal';
import { ChannelCardComponent } from '../../channels/channel-cards/channel-card.component';
import { ChannelsListComponent } from './list/channels-list.component';
import { ChannelCardService } from '../../channels/channel-card.service';
import { ChannelEstablishRSModalComponent } from 'lender/clients/$id/channels/single/details/establish-modal/establish.modal';
import { NewChannelsListComponent } from 'lender/clients/channels/channels-list.component';
import { CardModule } from 'commons/card/card.module';
import { TpoAccountModule } from 'tpo/account';
import { NewChannelCardComponent } from 'lender/clients/channels/new-channel-card/new-channel-card.component';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        PipesModule,
        ApplicationsModule,
        SharedProspectingModule,
        ChannelApplicationModule,
        CardModule,
        TpoAccountModule,
    ],
    declarations: [
        NewChannelsListComponent,
        NewChannelCardComponent,
        ChannelCardListComponent,
        ChannelInviteComponent,
        InviteHistoryComponent,
        SingleChannelComponent,
        ChannelPurchaseModalComponent,
        ChannelLocationsComponent,
        ChannelAuditLogComponent,
        StatusReasonComponent,
        ChangeStatusReasonModal,
        ChannelsListComponent,
        ChannelCardComponent,
        ChannelEstablishRSModalComponent
    ],
    providers: [
        ChannelsService,
        ChannelCardService,
    ],
})
export class InvestorChannelsModule {}
