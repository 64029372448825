import { Component, Input, OnInit } from '@angular/core';
import { TpoProductsService } from './tpo-products.service';
import { TpoProduct, PlanPaymentStatus } from './tpo-product.interface';
import { firstValueFrom } from 'rxjs';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';
import { UserService } from 'angularjs-providers/user.provider';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TpoAddProductModalComponent } from './tpo-add-product.modal.component';
import { PaymentMethodTypes } from 'tpo/payment-methods/payment-methods.interface';
import { AccountIdentifier } from 'shared/account/company-information/account-information.service';

@Component({
    templateUrl: 'tpo-products.component.html',
    selector: 'tpo-products',
})
export class TpoProductsComponent implements OnInit {

    @Input() accountIdentifier: AccountIdentifier;

    products: TpoProduct[];
    tpoId: number;
    resolving: boolean = false;
    canManageProducts: boolean;

    constructor(
        private tpoProductsService: TpoProductsService,
        private readonly globalNotificationsService: GlobalNotificationsService,
        private readonly userService: UserService,
        private readonly modalService: BsModalService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.tpoId = this.accountIdentifier.tpoId;
        await this.setProductList();
        this.canManageProducts = this.userService.profile.can('MANAGE_PRODUCTS');
    }

    private async setProductList(): Promise<void> {
        try {
            const includeInactive = this.userService.profile.isComergence;

            this.resolving = true;
            this.products = await firstValueFrom(this.tpoProductsService.getProductsByTpoId(this.tpoId, includeInactive));
        } catch ({ error: { message }, message: httpError }) {
            this.globalNotificationsService.add({
                type: GlobalNotificationType.ERROR,
                message,
            });
        } finally {
            this.resolving = false;
        }
    }

    openTpoAddProductModal() {
        this.modalService.show(
            TpoAddProductModalComponent,
            {
                initialState: {
                    tpoId: this.tpoId,
                    reloadProducts: async () => {
                        await this.setProductList();
                    },
                },
                class: 'modal-new modal-smd',
                backdrop: 'static',
            },
        );
    }

    protected readonly PlanPaymentStatus = PlanPaymentStatus;
    protected readonly PaymentMethodTypes = PaymentMethodTypes;
}
