import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { PipesModule } from 'commons/pipes/pipes.module';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';

import { RecurlyModule, RecurlyProvider } from 'commons/recurly';
import { ProductSubscriptionComponent } from "./product-subscription.component";
import { ProductPlanViewComponent } from './product-plan-view.component';
import { ProductPlanPurchaseComponent } from './product-plan-purchase.component';
import { ProductSubscriptionService } from './product-subscription.service';


@NgModule({
    imports: [
        CommonModule,
        RecurlyModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
        DirectivesModule,
        PipesModule,
    ],
    declarations: [
        ProductSubscriptionComponent,
        ProductPlanViewComponent,
        ProductPlanPurchaseComponent,
    ],
    providers: [
        RecurlyProvider,
        ProductSubscriptionService,
    ],
})
export class ProductSubscriptionModule {
}
