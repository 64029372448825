<div>
    <div class="gray-head">
        <h2>Payment Methods</h2>
    </div>
    <section>
        <div class="rows-bordered remote-resource" [class.resolved]="!resolving">
            <div class="row-fluid header">
                <div class="col-md-4">
                    <b>Payment Nickname</b>
                </div>
                <div class="col-md-3">
                    <b>Active Subscriptions</b>
                </div>
                <div class="col-md-3">
                    <b>Expiration</b>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row-fluid" *ngFor="let paymentMethod of paymentMethods">
                <div class="col-md-4">
                    {{paymentMethod.nickname}}
                </div>
                <div class="col-md-3">
                    {{paymentMethod.hasActiveSubscriptions ? "Yes" : "None"}}
                </div>
                <div class="col-md-3">
                    {{paymentMethod.expirationMonth}}/{{paymentMethod.expirationYear}}
                </div>
                <div class="col-md-2 text-right">
                    <a [href]="paymentMethod.managementUrl" target="_blank">Manage</a>
                </div>
            </div>
            <div *ngIf="!resolving && !paymentMethods?.length" class="row-fluid">
                <div class="col-md-a text-light text-bigger text-center">
                   No Payment Methods Found
                </div>
            </div>
        </div>
    </section>
</div>
