import { ChangeDetectionStrategy, Component, HostBinding, input, output } from '@angular/core';
import { SCFullCompany } from 'tpo/solution-center-v2/solution-center.service';
import { SC_TABS, SC_CARD_TAB, SolutionCenterVisibleTabs } from '../../solution-center-v2/solution-center.common';

@Component({
    selector: 'sc-company-full-card-v2',
    templateUrl: './company-full-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        'class': 'remote-resource',
        '[class.resolved]': '!resolving()',
    }
})
export class ScCompanyFullCardV2Component {
    companyData = input.required<SCFullCompany>();
    resolving = input<boolean>(true);
    isPreview = input<boolean>(false);
    visibleTabs = input.required<SolutionCenterVisibleTabs>();
    currentTab = input.required<SC_CARD_TAB>();
    closeCard = output();
    selectTab = output<SC_CARD_TAB>();

    protected readonly tabList = SC_TABS;
    protected readonly SC_CARD_TAB = SC_CARD_TAB;

    @HostBinding('class.preview-mode') get preview() {
        return this.isPreview();
    }
}
