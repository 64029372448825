export interface LenderUserPanelProduct {
    productName: string;
    productId: number;
    status: keyof typeof LenderUserPanelProductLicenseStatus;
    productPlanName: string;
}

export enum LenderUserPanelProductLicenseStatus {
    A = 'Active',
    I = 'Inactive',
}

export const LicenseStatusOptions: { value: LenderUserPanelProductLicenseStatus, label: string }[] =
    Object.keys(LenderUserPanelProductLicenseStatus)
        .map(
            key => ({ value: key as LenderUserPanelProductLicenseStatus, label: LenderUserPanelProductLicenseStatus[key]})
        );

export interface LenderUserEntityAccessDTO {
    entityIds: number[],
    status: string
}

export interface Entity {
    entityName: string;
    entityId: number;
}

export interface EntityListItem {
    entityTypeId: number;
    entityTypeName: string;
    entities: Entity[];
}
