import { find } from 'lodash';
import { Component, effect, inject, Injector, OnDestroy, signal } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

import { RealmFormGroup } from 'commons/forms';
import { Line } from 'commons/components/image-cropper/image-cropper.component';

import { Tag, LoanProgram } from 'shared/solution-center/solution-center.common';

import { UserService } from 'angularjs-providers/user.provider';

import { CompanyInfoForm } from './form';
import { ServerErrorResponse } from 'commons/forms/form-group.hoc';
import { SolutionCenterV2Component } from 'lender/solution-center-v2/solution-center.component';
import { InvestorCompanyInfo, InvestorSolutionCenterV2Service, SupportedFileTypes } from 'lender/solution-center-v2/solution-center.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    templateUrl: './company-info.component.html',
})
export class SCCompanyInfoV2Component implements OnDestroy {
    protected organizationId = inject(UserService).profile.organization.organizationId;
    editing = signal(false);
    uploadLogoError: string;
    readonly maxLength = 380;
    readonly defaultCropCoords = { start: { x: 0, y: 0 }, end: { x: 1, y: 1 } };
    originalData: InvestorCompanyInfo;
    form: RealmFormGroup = CompanyInfoForm();
    protected readonly solutionCenter = inject(SolutionCenterV2Component);
    protected readonly investorSolutionCenterService = inject(InvestorSolutionCenterV2Service);
    tags: Observable<Tag[]> = this.investorSolutionCenterService.getAvailableTags();
    loanPrograms: Observable<LoanProgram[]> = this.investorSolutionCenterService.getAvailableLoanPrograms();
    private readonly formValue = toSignal(this.form.valueChanges);

    constructor() {
        // Update the card on form value changes
        effect(() => {
            const companyInfo = this.solutionCenter.companyInfo();
            const formData = this.formValue();
            const editing = this.editing();
            if (companyInfo && editing) {
              this.solutionCenter.updateCard(formData as InvestorCompanyInfo);
            }
        }, {allowSignalWrites: true});
    }

    edit() {
        const companyInfo = this.solutionCenter.companyInfo();
        this.originalData = companyInfo;
        this.form.patchValue(
            companyInfo,
            { emitEvent: false },
        );
        this.setEditing(true);
    }

    setEditing(editing: boolean) {
        this.editing.set(editing);
        this.solutionCenter.blockVisibilityToggle(editing);
    }

    async submit() {
        try {
            const data = this.solutionCenter.companyInfo();
            this.solutionCenter.resolving.set(true);
            const { availableTabs, ...companyInfo } = await firstValueFrom(
                await this.investorSolutionCenterService.updateCustomerData(data),
            );
            this.solutionCenter.updateCard(companyInfo);
            this.originalData = this.solutionCenter.companyInfo();
            // this.solutionCenter.updateVisibilityToggle();
            this.cancel();
        } catch ({ error }) {
            this.form.setServerError(error as ServerErrorResponse);
        } finally {
            this.solutionCenter.resolving.set(false);
        }
    }

    cancel() {
        this.uploadLogoError = null;
        this.setEditing(false);
        this.form.reset(this.originalData);
        this.solutionCenter.updateCard(this.originalData);
    }

    onLogoPick(files: FileList) {
        this.fileValidation(files, 'uploadLogoError');
        if (this.uploadLogoError) {
            return;
        }
        const url = URL.createObjectURL(files[0]);
        this.form.patchValue({
            hasCompanyLogo: true,
            originalCompanyLogo: url,
            companyLogoCoordinates: this.defaultCropCoords,
            isCompanyLogoModified: true,
        });
    }

    removeLogo() {
        this.form.patchValue({
            hasCompanyLogo: false,
            companyLogo: null,
            originalCompanyLogo: null,
            isCompanyLogoModified: true,
        });
    }
    async onCroppedLogoUpdate(url: string) {
        this.form.patchValue({
            companyLogo: url,
            isCompanyLogoModified: true,
        });
    }

    onCropChange(crop: Line) {
        this.form.patchValue({
            companyLogoCoordinates: crop,
            isCompanyLogoModified: true,
        });
    }

    fileValidation(files: FileList, type: 'uploadLogoError') {
        this[type] = null;

        if (files.length === 0) {
            this[type] = 'You must select a file to upload.';
            this.form.setServerError( { message: this[type] });
            return;
        }

        const file = files[0];
        const fileTypeConfig = find(SupportedFileTypes, (config) => (
            config.regexp.test(file.name)
        ));

        switch (true) {
            case files.length > 1:
                this[type] = 'You can upload only one file at once.';
                break;
            case file.size > 20 * Math.pow(2, 20): // 20Mb
                this[type] = 'You can upload only a file less than 20 Mb.';
                break;
            case !fileTypeConfig:
                this[type] = 'Wrong file type, please use supported file types for upload: PNG, JPEG, JPG';
                break;
        }

        if (this[type]) {
            this.form.setServerError( { message: this[type] });
        }
    }

    ngOnDestroy() {
        this.cancel();
    }
}
