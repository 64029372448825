import { Component, inject } from '@angular/core';

import { UserService } from 'angularjs-providers/user.provider';

@Component({
    templateUrl: 'registration-final-step.html',
    selector: 'registration-password-creation',
})
export class RegistrationFinalStepComponent {
    userService = inject(UserService);
    public resolved = true;

    constructor() {}

    redirectToLogin() {
        this.resolved = false;
        this.userService.redirectToLogin();
    }
}
