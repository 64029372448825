import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { PipesModule } from 'commons/pipes/pipes.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';

import { CcmCustomerProductsNewService } from 'comergence/ccm-customer-product-new/ccm-customer-products-new.service';
import {CcmCustomerProductsComponentNew} from './ccm-product/ccm-customer-products-new.component';
import {
    CcmCustomerProductItemNewComponent
} from './ccm-product/ccm-product-item/ccm-customer-product-item-new.component';

@NgModule(
    {
        imports: [
            CommonModule,
            FormsModule,
            ReactiveFormsModule,
            PipesModule,
            DirectivesModule,
            ComponentsModule,
            BsDropdownModule,
        ],
        declarations: [
            CcmCustomerProductsComponentNew,
            CcmCustomerProductItemNewComponent,
        ],
        providers: [
            CcmCustomerProductsNewService,
        ],
    },
)
export class CcmCustomerProductNewModule {

}
