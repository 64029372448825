import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { Observable} from 'rxjs';
import { CreatePaymentMethodAndSubscribeToPlan, ProductSubscriptionPlan, PurchasePlan, PurchasedPlan } from './product-subscription.interface';

declare let apiPath: string;

@Injectable()
export class ProductSubscriptionService {
    constructor(
        private http: RealmHttpClient,
    ) {}

    getProductPlanSummary(tpoId: number, organizationId: number, planId: number, productId: number): Observable<ProductSubscriptionPlan> {
        return this.http.request<ProductSubscriptionPlan>(
            'GET',
            `${apiPath}/tpos/${tpoId}/organizations/${organizationId}/products/${productId}/plans/${planId}/summary`,
        );
    }

    createPaymentMethodAndSubscribeToPlan(
        tpoId: number,
        organizationId: number,
        productId: number,
        planId: number,
        paymentMethodAndSubscription: CreatePaymentMethodAndSubscribeToPlan,
    ): Observable<PurchasedPlan> {
        return this.http.request<PurchasedPlan>(
            'POST',
            `${apiPath}/tpos/${tpoId}/organizations/${organizationId}/products/${productId}/plans/${planId}/subscribe`,
            {},
            paymentMethodAndSubscription,
        );
    }

    subscribeToProductPlan(
        tpoId: number,
        organizationId: number,
        productId: number,
        planId: number,
        paymentMethod: PurchasePlan): Observable<PurchasedPlan> {
        return this.http.request<PurchasedPlan>(
            'POST',
            `${apiPath}/tpos/${tpoId}/organizations/${organizationId}/products/${productId}/plans/${planId}`,
            {},
             paymentMethod,
        );
    }

}
