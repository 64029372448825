import { Component, inject } from '@angular/core';
import { StateService } from '@uirouter/core';
import { UserProfile, UserService } from "angularjs-providers/user.provider";
import { LenderUsersResourceService } from 'lender/user-management/users-resource.service';

@Component({
    templateUrl: 'user-management-header.component.html',
    styles: ['.gray-head.tab-menu {margin: 0;padding: 0;list-style: none;white-space: nowrap; color: #808080}',
        '.b {font-weight: bold; color: black}',
    ],
})
export class UserManagementHeaderComponent {
    User: UserProfile = inject(UserService).profile;
    stateService = inject(StateService)
    investorUserService = inject(LenderUsersResourceService);
}
