import { Injector, Injectable, inject, Signal, signal } from '@angular/core';

import { NgResource } from 'commons/interfaces';
import { UserService as UService } from 'angularjs-providers/user.provider';
import { Observable } from 'rxjs';
import { TableauReportingGroup } from 'shared/new-contacts/contacts.interface';
import { RealmHttpClient, RealmParams } from 'commons/services/http';
import { CrmSettings, UserInformation } from './investor-marketing/investor-marketing.interface';
import { map, shareReplay } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';

declare let apiPath: string;

export type ExtendedUserProfile = {
	address1: string;
	address2: string;
	channelsAssignedIds: number[];
	city: string;
	externalId: number;
	firstName: string;
	fullName: string;
	id: number;
	isAccountAssigned: boolean;
	isActive: boolean;
	isComergenceRepresentative: boolean;
	isEmailBounced: boolean;
	isFullVisibilityAllowed: boolean;
	isReportingManager: boolean;
	lastName: boolean;
	organizationReportingEnabled: boolean;
	phone: string,
	reportingLicense: string;
	reportsToUserId: number;
	state: string;
	status: {
		id: string;
		name: string;
	}
	systemRoleId: number;
	userName: string;
	userReportingEnabled: boolean;
	zip: string;
};

@Injectable()
export class LenderUsersResourceService {
    $resource = inject(Injector).get('$injector').get('$resource')
    session = inject(UService).profile;
    private http= inject(RealmHttpClient);
    investorId = this.session.organization.id;
    organizationId = this.session.organization.organizationId;

    channels = this.$resource(`${apiPath}/lenders/:lenderId/channels`, {
        lenderId: this.investorId,
    }, {
        get: { method: 'get', isArray: true },
    });

    private userPath = `${apiPath}/organizations/:organizationId/users/:userId`
    user = this.$resource(this.userPath, {
            userId: '@userId',
            organizationId: this.organizationId,
        },
        {
            get: {
                method: 'get',
            },
            update: {
                method: 'put',
            },
            assignmentVerification: {
                method: 'post',
                url: `${this.userPath}/assignment_verification`,
            },
            getSettings: {
                method: 'get',
                url: `${this.userPath}/settings`,
            },
            updateSettings: {
                method: 'put',
                url: `${this.userPath}/settings`,
            },
        });

    managers = this.$resource(
        `${apiPath}/organizations/:organizationId/users/reporting-managers`,
        this.session.organization.type === 'CUSTOMER' ? { organizationId: this.organizationId } : {},
        {
            list: {
                method: 'get',
                isArray: true,
            },
        },
    );

	getChannels = (...args) => this.channels.get(...args);
	getReportingManagers = (...args) => this.managers.list(...args);
	get = (...args): NgResource<ExtendedUserProfile> => this.user.get(...args);
	update = (...args) => this.user.update(...args);
	assignmentVerification = (...args) => this.user.assignmentVerification(...args);
	getSettings = (...args) => this.user.getSettings(...args);
	updateSettings = (...args) => this.user.updateSettings(...args);

    getReportingGroups(organizationId: number = this.organizationId): Observable<TableauReportingGroup[]> {
        return this.http.request<TableauReportingGroup[]>(
            'GET',
            `${apiPath}/organizations/${organizationId}/reporting/groups`,
        );
    }

    public getCrmSettings(organizationId: number): Observable<CrmSettings> {
        return this.http.request<CrmSettings>(
            'GET',
            `${apiPath}/organizations/${organizationId}/crm-settings/user-information`,
            null,
            null
        );
    }

    public getUsers(organizationId: number, params: RealmParams = null): Observable<UserInformation[]> {
        return this.http.request<UserInformation[]>(
            'GET',
            `${apiPath}/organizations/${organizationId}/users/all/page`,
            params,
        );
    }

    public saveCrmSettings(organizationId: number, newSettings: CrmSettings): Observable<CrmSettings> {
        return this.http.request<CrmSettings>(
            'PUT',
            `${apiPath}/organizations/${organizationId}/crm-settings/user-information`,
            null,
            newSettings
        );
    }

    public isCrmServiceEnabled: Signal<boolean> = this.session.isLender ? toSignal<boolean>(this.http.request<{ active: boolean }>(
        'GET',
        `${apiPath}/organizations/${this.organizationId}/crm-settings/service/status`,
    ).pipe(
        map(({ active }) => active),
        shareReplay(1),
    )) : signal(false);
}
