<ui-view></ui-view>

<div class="gray-head flex-head">
    <h2>Solution Center</h2>
</div>
<div class="remote-resource" [class.resolved]="!resolving">
    <div class="sticky-filters mb20">
        <div class="wrapper">
            <div class="row-fluid filter-labels">
                <div class="col-md-a">
                    <div class="text-muted">Search</div>
                </div>
                <div class="col-md-a">
                    <div class="text-muted">Tags</div>
                </div>
                <div class="col-md-a">
                    <div class="text-muted">Loan Programs</div>
                </div>
                <div class="col-md-a">
                    <div class="text-muted">Investors</div>
                </div>
                <div class="col-f-120"></div>
            </div>
            <div class="row-fluid filter-selectors">
                <div class="col-md-a">
                    <div class="has-feedback">
                        <search-list placeholder="Name, product"></search-list>
                    </div>
                </div>
                <div class="col-md-a">
                    <ng-select
                        placeholder="All Tags"
                        [ngModel]="params.tags"
                        [items]="filters.tags | async"
                        (change)="setFilter({ tags: $event })"
                        [loading]="!(filters.tags | async)"
                        [closeOnSelect]="false"
                        bindValue="code"
                        bindLabel="name"
                        [multiple]="true"
                    ></ng-select>
                </div>
                <div class="col-md-a">
                    <ng-select
                        placeholder="All Loan Programs"
                        [ngModel]="params.loanPrograms"
                        [items]="filters.loanPrograms | async"
                        (change)="setFilter({ loanPrograms: $event })"
                        [loading]="!(filters.loanPrograms | async)"
                        [closeOnSelect]="false"
                        bindValue="code"
                        bindLabel="name"
                        [multiple]="true"
                    ></ng-select>
                </div>
                <div class="col-md-a">
                    <ng-select
                        placeholder="Select Investors"
                        [ngModel]="params.investorType"
                        [items]="filters.investorType | async"
                        (change)="setFilter({ investorType: $event })"
                        [loading]="!(filters.investorType | async)"
                        [closeOnSelect]="false"
                        bindValue="code"
                        bindLabel="name"
                        [multiple]="false"
                    ></ng-select>
                </div>
                <div class="col-f-120 va-m">
                    <a *ngIf="filtersApplied" (click)="resetFilters()">Reset filters</a>
                </div>
            </div>
        </div>
    </div>
    <div class="wrapper">
        <ng-container *ngIf="!(list | async)?.length">
            <div class="text-muted text-center text-bigger text-weak">No matches found</div>
        </ng-container>
        <div class="company-list">
            <sc-company-card-v2
                *ngFor="let company of list | async"
                [companyData]="company" uiSref=".:organizationId" [uiParams]="{organizationId: company.organizationId}">
            </sc-company-card-v2>
        </div>
    </div>
</div>
