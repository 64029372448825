<div>
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">&times;</button>
        <h4 class="modal-title">Add Products</h4>
    </div>
    <form [formGroup]="addProductForm" (onValidSubmit)="addProduct()">
        <div class="modal-body remote-resource" [class.resolved]="!resolving">
            <form-errors [form]="addProductForm"></form-errors>
            <div class="form-group" has-error>
                <div class="col-md-a">
                    <label class="control-label required">
                        {{addProductForm?.getFieldLabel('product')}}
                    </label>
                    <ng-select
                        formControlName="product"
                        [items]="tpoAccessibleProducts"
                        placeholder="Select Product"
                        bindValue="productId"
                        bindLabel="productName"
                        [loading]="resolving"
                        (change)="onProductChange($event)"
                    ></ng-select>
                </div>
            </div>
            <div class="form-group" has-error>
                <div class="col-md-a">
                    <label class="control-label required">
                        {{addProductForm?.getFieldLabel('planId')}}
                    </label>
                    <ng-select
                        formControlName="planId"
                        [items]="plans"
                        placeholder="Select Plan"
                        bindValue="planId"
                        bindLabel="planName"
                    ></ng-select>
                </div>
            </div>
            <div class="form-group" has-error>
                <div class="col-md-a">
                    <label class="control-label required">
                        {{addProductForm?.getFieldLabel('paymentMethodType')}}
                    </label>
                    <ng-select
                        formControlName="paymentMethodType"
                        [items]="paymentMethods"
                        placeholder="Select Payment Method"
                        bindValue="value"
                        bindLabel="label"
                    ></ng-select>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-md-6 form-group" has-error>
                    <label class="control-label">{{addProductForm.get('poNumber').label}}</label>
                </div>
                <div class="col-md-6 form-group" has-error>
                    <label class="control-label">{{addProductForm.get('costCenter').label}}</label>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-md-6 form-group" has-error>
                    <input type="text" class="form-control" formControlName="poNumber" />
                </div>
                <div class="col-md-6 form-group" has-error>
                    <input type="text" class="form-control" formControlName="costCenter" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="btn-group pull-right">
                <button type="submit" class="btn btn-primary">Confirm</button>
                <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>
