<div class="remote-resource" [class.resolved]="!resolving">
    <div *ngIf="canManagePayments" class="pull-right margin-bottom-sm">
        <button type="button" class="btn btn-primary" (click)="openAssignLicenseModal()">
            Assign License
        </button>
    </div>

    <div class="rows-bordered">
        <div class="row-fluid header">
            <div class="col-md-a">
                Name
            </div>
            <div class="col-f-60 button-holder"></div>
        </div>
    <ng-container *ngIf="!resolving && ((list | async)?.length > 0); else noRecord">
        <div class="row-fluid" *ngFor="let item of list | async">
            <div class="col-md-a">
                <a uiSref="people.contacts.:contactId.products" [uiParams]="{contactId: item.userId}">
                    {{item.userName}}
                </a>
            </div>
            <div class="col-f-60 button-holder">
                <div class="dropdown" dropdown *ngIf="canManagePayments">
                    <a class="btn btn-icon btn-tertiary text-bigger dropdown-toggle" dropdownToggle placement="top"><span class="c-icon c-icon-dot-3"></span></a>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                        <li role="menuitem">
                          <a (click)="openManageLicenseModal(item)">
                              Manage License
                          </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </ng-container>
        <ng-template #noRecord>
            <div class="row-fluid">
                <div class="col-sm-12 text-center text-light text-bigger">
                    No records added
                </div>
            </div>
        </ng-template>
        <br>
        <paginator [sizes]="[ 10, 25, 50 ]"></paginator>
    </div>
</div>


