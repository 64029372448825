export interface CcmCustomerProductNew {
    isCreditCard: boolean;
    isGoLive: boolean;
    isSSO: boolean;
    productId: number;
    productName: string;
    visibilityAllowedByComergence: boolean;
}

export enum ProductFeature {
    CreditCard = 'product-credit-card',
    Visibility = 'product-visibility',
    SSO = 'product-sso'
}
