export interface PaymentMethod {
    id: number;
    nickname: string;
    cardType: string;
    lastFourDigits: string;
    expirationMonth: string;
    expirationYear: string;
    managementUrl: string;
    hasActiveSubscriptions: boolean;
    isExpired: boolean;
    type: PaymentMethodType;
}

export const PaymentMethodTypes = {
    'CREDIT_CARD': {
        name: 'Credit Card',
        display: 'Subscription'
    },
    'NO_PAYMENT': {
        name: 'No Payment',
        display: 'No Payment'
    },
    'INVOICING': {
        name: 'Invoicing',
        display: 'Invoicing'
    },
}

export type PaymentMethodType = keyof typeof PaymentMethodTypes;
