<div class="remote-resource" [class.resolved]="!resolving">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">&times;</button>
        <h4 class="modal-title">Manage Product</h4>
    </div>
    <div class="modal-body ">
        <form [formGroup]="manageProductForm" id="manageProductForm" name="manageProductForm" (onValidSubmit)="onConfirm(this.manageProductForm.value)">
            <form-errors [form]="manageProductForm"></form-errors>
            <div class="row-fluid">
                <div class="col-md-6">
                    <span class="text-light">
                        Call to Action
                    </span>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-md-12">
                    <label class="radio-inline">
                        <input type="radio" formControlName="type" [value]="CallToAction.SIGN_UP"> Sign Up
                    </label>
                    <label class="radio-inline">
                        <input type="radio" formControlName="type" [value]="CallToAction.CONTACT_US"> Contact Us
                    </label>
                </div>
            </div>
            &nbsp;
            <div *ngIf="manageProductForm.controls.type.value == CallToAction.CONTACT_US">
                <div class="row-fluid col-md-6">
                <label class="control-label">
                    <span class="text-light">
                        Lead Notification Email
                    </span>
                </label>
                </div>
                <div class="row-fluid col-md-12">
                    <input
                        type="email"
                        class="form-control has-error"
                        formControlName="email"
                    />
                </div>
            </div>
            &nbsp;
            <div class="row-fluid">
                <div class="col-md-12">
                    <span class="text-light">
                        Product Description
                        <span class="text-muted text-big glyphicon glyphicon-question-sign"
                              placement="top"
                              tooltip="Links must be formatted https://"
                        ></span>
                    </span>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-md-12">
                    <quill-editor [styles]="{ height: '200px', width: '530px' }" class="has-error" placeholder="Enter your product description here" formControlName="productDescription" theme="snow" [maxLength]="1000"></quill-editor>
                </div>
            </div>
        </form>

    </div>
    <div class="modal-footer" [class.curtain]="resolving">
        <div class="text-right">
            <button type="submit" form="manageProductForm" class="btn btn-primary">Save</button>
            <button type="button" (click)="onCancel()" class="btn btn-default">Cancel</button>
        </div>
    </div>
</div>
