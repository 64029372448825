import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { RealmHttpClient } from 'commons/services/http';
import { ListParams } from 'commons/components/new-list/list.component';

import { UserService } from 'angularjs-providers/user.provider';
import {
    Tag,
    LoanProgram,
    SCCustomerCard,
    InvestorType,
    SCCompanyInfo,
} from 'shared/solution-center-v2/solution-center.common';

import { InvestorCompanyOverview } from 'lender/solution-center-v2/solution-center.service';

export type SCFullCompany = SCCompanyInfo & InvestorCompanyOverview;



// import { PlanPaymentStatus } from 'comergence/tpo/billing/tpo-products/tpo-product.interface';

// export type TPOSCProduct = {
//     email: string;
//     description: string;
//     productId: number
//     productName: string;
//     type: string;
//     visibilityAllowedByComergence: boolean;
//     visibilityAllowedByCustomer: boolean;
//     productPlanId: number;
//     paymentPlanStatus: PlanPaymentStatus;
// }

// export type TPOCustomerProductInformation = {
//     overview: SCProductOverview;
//     tpoCustomerProducts: TPOSCProduct[];
// }

// export type SCCustomerProductInformation = {
//     overview: SCProductOverview;
//     tpoCustomerProducts: SCProduct[];
// }

export interface CompanyInfo {
    companyAddress: string;
    companyName: string;
    companyDescription: string;
    customerId: number;
    hasCompanyLogo: boolean;
    organizationId: number;
}

const ts = Date.now();
const processCustomerCard = (cards: CompanyInfo[], tpoId: number): SCCustomerCard[] => {
    return cards.map(card => {
        const { hasCompanyLogo, organizationId } = card;
        return {
            ...card,
            ...hasCompanyLogo ? {
                companyLogo: `/api/rest/tpos/${tpoId}/solution-center/customers/${organizationId}/company-logo?nocache=${ts}`,
            } : {},
        };
    });
}

const processCustomerOverviewCard = (card: SCFullCompany, tpoId: number): SCFullCompany => {
    const { hasCompanyLogo, organizationId, hasMarketingLogo } = card;
    return {
        ...card,
        ...hasCompanyLogo ? {
            companyLogo: `/api/rest/tpos/${tpoId}/solution-center/customers/${organizationId}/company-logo?nocache=${ts}`,
        } : {},
        ...hasMarketingLogo ? {
            marketingLogo: `/api/rest/tpos/${tpoId}/solution-center/customers/${organizationId}/marketing-logo?nocache=${ts}`,
        } : {},
    };
}

// const processProductInformation = (productInformation: TPOCustomerProductInformation): SCCustomerProductInformation => {
//     return {
//         overview: productInformation.overview,
//         tpoCustomerProducts: productInformation.tpoCustomerProducts.map(({description: productDescription,...product}) => {
//             return {
//                 ...product,
//                 productDescription,
//             };
//         }),
//     };
// }

declare let apiPath: string;

@Injectable()
export class LenderSolutionCenterV2Service {
    private readonly http = inject(RealmHttpClient);
    private readonly user = inject(UserService);
    protected tpoId = this.user.profile.organization.id;

    getCustomersList(params: ListParams): Observable<SCCustomerCard[]> {
        const { tpoId } = this;
        return this.http.request<CompanyInfo[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/solution-center/customers-board`,
            params,
        ).pipe(
            map(cards => processCustomerCard(cards, tpoId)),
            shareReplay(1),
        );
    }

    getFullCustomer(organizationId: number): Observable<SCFullCompany> {
        const { tpoId } = this;
        return this.http.request<SCFullCompany>(
            'GET',
            `${apiPath}/tpos/${tpoId}/solution-center/customers/${organizationId}/overview`,
        ).pipe(
            map(card => processCustomerOverviewCard(card, tpoId)),
        );
    }

    // issueContactRequest(organizationId: number, productId: number, body: TPOSolutionCenterContactRequestBody): Observable<void> {
    //     const { tpoId } = this;
    //     return this.http.request<void>(
    //         'POST',
    //         `${apiPath}/tpos/${tpoId}/organizations/${organizationId}/products/${productId}/contact-request`,
    //         {},
    //         body,
    //     );
    // }

    getAvailableLoanPrograms(): Observable<LoanProgram[]> {
        const { tpoId } = this;
        return this.http.request<LoanProgram[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/solution-center/customers/available-loan-programs`,
        ).pipe(
            shareReplay(1),
        );
    }

    getAvailableTags(): Observable<Tag[]> {
        const { tpoId } = this;
        return this.http.request<Tag[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/solution-center/customers/available-tags`,
        ).pipe(
            shareReplay(1),
        );
    }

    getAvailableInvestorsTypes(): Observable<InvestorType[]> {
        const { tpoId } = this;
        return this.http.request<InvestorType[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/solution-center/customers/investor-types`,
        ).pipe(
            shareReplay(1),
        );
    }

    // getProductsInformation(customerId: number): Observable<SCCustomerProductInformation> {
    //     const { tpoId } = this;
    //     return this.http.request<TPOCustomerProductInformation>(
    //         'GET',
    //         `${apiPath}/tpos/${tpoId}/solution-center/products`,
    //         { organizationId: customerId },
    //     ).pipe(
    //         map( productInformation => processProductInformation(productInformation)),
    //     );
    // }

    // getChannels(investorId: number): Observable<LenderChannel[]> {
    //     const { tpoId } = this;
    //     return this.http.request<LenderChannel[]>(
    //         'GET',
    //         `${apiPath}/lenders/${investorId}/channels/tpos/${tpoId}`,
    //     ).pipe(
    //         map(channels => processChannel(channels)),
    //     );
    // }

    // getChannelContactInfo(organizationId: number, channelId: number): Observable<LenderChannelContactInfo> {
    //     return this.http.request<LenderChannelContactInfo>(
    //         'GET',
    //         `${apiPath}/lenders/${organizationId}/channels/${channelId}/contactinfo`,
    //     ).pipe(
    //         shareReplay(1),
    //     );
    // }

    // getApplicationHistory(investorId: number): Observable<ApplicationHistory[]> {
    //     return this.http.request<ApplicationHistory[]>(
    //         'GET',
    //         `${apiPath}/tpo-applications-history/${investorId}`,
    //     );
    // }
}
