import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { SCProduct } from 'lender/solution-center/solution-center.service';
import { isNil } from 'lodash';
import { PLAN_PAYMENT_STATUSES_WITH_RE_SUBSCRIPTION_ACCESS, PlanPaymentStatus } from 'shared/tpo-products/tpo-product.interface';

@Component({
    selector: 'sc-coc-single-product',
    templateUrl: './single-product.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScCocSingleProductComponent {
    product = input.required<SCProduct>();
    action = output<SCProduct>();
    isPreview = input<boolean>();

    getButtonText(): string {
        return this.product().type == 'INTEGRATION' ? 'Subscribe' : 'Contact Us';
    }

    isProductSubscribed(): boolean {
        if(!this.isPreview()) {
            const status = this.product().paymentPlanStatus;
            if(!isNil(status)) {
                return !PLAN_PAYMENT_STATUSES_WITH_RE_SUBSCRIPTION_ACCESS.includes(PlanPaymentStatus[status]) && this.product().type == 'INTEGRATION';
            }
        }
    }
}
