import { find } from 'lodash';
import { Component, effect, inject, OnDestroy, signal } from '@angular/core';
import { firstValueFrom } from "rxjs";
import { toSignal } from '@angular/core/rxjs-interop';

import { RealmFormGroup } from 'commons/forms';
import { ServerErrorResponse } from 'commons/forms/form-group.hoc';
import { Line } from 'commons/components/image-cropper/image-cropper.component';

import { UserService } from 'angularjs-providers/user.provider';

import { OverviewForm } from './overviewForm';
import { SolutionCenterV2Component } from 'lender/solution-center-v2/solution-center.component';
import {
    InvestorCompanyOverview,
    InvestorSolutionCenterV2Service,
    SupportedFileTypes
} from 'lender/solution-center-v2/solution-center.service';

@Component({
    templateUrl: './overview.component.html',
})
export class SCOverviewV2Component implements OnDestroy {
    protected organizationId = inject(UserService).profile.organization.organizationId;
    editing = signal(false);
    uploadLogoError: string;
    readonly maxLength = 380;
    readonly defaultCropCoords = { start: { x: 0, y: 0 }, end: { x: 1, y: 1 } };
    readonly defaultMarketingLogoPageWidth = 100;
    readonly defaultMarketingLogoImagePosition = 'RIGHT';
    originalData: InvestorCompanyOverview;
    form: RealmFormGroup = OverviewForm();
    protected readonly solutionCenter = inject(SolutionCenterV2Component);
    protected readonly investorSolutionCenterService = inject(InvestorSolutionCenterV2Service);
    private readonly formValue = toSignal(this.form.valueChanges);

    constructor() {
        // Update the card on form value changes
        effect(() => {
            const overview = this.solutionCenter.overview();
            const formData = this.formValue();
            const editing = this.editing();
            if (overview && editing) {
                this.solutionCenter.updateOverview(formData as InvestorCompanyOverview);
            }
        }, {allowSignalWrites: true});
    }

    edit() {
        const overview = this.solutionCenter.overview();
        this.originalData = overview;
        this.form.patchValue(
            overview,
            { emitEvent: false },
        );
        this.setEditing(true);
    }

    setEditing(editing: boolean) {
        this.editing.set(editing);
        this.solutionCenter.blockVisibilityToggle(editing);
    }

    async submit() {
        try {
            const data = this.solutionCenter.overview();
            this.solutionCenter.resolving.set(true);
            const { ...companyOverview } = await firstValueFrom(
                await this.investorSolutionCenterService.updateCustomerOverview(data),
            );
            this.solutionCenter.updateCard(companyOverview);
            this.originalData = this.solutionCenter.overview();
            this.cancel();
        } catch ({ error }) {
            this.form.setServerError(error as ServerErrorResponse);
        } finally {
            this.solutionCenter.resolving.set(false);
        }
    }

    cancel() {
        this.uploadLogoError = null;
        this.setEditing(false);
        this.form.reset(this.originalData);
        this.solutionCenter.updateOverview(this.originalData);
    }

    onLogoPick(files: FileList) {
        this.fileValidation(files, 'uploadLogoError');
        if (this.uploadLogoError) {
            return;
        }
        const url = URL.createObjectURL(files[0]);
        this.form.patchValue({
            hasMarketingLogo: true,
            originalMarketingLogo: url,
            marketingLogoCoordinates: this.defaultCropCoords,
            marketingLogoPageWidth: this.defaultMarketingLogoPageWidth,
            marketingLogoImagePosition: this.defaultMarketingLogoImagePosition,
            isMarketingLogoModified: true,
        });
    }

    removeLogo() {
        this.form.patchValue({
            hasMarketingLogo: false,
            marketingLogo: null,
            originalMarketingLogo: null,
            isMarketingLogoModified: true,
        });
    }

    async onCroppedLogoUpdate(url: string) {
        this.form.patchValue({
            marketingLogo: url,
            isMarketingLogoModified: true,
        });
    }

    onCropChange(crop: Line) {
        this.form.patchValue({
            marketingLogoCoordinates: crop,
            isMarketingLogoModified: true,
        });
    }

    fileValidation(files: FileList, type: 'uploadLogoError') {
        this[type] = null;

        if (files.length === 0) {
            this[type] = 'You must select a file to upload.';
            this.form.setServerError( { message: this[type] });
            return;
        }

        const file = files[0];
        const fileTypeConfig = find(SupportedFileTypes, (config) => (
            config.regexp.test(file.name)
        ));

        switch (true) {
            case files.length > 1:
                this[type] = 'You can upload only one file at once.';
                break;
            case file.size > 20 * Math.pow(2, 20): // 20Mb
                this[type] = 'You can upload only a file less than 20 Mb.';
                break;
            case !fileTypeConfig:
                this[type] = 'Wrong file type, please use supported file types for upload: PNG, JPEG, JPG, WEBP';
                break;
        }

        if (this[type]) {
            this.form.setServerError( { message: this[type] });
        }
    }

    ngOnDestroy() {
        this.cancel();
    }

    onSizeChange($event: number) {
        this.form.patchValue({
            marketingLogoPageWidth: $event,
        });
    }

    onPositionChange($event: string) {
        this.form.patchValue({
            marketingLogoImagePosition: $event,
        });
    }
}
