import { Component, input, model, output } from '@angular/core';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { ProductSubscriptionService } from './product-subscription.service';
import { CreatePaymentMethodAndSubscribeToPlan, PurchasePlan } from './product-subscription.interface';
import { firstValueFrom } from 'rxjs';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';
import { StateService } from '@uirouter/core';
import { PaymentMethod } from 'tpo/payment-methods/payment-methods.interface';


@Component({
    templateUrl: 'product-plan-purchase.component.html',
    selector: 'product-plan-purchase',
})
export class ProductPlanPurchaseComponent {
    tpoId = input<number>();
    organizationId = input<number>();
    planId = input<number>();
    productId = input<number>();
    paymentMethods = input<PaymentMethod[]>();
    paymentMethodSelection = model<string>();
    resolved = output<boolean>();

    subscriptionPaymentForm: RealmFormGroup = new RealmFormGroup({
        tokenId: new RealmFormControl(
            'tokenId',
            {},
        ),
        first_name: new RealmFormControl(
            'first_name',
            {
                label: 'First Name',
            },
            Validators.required,
        ),
        last_name: new RealmFormControl(
            'last_name',
            {
                label: 'Last Name',
            },
            Validators.required,
        ),
        email: new RealmFormControl(
            'email',
            {
                label: 'Email',
            },
            [Validators.required, Validators.email],
        ),
        card: new RealmFormControl(
            'card',
            {
                label: 'Card',
                updateOn: 'change',
            },
            Validators.required,
        ),
        costCenter: new RealmFormControl(
            'costCenter',
            {
                label: 'Cost Center',
            },
            Validators.maxLength(999),
        ),
        poNumber: new RealmFormControl(
            'poNumber',
            {
                label: 'PO Number',
            },
            Validators.maxLength(999),
        ),
        paymentMethodNickname: new RealmFormControl(
            'paymentMethodNickname',
            {
                label: 'Payment nickname',
            },
            Validators.required,
        ),
    });

    subscriptionExistingPaymentForm: RealmFormGroup = new RealmFormGroup({
        paymentMethodId: new RealmFormControl(
            'paymentMethodId',
            {
                label: 'Payment Method',
                updateOn: 'change',
            },
            Validators.required,
        ),
        costCenter: new RealmFormControl(
            'costCenter',
            {
                label: 'Cost Center',
            },
            Validators.maxLength(999),
        ),
        poNumber: new RealmFormControl(
            'poNumber',
            {
                label: 'PO Number',
            },
            Validators.maxLength(999),
        ),
    });

    constructor(
        private readonly productSubscriptionService: ProductSubscriptionService,
        private stateService: StateService,
        private notificationsService: GlobalNotificationsService,
    ) {
    }

    async purchasePlanWithNewPaymentMethod() {
        const form = this.subscriptionPaymentForm.getRawValue();
        const paymentMethodAndSubscription: CreatePaymentMethodAndSubscribeToPlan = {
            createPaymentMethod: {
                email: form.email,
                recurlyToken: form.tokenId,
                paymentMethodNickname: form.paymentMethodNickname,
            },
            purchasePlan: {
                paymentMethodId: null,
                poNumber: form.poNumber,
                costCenter: form.costCenter,
            },
        };

        try {
            this.resolved.emit(false);

            await firstValueFrom(
                this.productSubscriptionService.createPaymentMethodAndSubscribeToPlan(
                    this.tpoId(),
                    this.organizationId(),
                    this.productId(),
                    this.planId(),
                    paymentMethodAndSubscription,
                ),
            );
            this.addNotificationMessageOnSuccessfulSubscription();
        } catch ({error: {message}, message: httpError}) {
            this.subscriptionPaymentForm.setServerError({message: message || httpError});
        } finally {
            this.resolved.emit(true);
        }
    }

    changePaymentType(newPaymentTypeValue: string) {
        this.paymentMethodSelection.update( (value) => {
            value = newPaymentTypeValue;
            return value;
        });
        this.subscriptionExistingPaymentForm.reset();
        this.subscriptionPaymentForm.reset();
    }

    async purchasePlanWithExistingPaymentMethod() {
        const existingPaymentMethod: PurchasePlan = this.subscriptionExistingPaymentForm.value;
        try {
            this.resolved.emit(false);
            await firstValueFrom(this.productSubscriptionService.subscribeToProductPlan(
                this.tpoId(),
                this.organizationId(),
                this.productId(),
                this.planId(),
                existingPaymentMethod,
            ));
            this.addNotificationMessageOnSuccessfulSubscription();
        } catch ({error: {message}, message: httpError}) {
            this.subscriptionExistingPaymentForm.setServerError({message: message || httpError});
        } finally {
            this.resolved.emit(true);
        }
    }

    addNotificationMessageOnSuccessfulSubscription() {
        this.notificationsService.add({
            message: 'Subscription Successful',
            type: GlobalNotificationType.POSITIVE,
        });
        this.stateService.go('solution-center');
    }
}
