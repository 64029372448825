import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'empty',
})
export class EmptyPipe implements PipeTransform {
    transform(item: string | number | null, minLength: number = 0, symbol: string = '\xA0'): string | null {
        let result: string = item?.toString() || '–';

        if (result.length < minLength) {
            result += symbol.repeat((minLength - result.length) / symbol.length);
        }

        return result;
    }
}
