import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    NewProductEntity,
    TpoProductEntity,
    UpdateProductEntity
} from 'shared/tpo-products/tpo-product.interface';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { Validators } from '@angular/forms';
import { ConfirmModalComponent } from 'commons/components/modals';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TpoProductsService } from 'shared/tpo-products/tpo-products.service';
import { firstValueFrom } from 'rxjs';
import { UIRouterGlobals } from '@uirouter/core';

@Component({
    templateUrl: 'individual-product-entity-row.component.html',
    selector: 'individual-product-entity-row',
})
export class IndividualProductEntityRowComponent implements OnInit {

    @Input()
    entity: TpoProductEntity;

    @Input()
    editing: boolean;

    @Input()
    userCanAddEntity: boolean;

    @Input()
    canBeDeleted: boolean;

    @Output()
    updateEntity: EventEmitter<TpoProductEntity> = new EventEmitter<TpoProductEntity>();

    @Output()
    removeEntity: EventEmitter<void> = new EventEmitter<void>();

    entityForm: RealmFormGroup = new RealmFormGroup({
        entityName: new RealmFormControl(
            'entityName',
            {
                label: 'Name',
            },
            Validators.required,
        ),
    });

    private bsModalRef: BsModalRef;

    public resolving: boolean;
    public tpoId: number;
    public productId: number;

    constructor(
        private readonly modalService: BsModalService,
        private tpoProductsService: TpoProductsService,
        private uiRouter: UIRouterGlobals,
    ) {
        this.tpoId = this.uiRouter.params.id;
        this.productId = this.uiRouter.params.productId;
    }

    ngOnInit() {
        this.entityForm.patchValue(this.entity);
    }

    public cancel() {
        if(this.entity.entityId) {
            this.entityForm.patchValue(this.entity);
            this.editing = false;
        } else {
            this.removeEntity.emit();
        }
    }

    public async save() {
        if(this.entity.entityName == this.entityForm.get('entityName').value) {
            this.editing = false;
            return;
        }

        if(this.entity.entityId) {
            let updateEntity: UpdateProductEntity = {
                entityName: this.entityForm.get('entityName').value,
                entity: this.entity,
            };

            try {
                this.resolving = true;
                this.entity = await firstValueFrom(this.tpoProductsService.updateEntityName(this.tpoId, this.productId, updateEntity));
            } catch(e) {
                this.entityForm.markAsTouched();
                this.entityForm.controls['entityName'].setErrors({ error: true });
            } finally {
                this.resolving = false;
            }

            this.updateEntity.emit(this.entity);
        } else {
            let newEntity: NewProductEntity = {
                entityName: this.entityForm.get('entityName').value,
                entityTypeId: this.entity.entityTypeId,
            };

            try {
                this.resolving = true;
                this.entity = await firstValueFrom(this.tpoProductsService.createNewEntity(this.tpoId, this.productId, newEntity));
            } finally {
                this.resolving = false;
            }

            this.updateEntity.emit(this.entity);
        }
    }

    public edit() {
        this.editing = true;
    }

    public openDeleteModal() {
        const initialState = {
            title: `Delete ${this.entity.entityName}` ,
            confirmText: 'Delete',
            cancelText: 'Cancel',
            message: `Deleting ${this.entity.entityName} will remove access for all users. Are you sure you want to continue?`,
            onConfirm: async (): Promise<void> => {
                try {
                    this.resolving = true;
                    await firstValueFrom(this.tpoProductsService.deleteEntity(this.tpoId, this.productId, this.entity.entityId));
                    this.removeEntity.emit();
                } finally {
                    this.resolving = false;
                }
                this.modalService.hide();
            },
        };
        this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState,
            class: 'modal-smd modal-new',
        });
    }

}
