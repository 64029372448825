import { RealmFormGroup } from 'commons/forms/form-group.hoc';
import { RealmFormControl } from 'commons/forms/form-control.hoc';
import {
    atLeastOneFieldValidator, FileSizeValidator,
} from 'commons/validators';

export const OverviewForm = () => new RealmFormGroup(
    {
        originalMarketingLogo: new RealmFormControl(
            'originalMarketingLogo',
            {
                label: 'Image',
            },
            FileSizeValidator(20 * Math.pow(2, 20)),
        ),
        marketingLogo: new RealmFormControl(
            'marketingLogo',
            {
                label: 'Image',
            },
        ),
        description: new RealmFormControl(
            'description',
            {
                label: 'Overview Description',
                updateOn: 'change',
            },
        ),
        isMarketingLogoModified: new RealmFormControl(
            'isMarketingLogoModified',
            {},
        ),
        marketingLogoCoordinates: new RealmFormControl(
            'marketingLogoCoordinates',
            {},
        ),
        marketingLogoPageWidth: new RealmFormControl(
            'marketingLogoPageWidth',
            {
                label: 'Image Size',
            },
        ),
        marketingLogoImagePosition: new RealmFormControl(
            'marketingLogoImagePosition',
            {
                label: 'Image Position',
            },
        ),
    },
    {
        validators: atLeastOneFieldValidator(['description', 'originalMarketingLogo'], '<b>An Image or Description is required</b>')
    }
);
