import { ChangeDetectionStrategy, Component, computed, HostBinding, input, output } from '@angular/core';
import { SCProduct, SCProductOverview } from 'lender/solution-center/solution-center.service';
import {
    LenderChannel,
    SC_CARD_TAB,
    SC_CHANNEL_ACTION,
    SC_CHANNEL_HISTORY_ACTION,
    SC_TABS,
    SCCustomerCardOverview,
    SolutionCenterVisibleTabs,
} from '../solution-center.common';
import { ApplicationHistory } from 'tpo/solution-center/tpo-sc-types';

@Component({
    selector: 'sc-company-overview-card',
    templateUrl: './company-overview-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScCompanyOverviewCardComponent {
    companyData = input.required<SCCustomerCardOverview>();
    productOverview = input.required<SCProductOverview>();
    productList = input.required<SCProduct[]>();
    channelList = input<LenderChannel[]>([]);
    applicationHistory = input<ApplicationHistory[]>([]);

    productClick = output<SCProduct>();
    channelClick = output<{channel: LenderChannel, action: SC_CHANNEL_ACTION}>();
    applicationHistoryClick = output<{applicationHistory: ApplicationHistory, action: SC_CHANNEL_HISTORY_ACTION}>();
    sortApplicationHistoryAction = output<string>();

    visibleTabs = input.required<SolutionCenterVisibleTabs>();
    currentTab = input.required<SC_CARD_TAB>();
    labels = computed<string[]>(() => {
        const { loanPrograms, tags } = this.companyData();

        return [
            ...loanPrograms.map(({ name }) => name),
            ...tags.map(({ name }) => name),
        ];
    });
    protected readonly tabList = SC_TABS;
    protected readonly SC_CARD_TAB = SC_CARD_TAB;
    selectTab = output<SC_CARD_TAB>();
    isPreview = input<boolean>(false);
    @HostBinding('class.preview-mode') get preview() {
        return this.isPreview();
    }
}
