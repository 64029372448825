import { map, toPairs, isUndefined } from 'lodash';
import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { StateService } from '@uirouter/core';

import { UserService as UService } from 'angularjs-providers/user.provider';
import { StaticValuesService } from 'angularjs-providers/static-values.provider';
import { StateService as $stateProvider } from 'angularjs-providers/state.provider';

import { SharedUserManagementResourceService } from 'shared/user-management/user-management-resource.service';
import { RolesResourceService } from 'shared/roles/roles-resource.service';
import { BounceEmailService } from 'shared/bounce-email.service';

import { LenderUserDetailsComponent } from 'lender/user-management';
import { LenderUsersResourceService } from 'lender/user-management/users-resource.service';

import { ComergenceCustomerResourceService } from 'comergence/customers/user-management/comergence-customer-resource.service';
import { OrganizationResourceService } from 'comergence/customers/user-management/organization-resource.service';
import { UserManagementCapabilitiesService } from 'commons/services/user-capabilities/user-management-capabilities.service';

@Component({
	templateUrl: './details.component.html',
	selector: 'comergence-customer-user-details',
})
export class ComergenceCustomerUserDetailsComponent extends LenderUserDetailsComponent {
	hasManagers: boolean = false;
	hasChannels: boolean = false;

	unbouncing: boolean = false;

	information: any = {};
	customerType: any;
	organizationId: any;

	constructor(
		public UserService: UService,
		public RolesResource: RolesResourceService,
		public StaticValues: StaticValuesService,
		public LenderUsersService: LenderUsersResourceService,
		public $state: $stateProvider,
		public modalService: BsModalService,
		public UserManagement: SharedUserManagementResourceService,
		public BouncedEmails: BounceEmailService,
		public ComergenceCustomer: ComergenceCustomerResourceService,
		public OrganizationService: OrganizationResourceService,
		public stateService: StateService,
		userManagementService: UserManagementCapabilitiesService,
	) {
		super(
			UserService,
			RolesResource,
			StaticValues,
			LenderUsersService,
			$state,
			modalService,
			UserManagement,
			userManagementService,
		);
	}

	ngOnInit() {
		this.customerType = this.stateService.params.customerType;
		this.organizationId = this.stateService.params.organizationId;

		this.information = this.ComergenceCustomer.get({ organizationId: this.organizationId });

		this.initCommon();

		this.OrganizationService.getServices({ organizationId: this.organizationId },
			(services) => {
				this.hasChannels = services.INVESTOR && services.INVESTOR.statusCode === 'ACTIVE';
				this.hasManagers = services.INVESTOR && services.INVESTOR.statusCode === 'ACTIVE';

				if (!this.hasManagers) {
					return;
				}

				this.loadManagers();
				this.loadChannels();
			},
		);

		if (this.user.$promise) {
			this.user.$promise.then(() => {
				this.filterOutUser();
				this.setFullVisibility();

				this.userForm.patchValue(this.user);
			});
		}
	}

	loadRoles() {
		this.roles = this.OrganizationService.queryRoles({ organizationId: this.organizationId });
	}

	getLoadManagersParams() {
		return { organizationId: this.organizationId };
	}

	loadChannels() {
		this.channels = this.OrganizationService.getInvestorChannels({
			activeOnly: true,
			customerType: this.customerType,
			organizationId: this.organizationId,
		}, (data) => {
			this.channelsData = data.map((channel) => (channel));
		});
	}

	filterOutUser = () => {
		if (this.user?.id && this.hasManagers && this.managers.$resolved) {
			this.managers = this.managers.filter((manager) => {
				return manager.id !== this.user.id;
			});
			// gotcha: after filtering it is not a resource, so add $resolved again
			this.managers.$resolved = true;
		}

		if (this.user && this.user.state === '--') {
			delete this.user.state;
		}
	}

	unbounceEmail() {
		if (!this.user || !this.user.isEmailBounced) {
			return;
		}

		this.unbouncing = true;

		this.BouncedEmails.unbounce({
			email: this.user.email,
		}, () => {
			this.user.isEmailBounced = false;
			this.unbouncing = false;
		}, () => {
			this.unbouncing = false;
		});
	}

	async save() {
		// we can only create users, no edit
		if (!this.isNew) {
			return;
		}

		const data = { ...this.userForm.value };

		// this is hack since disabled form value does not get value
		if (isUndefined(data.isFullVisibilityAllowed)) {
			data.isFullVisibilityAllowed = false;
		}

		if (this.hasChannels && !this.user.channelsAssignedIds.length) {
			this.userForm.serverError = 'User should be assigned to at least one channel';
			return;
		}

		map(toPairs(data), ([fieldName, fieldValue]) => (this.user[fieldName] = fieldValue));

        this.user.$resolved = false;
		this.LenderUsersService.user.save(
			{
				userId: this.stateService.params.id,
				organizationId: this.organizationId,
			},
            this.user,
			this.saveSuccessHandler,
			this.saveErrorHandler,
		);
	}

	saveSuccessHandler = ({ id }) => {
		const href = this.$state.href('customers.:customerType.:organizationId.manage.:actionType.:id', {
			id,
			actionType: 'view',
			customerType: this.customerType,
			organizationId: this.organizationId,
		});
		const link = `<a class="underlined text-success" href="${href}">View User</a>`;

		this.$state.notify('customers.:customerType.:organizationId.manage', {
			customerType: this.customerType,
			organizationId: this.organizationId,
			notification: {
				type: 'alert-success',
				message: `User <b>${this.user.firstName} ${this.user.lastName}</b> was created successfully. ${link}`,
				timeout: 4000,
			},
		});
	}

	setFullVisibility = () => {
		const shouldPreserveValue = this.userForm.get('isReportingManager').value
			|| this.userForm.get('isAccountAssigned').value;

		if (shouldPreserveValue) {
			return;
		}

		const field = this.userForm.get('isFullVisibilityAllowed');
		field.setValue(false);
	}

	isFullVisibilityAllowedDisabled() {
		// in non-edit mode return model values
		if (!this.editable) {
			return !this.user.isAccountAssigned && !this.user.isReportingManager;
		}

		// in edit mode check form values to enable/disable isFullVisibilityAllowed field
		const disabled = !this.userForm.get('isAccountAssigned').value && !this.userForm.get('isReportingManager').value;

		const field = this.userForm.get('isFullVisibilityAllowed');
		const method = disabled ? 'disable' : 'enable';
		// trigger method only if value is changed
		(field.disabled !== disabled) && field[method]();

		return disabled;
	}
}
