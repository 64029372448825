import { Component, input } from '@angular/core';
import { ProductSubscriptionPlan } from './product-subscription.interface';

@Component({
    templateUrl: 'product-plan-view.component.html',
    selector: 'product-plan-view',
})
export class ProductPlanViewComponent {
    productPlan = input<ProductSubscriptionPlan>();
}
