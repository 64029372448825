<div>
    <h2 class="title-label">
        Products
    </h2>
</div>

<div class="rows-bordered remote-resource" [class.resolved]="resolved">
    <div class="row-fluid header">
        <div class="col-md-a">Product</div>
        <div class="col-md-a">Credit Card</div>
        <div class="col-md-a">Visibility</div>
        <div class="col-md-a">Go Live</div>
        <div class="col-md-a">SSO</div>
        <div class="col-f-60 button-holder"></div>
    </div>
    <product-item class="row-fluid"
        *ngFor="let product of productList(); let productIndex = index"
        [item]="product"
        [userCanAddEdit]="userCanAddEdit"
        [organizationId]="organizationId"
        [productIndex]="productIndex">
    </product-item>
</div>
