<div class="split-view remote-resource" [class.resolved]="!resolving()" [class.collapsed]="!previewVisible()">
    <div class="configuration">
        <div class="gray-head">
            <div class="header-block flex-row gap-m">
                <h2 class="no-margin">Solution Center Information</h2>
                <toggle
                    [ngModel]="companyInfo()?.visible"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="isVisibilityBlocked()"
                    [loading]="visibilityResolving()"
                    (toggle)="onChangeVisibility()"
                ></toggle>
                <ng-container [ngSwitch]="companyInfo()?.visible">
                    <span class="label label-success text-smaller" *ngSwitchCase="true">Visible</span>
                    <span class="label label-default text-smaller" *ngSwitchDefault>Hidden</span>
                </ng-container>
                <div class="flex-grow" *ngIf="User().isCustomer">
                    <a class="btn btn-tertiary" href="/help/authenticated/customer/solution-center-information" target="_blank">
                        <i class="glyphicon glyphicon-question-sign"></i> Help
                    </a>
                </div>
                <div class="preview-toggle" (click)="togglePreview()">
                    <img class="preview-chevron" [class.reverse]="!previewVisible()" src="static/images/solution-center/chevron-double-right.svg">
                    <img src="static/images/solution-center/layout-sidebar-inset-reverse.svg">
                </div>
            </div>
            <ul class="tab-menu">
                <ng-container *ngFor="let tab of tabList">
                    <li uiSrefActive="active" *ngIf="visibleTabs()[tab.code]">
                        <a [uiSref]="tab.investorRoute"><b>{{tab.title}}</b></a>
                    </li>
                </ng-container>
            </ul>
        </div>

        <div class="tab-content" ui-view></div>
    </div>
    <div class="preview">
        <div class="scalable-content" [class.centered]="currentTab() == SC_CARD_TAB.COMPANY" #scalableElement [style.scale]="previewScale()" [ngSwitch]="currentTab()" (transitionend)="updateScale()">
            <sc-company-card-v2 *ngSwitchCase="SC_CARD_TAB.COMPANY" [isPreview]="true" [companyData]="companyInfo()" class="mb20"></sc-company-card-v2>
            <sc-company-full-card-v2
                *ngSwitchDefault
                [isPreview]="true"
                [companyData]="fullCompany()"
                [resolving]="resolving()"
                [currentTab]="currentTab()"
                [visibleTabs]="visibleTabs()"
            ></sc-company-full-card-v2>
        </div>
        <div [style.height]="previewMaxHeight()"></div>
    </div>
</div>
