import { Injectable, signal } from '@angular/core';


@Injectable()
export class ProductNameService {

    productName = signal<string>(null);

    constructor(
    ) {
    }
}
