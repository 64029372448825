<div class="remote-resource" [class.resolved]="!resolving">
    <div *ngIf="customer || isNew">
        <h2 *ngIf="!isNew">Customer Information
            <span *ngIf="customer.statusCode == 'ACTIVE'" class="label block va-m text-smaller label-success">Active</span>
            <span *ngIf="customer.statusCode == 'INACTIVE'" class="label block va-m text-smaller label-danger">Inactive</span>
            <div class="pull-right" *ngIf="!isEditing && canManageCustomers" [class.curtain]="isAdditionalFieldsEditing && !isNew">
                <button class="btn btn-success-wired" *ngIf="customer.statusCode && customer.statusCode != 'ACTIVE'" (click)="setStatus('ACTIVE')">
                    Activate
                </button>
                <button class="btn btn-danger-wired"  *ngIf="customer.statusCode && customer.statusCode != 'INACTIVE'" (click)="openDeactivateModal()">
                    Deactivate
                </button>
                <button class="btn btn-primary" *ngIf="customer.statusCode != 'INACTIVE'" (click)="setEditing(true)">
                    Edit
                </button>
            </div>
            <div class="pull-right" *ngIf="isEditing">
                <button class="btn btn-primary" type="submit" form="informationForm">Save</button>
                <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button>
            </div>
        </h2>
        <form [formGroup]="informationForm" id="informationForm" (onValidSubmit)="save()">
            <form-errors [form]="informationForm"></form-errors>

			<div [class.curtain]="isAdditionalFieldsEditing && !isNew">
				<h3>Company Information</h3>

				<div class="rows-striped">
					<div class="row-fluid">
						<div class="col-md-6">
							<div class="form-group row" has-error>
								<label class="col-sm-5 control-label text-right" [class.required]="isEditing">{{informationForm.getFieldLabel('organizationName')}}</label>
								<div class="col-sm-6">
									<div [isEditable]="isEditing">
										<span view class="control-value">{{customer?.organizationName || '&ndash;'}}</span>
										<input edit type="text" name="organizationName" formControlName="organizationName" class="form-control" maxlength="100" tabindex="1" />
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group row" has-error>
								<label class="col-sm-5 control-label text-right">{{informationForm.getFieldLabel('companyNmlsId')}}</label>
								<div class="col-sm-6">
									<div [isEditable]="isEditing">
										<span view class="control-value">{{customer?.companyNmlsId || '&ndash;'}}</span>
										<input edit type="text" name="companyNmlsId" formControlName="companyNmlsId" maxlength="9" class="form-control" tabindex="3" />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row-fluid">
						<div class="col-md-6">
							<div class="form-group row" has-error>
								<label class="col-sm-5 control-label text-right">{{informationForm.getFieldLabel('organizationPhoneNumber')}}</label>
								<div class="col-sm-6">
									<div [isEditable]="isEditing">
										<span view class="control-value">{{customer?.organizationPhoneNumber || '&ndash;'}}</span>
										<input edit type="text" name="organizationPhoneNumber" formControlName="organizationPhoneNumber" class="form-control" maxlength="10" tabindex="2" />
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group row" has-error>
								<label class="col-sm-5 control-label text-right" >{{informationForm.getFieldLabel('businessTaxId')}}</label>
								<div class="col-sm-6">
									<div [isEditable]="isEditing">
										<span view class="control-value">{{customer?.businessTaxId || '&ndash;'}}</span>
										<input edit type="text" name="businessTaxId" formControlName="businessTaxId" class="form-control" maxlength="9" tabindex="4" />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row-fluid" *ngIf="!isNew">
						<div class="col-md-6">
							<div class="form-group row">
								<label class="col-sm-5 control-label text-right">Display Name</label>
								<div class="col-sm-6">
									<span class="control-value">{{customer.displayName || '&ndash;'}}</span>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group row">
								<label class="col-sm-5 control-label text-right">Customer ID</label>
								<div class="col-sm-6">
									<span class="control-value">{{customer.customerId || '&ndash;'}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<br>

				<h3>Physical Address</h3>
				<div class="rows-striped">
					<div class="row-fluid">
						<div class="col-md-6">
							<div class="form-group row" has-error>
								<label class="col-sm-5 control-label text-right" [class.required]="isEditing">{{informationForm.getFieldLabel('address1')}}</label>
								<div class="col-sm-6">
									<div [isEditable]="isEditing">
										<span view class="control-value">{{customer?.address1 || '&ndash;'}}</span>
										<input edit type="text" name="address1" formControlName="address1" class="form-control" maxlength="100" tabindex="5" />
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="form-group row" has-error>
								<label class="col-sm-5 control-label text-right" [class.required]="isEditing">{{informationForm.getFieldLabel('city')}}</label>
								<div class="col-sm-6">
									<div [isEditable]="isEditing">
										<span view class="control-value">{{customer?.city || '&ndash;'}}</span>
										<input edit type="text" name="city" formControlName="city" class="form-control" maxlength="100" tabindex="8" />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row-fluid">
						<div class="col-md-6">
							<div class="form-group row" has-error>
								<label class="col-sm-5 control-label text-right" >{{informationForm.getFieldLabel('address2')}}</label>
								<div class="col-sm-6">
									<div [isEditable]="isEditing">
										<div view class="control-value">{{customer?.address2 || '&ndash;'}}</div>
										<input edit type="text" name="address2" formControlName="address2" class="form-control" maxlength="100" tabindex="6" />
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group row" has-error>
								<label class="col-sm-5 control-label text-right" [class.required]="isEditing">{{informationForm.getFieldLabel('state')}}</label>
								<div class="col-sm-6">
									<div [isEditable]="isEditing">
										<div view class="control-value">{{customer?.state || '&ndash;'}}</div>
										<ng-select
											edit
											formControlName="state"
											[items]="states | async"
											[loading]="loadingValue.states"
											bindLabel="name"
											bindValue="name"
											placeholder="Select state"
											name="state"
											tabindex="9"
										>
										</ng-select>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row-fluid">
						<div class="col-md-6">
							<div class="form-group row" has-error>
								<label class="col-sm-5 control-label text-right">{{informationForm.getFieldLabel('suite')}}</label>
								<div class="col-sm-6">
									<div [isEditable]="isEditing">
										<span view class="control-value">{{customer?.suite || '&ndash;'}}</span>
										<input edit type="text" name="suite" formControlName="suite" class="form-control" maxlength="8" tabindex="7" />
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group row" has-error>
								<label class="col-sm-5 control-label text-right" [class.required]="isEditing">{{informationForm.getFieldLabel('zip')}}</label>
								<div class="col-sm-6">
									<div [isEditable]="isEditing">
										<span view class="control-value">{{customer?.zip || '&ndash;'}}</span>
										<input edit type="text" name="zip" formControlName="zip" class="form-control" maxlength="12" tabindex="10" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<br>
			<additional-fields
				[isNew]="isNew"
				[class.curtain]="isEditing && !isNew"
				entityTypeId="CCM_CUSTOMER"
				[entityId]="customer?.organizationId"
				[editPermission]="canManageCustomers"
				[(editing)]="isAdditionalFieldsEditing"
				[parentForm]="informationForm"
				(groupedFieldsChange)="groupedFields = $event; detectChanges();"
			></additional-fields>
            <div *ngIf="isNew" class="pull-right">
				<br>
                <div>
                    <button class="btn btn-primary" type="submit" [disabled]="resolving">Save</button>
                    <button class="btn btn-default" type="button" (click)="cancel()" [disabled]="resolving">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>
