<section
	class="remote-resource"
	[ngClass]="{ resolved: (!hasChannels || channels?.$resolved) && (!hasManagers || managers?.$resolved) && user?.$resolved && roles?.$resolved,
		preview: !editable }"
>
	<div class="alert text-left {{pageNotification?.type}}" *ngIf="pageNotification">
		<span [ngSwitch]="pageNotification.type">
			<i *ngSwitchCase="alert-success" class="glyphicon glyphicon-ok-sign">&nbsp;</i>
		</span>
		<span>{{pageNotification?.message}}</span>
	</div>

	<form [formGroup]="userForm" id="userForm" (onValidSubmit)="save()" autocomplete="off" role="presentation">
		<div class="clearfix mb20" *ngIf="(editable && isNew) || (!isNew && UserService?.profile?.can('CCM_MANAGE_CUSTOMER_USERS') && (information?.statusCode !== 'INACTIVE'))">
			<div class="pull-left" *ngIf="!isNew && UserService?.profile?.can('CCM_MANAGE_CUSTOMER_USERS') && (information?.statusCode !== 'INACTIVE')">
				<button type="button" class="btn btn-success-wired" (click)="activate()" [disabled]="editable"
					*ngIf="user?.status?.id === userStatus.INACTIVE">Activate User</button>
			</div>

			<div class="pull-right" *ngIf="editable && isNew">
				<input type="submit" class="btn btn-primary" tabindex="16" value="Save">

				<a class="btn btn-default"
					uiSref="customers.:customerType.:organizationId.manage"
					[uiParams]="{ customerType: customerType, organizationId: organizationId }"
				>
					Cancel
				</a>
			</div>
		</div>

		<form-errors [form]="userForm"></form-errors>

		<div class="mb20">
			<h3>User Information</h3>
		</div>

		<div class="rows-striped">
			<div style="overflow: hidden; height: 0;">
				<input type="password" name="fakeusernameremembered">
				<input type="text" name="fakeusernameremembered">
				<input type="email" name="email">
			</div>

			<div class="row-fluid">
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('systemRoleId')">
						<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('systemRoleId')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<ng-select
										*ngIf="roles.$resolved"
										formControlName="systemRoleId"
										[items]="roles"
										bindValue="id"
										bindLabel="name"
										[clearable]="true"
										placeholder="Choose System Role"
										tabindex="3">
									</ng-select>
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{getRoleById(user?.systemRoleId)}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('address1')">
						<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('address1')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<input type="text" name="address1" formControlName="address1" class="form-control" maxlength="100" tabindex="11" />
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{user?.address1 || '&ndash;'}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
			</div>
			<div class="row-fluid">
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('firstName')">
						<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('firstName')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<input type="text" name="firstName" formControlName="firstName" class="form-control" maxlength="50" tabindex="4" />
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{user?.firstName || '&ndash;'}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('address2')">
						<label class="col-sm-3 control-label">{{getLabel('address2')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<input type="text" name="address2" formControlName="address2" class="form-control" maxlength="100" tabindex="12" />
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{user?.address2 || '&ndash;'}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
			</div>
			<div class="row-fluid">
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('lastName')">
						<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('lastName')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<input type="text" name="lastName" formControlName="lastName" class="form-control" maxlength="50" tabindex="5" />
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{user?.lastName || '&ndash;'}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('city')">
						<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('city')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<input type="text" name="city" formControlName="city" class="form-control" maxlength="100" tabindex="13" />
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{user?.city || '&ndash;'}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
			</div>
			<div class="row-fluid">
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('email')">
						<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('email')}}</label>
						<div [ngClass]="{ 'col-sm-6': !editable, 'col-sm-8': editable }">
							<span [class.control-value]="!editable">
								<editable-component [editable]="editable">
									<ng-template #editTemplate>
										<input type="email"
											formControlName="email"
											class="form-control"
											maxlength="100"
											tabindex="6"
											autocomplete="off"
										/>
									</ng-template>
									<ng-template #viewTemplate>
										<a
											*ngIf="user?.email && !user?.isEmailBounced"
											href="mailto:{{user?.email}}"
										>
											{{user?.email || '&ndash;'}}
										</a>
										<span *ngIf="!user?.email || user?.isEmailBounced">{{user?.email || '&ndash;'}}</span>
										<span *ngIf="user?.isEmailBounced">
											&nbsp;<span
												tooltip="Bounce Email"
												placement="top-right"
												class='text-danger glyphicon glyphicon-exclamation-sign'
											></span>
										</span>
									</ng-template>
								</editable-component>
							</span>
						</div>
						<div class="col-sm-2" *ngIf="user?.isEmailBounced && !editable && !unbouncing">
							<a class="btn btn-primary" (click)="unbounceEmail()">Release</a>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('state')">
						<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('state')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<ng-select
										*ngIf="states.$resolved"
										formControlName="state"
										[items]="states"
										bindValue="name"
										bindLabel="name"
										[clearable]="true"
										placeholder="Select states"
										tabindex="14">
									</ng-select>
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{user?.state || '&ndash;'}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
			</div>
			<div class="row-fluid">
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('phone')">
						<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('phone')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<input phoneFormatter type="text" name="phone" formControlName="phone" class="form-control" tabindex="7" />
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{(user?.phone || '&ndash;') | tel}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group row" [class.has-error]="hasError('zip')">
						<label class="col-sm-3 control-label" [class.required]="editable">{{getLabel('zip')}}</label>
						<div class="col-sm-8">
							<editable-component [editable]="editable">
								<ng-template #editTemplate>
									<input type="text" name="zip" formControlName="zip" class="form-control" maxlength="20" tabindex="15" />
								</ng-template>
								<ng-template #viewTemplate>
									<div class="control-value">
										{{user?.zip || '&ndash;'}}
									</div>
								</ng-template>
							</editable-component>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="hasChannels || hasManagers">
			<br />
			<div class="mb20">
				<h3>Third Party Oversight</h3>
			</div>

			<div class="rows-striped">
				<div class="row-fluid">
					<div class="col-md-12">
						<div class="form-group row">
							<label class="coldp-sm-3 control-label" [class.required]="editable">Channel</label>
							<div class="coldp-sm-21 control-value check-list" [class.row]="editable" *ngIf="channels.$resolved">
								<div class="row">
									<div class="col-sm-5 clearfix" *ngFor="let channel of channelsData">
										<div class="pull-left">
											<editable-component [editable]="editable">
												<ng-template #editTemplate>
													<input name="user.channelsAssignedIds[]"
														type="checkbox"
														[checked]="user.channelsAssignedIds && user.channelsAssignedIds.indexOf(channel.id) > -1"
														value="{{channel.id}}"
														(click)="toggleAssignedChannels(channel.id)"
														tabindex="1">
												</ng-template>
												<ng-template #viewTemplate>
													<span class="glyphicon"
														[ngClass]="{'glyphicon-ok text-success': user.channelsAssignedIds && user.channelsAssignedIds.indexOf(channel.id) > -1,
															'glyphicon-remove text-danger': user.channelsAssignedIds && user.channelsAssignedIds.indexOf(channel.id) === -1}"
														aria-hidden="true"
													></span>
												</ng-template>
											</editable-component>
										</div>
										<label class="text-left col-sm-10 weight-normal" (click)="toggleAssignedChannels(channel.id)"> {{channel.name}}</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row-fluid">
					<div class="col-md-12">
						<div class="form-group row">
							<label class="coldp-sm-3 control-label">Assignment</label>
							<div class="coldp-sm-21 control-value check-list" [class.row]="editable">
								<div class="row">
									<div class="col-sm-5 clearfix">
										<div class="pull-left">
											<editable-component [editable]="editable">
												<ng-template #editTemplate>
													<input (click)="setFullVisibility()"
														formControlName="isReportingManager"
														id="isReportingManager"
														type="checkbox"
														tabindex="8"
													>
												</ng-template>
												<ng-template #viewTemplate>
													<span class="glyphicon"
														[ngClass]="{'glyphicon-ok text-success': user?.isReportingManager,
															'glyphicon-remove text-danger': !user?.isReportingManager}"
														aria-hidden="true"
													></span>
												</ng-template>
											</editable-component>
										</div>
										<label class="text-left weight-normal" (click)="toggleReportingManager()">{{getLabel('isReportingManager')}}</label>
									</div>

									<div class="col-sm-5 clearfix">
										<div class="pull-left">
											<editable-component [editable]="editable">
												<ng-template #editTemplate>
													<input (click)="setFullVisibility()"
														formControlName="isAccountAssigned"
														id="isAccountAssigned"
														type="checkbox"
														tabindex="9"
													>
												</ng-template>
												<ng-template #viewTemplate>
													<span class="glyphicon"
														[ngClass]="{'glyphicon-ok text-success': user?.isAccountAssigned, 'glyphicon-remove text-danger': !user?.isAccountAssigned}"
														aria-hidden="true"
													></span>
												</ng-template>
											</editable-component>
										</div>
										<label class="text-left weight-normal" (click)="toggleAccountAssignment()">{{getLabel('isAccountAssigned')}}</label>
									</div>

									<div class="col-sm-5 clearfix">
										<div class="pull-left">
											<editable-component [editable]="editable">
												<ng-template #editTemplate>
													<input
														formControlName="isFullVisibilityAllowed"
														id="isFullVisibilityAllowed"
														type="checkbox"
														tabindex="9"
													>
												</ng-template>
												<ng-template #viewTemplate>
													<span class="glyphicon"
														[ngClass]="{'glyphicon-ok text-success': user?.isFullVisibilityAllowed,
															'glyphicon-remove text-danger': !user?.isFullVisibilityAllowed}"
														aria-hidden="true"
													></span>
												</ng-template>
											</editable-component>
										</div>
										<label [ngClass]="{ 'text-muted': editable && isFullVisibilityAllowedDisabled() }"
											class="text-left weight-normal"
											(click)="toggleFullVisibilityAllowed()">{{getLabel('isFullVisibilityAllowed')}}</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row-fluid">
					<div class="col-md-12">
						<div class="form-group row" has-error>
							<label class="coldp-sm-3 control-label">{{getLabel('reportsToUserId')}}</label>
							<div class="coldp-sm-9">
								<editable-component [editable]="editable">
									<ng-template #editTemplate>
										<ng-select
											*ngIf="managers.$resolved"
											formControlName="reportsToUserId"
											[items]="managers"
											bindValue="id"
											bindLabel="personalName"
											[clearable]="true"
											placeholder="None"
											tabindex="10">
										</ng-select>
									</ng-template>
									<ng-template #viewTemplate>
										<span class="control-value">
											{{managersMap[user?.reportsToUserId] || '&ndash;'}}
										</span>
									</ng-template>
								</editable-component>
							</div>
                            <label class="coldp-sm-3 control-label">Report Access</label>
                            <div class="coldp-sm-9">
                                <div class="control-value">
                                    {{user.reportingGroups | reportingGroups}}
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</section>
