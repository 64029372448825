<div class="my-settings">
	<div class="gray-head">
		<h2>My Settings</h2>
	</div>
	<section class="manage-page remote-resource" [class.resolved]="userSettings?.$resolved && user?.$resolved">
		<lender-user-details
			*ngIf="userSettings?.$resolved && user?.$resolved"
			[user]="user"
			[settingsView]="true"
			[linkToRole]="true"
		></lender-user-details>
	</section>
</div>
