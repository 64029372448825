<div class="review-header-wrapper flex-row gap-m">
    <h2>
        Channels
    </h2>
</div>
<div class="remote-resource" [class.resolved]="resolved()">
    <div class="text-bigger text-center text-muted" *ngIf="!list().length">No records added</div>
    <ng-container *ngIf="list().length as length">
        <columns [count]="2">
            <ng-container *ngFor="let item of list(); last as last">
                <ng-template #cell>
                    <new-channel-card [lenderInfo]="lenderInfo" [expanded]="length <= 4" [channel]="item"></new-channel-card>
                </ng-template>
            </ng-container>
        </columns>
    </ng-container>
</div>
