import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RealmHttpClient } from 'commons/services/http';

import {
    CcmCustomerProductNew,
    ProductFeature
} from 'comergence/ccm-customer-product-new/ccm-customer-product-new.interface';

declare let apiPath: string;
@Injectable()
export class CcmCustomerProductsNewService {
    constructor(
        private readonly http: RealmHttpClient,
    ) {}

    public getCustomerProductDetails(organizationId: number): Observable<CcmCustomerProductNew[]> {
        return this.http.request<CcmCustomerProductNew[]>(
            'GET',
            `${apiPath}/v2/organizations/${organizationId}/products`
        );
    }

    public updateCustomerProduct(productId: number, organizationId: number, enabled: boolean, key: ProductFeature): Observable<CcmCustomerProductNew> {
        return this.http.request<CcmCustomerProductNew>(
            'PUT',
            `${apiPath}/v2/organizations/${organizationId}/products/${productId}/${key}`,
            {},
            {
                enabled,
            },
        );
    }

    public updateCustomerProductName(productId: number, organizationId: number, updatedProduct: Record<string, string>): Observable<CcmCustomerProductNew> {
        return this.http.request<CcmCustomerProductNew>(
            'PUT',
            `${apiPath}/v2/organizations/${organizationId}/products/${productId}/product-name`,
            {},
            updatedProduct
        );
    }
}
