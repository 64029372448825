import { Component, OnInit, signal, WritableSignal } from '@angular/core';

import { LenderUserPanelProduct, LenderUserPanelProductLicenseStatus } from './lender-user-panel-products.interface';
import { firstValueFrom } from 'rxjs';
import { LenderUserPanelProductsService } from './lender-user-panel-products.service';
import { UIRouterGlobals } from '@uirouter/core';
import { UserService } from 'angularjs-providers/user.provider';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LenderUserPanelProductAccessModalComponent } from './lender-user-panel-product-access.modal.component';

@Component({
    selector: 'lender-user-panel-products-list',
    templateUrl: './lender-user-panel-products-list.component.html',
})
export class LenderUserPanelProductsListComponent implements OnInit {
    static listName = 'lenderUserPanelProductsList';

    resolving: boolean;
    canManagePayments: boolean;
    protected contactId: number;
    public list: WritableSignal<LenderUserPanelProduct[]> = signal<LenderUserPanelProduct[]>([]);

    constructor(
        private lenderUserPanelProductsService: LenderUserPanelProductsService,
        private readonly userService: UserService,
        private readonly modalService: BsModalService,
        protected router: UIRouterGlobals
    ) {}

    async ngOnInit() {
        this.contactId = this.router.params.contactId;
        this.canManagePayments = this.userService.profile.can('TPO_MANAGE_PAYMENTS');
        await this.retrieveProducts();
    }

    private async retrieveProducts(): Promise<void> {
        try {
            this.resolving = true;
            this.list.set(await firstValueFrom(this.lenderUserPanelProductsService.getLenderUserProductsList(this.contactId)));
        } finally {
            this.resolving = false;
        }
    }

    openProductLicenseModal(productId: number, productName: string, licenseStatus: string) {
        this.modalService.show(
            LenderUserPanelProductAccessModalComponent,
            {
               initialState: {
                   productId: productId,
                   title: `Manage ${productName}`,
                   licenseStatus: licenseStatus as LenderUserPanelProductLicenseStatus,
                   contactId: this.contactId,
                   showUserList: false,
                   reloadProducts: async () => {
                       await this.retrieveProducts();
                   },
               },
                class: 'modal-new modal-smd',
                backdrop: 'static',
            }
        );
    }

    protected readonly LenderUserPanelProductLicenseStatus = LenderUserPanelProductLicenseStatus;
}
