import { Component } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { asyncScheduler } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { LenderIssuesStatusService } from 'lender/status/lender-issues-status.service';
import { ProspectsListComponent } from 'lender/prospects/list/prospects-list.component';
import { NavigationMenuItem } from '../../../../utils/navigation/navigation.interface';
import { AbstractRoleNavComponent } from '../abstract-role-nav.component';

const {
    featureToggle_thinkific_enabled: thinkificEnabled,
} = window.env

@Component({
	selector: 'customer-topnav',
	templateUrl: './role-topnav.component.html',
})
export class CustomerTopNavComponent extends AbstractRoleNavComponent {
    constructor(
            stateService: StateService,
            userService: UserService,
            private readonly lenderIssuesStatusService: LenderIssuesStatusService,
    ) {
        super(stateService, userService);
    }

    // Override
    public ngOnInit(): void {
        this.userService.profile$.pipe(
            takeUntil(this.userProfileUnsubscribe$),
            throttleTime((60 * 1000), asyncScheduler, { leading: true, trailing: true }),
        ).subscribe(() => {
            this.lenderIssuesStatusService.documents.docusign.update();
        });

        super.ngOnInit();
    }

    // Override
    protected rebuildMenuItems(userProfile: UserProfile): NavigationMenuItem[] {
        const menuItems: NavigationMenuItem[] = [];

        menuItems.push({
            sref: 'home',
            title: 'Home',
            isActive: () => this.stateService.includes('home'),
        });

        if (userProfile.isLender) {
            const clientsSubMenuItems: NavigationMenuItem[] = [];
            clientsSubMenuItems.push({
                sref: 'clients.list',
                title: 'Clients',
            });
            clientsSubMenuItems.push({
                sref: 'invites',
                title: 'Invites',
            });
            clientsSubMenuItems.push({
                sref: 'applications',
                title: 'Applications',
            });
            if (userProfile.can('VIEW_ALERTS')) {
                clientsSubMenuItems.push({
                    sref: 'alerts',
                    title: 'Alerts',
                });
            }
            if (userProfile.can('VIEW_CLIENT_QUESTIONNAIRE')) {
                clientsSubMenuItems.push({
                    sref: 'questionnaires.list',
                    title: 'Questionnaires',
                });
            }

            menuItems.push({
                sref: '',
                title: 'Clients',
                isActive: this.coalesceIsActive(clientsSubMenuItems),
                hasAlert: this.coalesceHasAlert(clientsSubMenuItems),
                items: clientsSubMenuItems,
            });

            if (userProfile.crmEnabled) {
                const marketingSubMenuItems: NavigationMenuItem[] = [];

                marketingSubMenuItems.push({
                    sref: 'crm.market',
                    srefParams: { q: '', page: 0 },
                    title: 'Market',
                });
                marketingSubMenuItems.push({
                    sref: 'crm.activities.list',
                    srefParams: { statusId: [ 'PL', 'IP' ] },
                    title: 'Activities',
                });
                marketingSubMenuItems.push({
                    sref: 'crm.contacts',
                    title: 'Contacts',
                });
                marketingSubMenuItems.push({
                    sref: `crm.prospects`,
                    srefParams: {[ProspectsListComponent.listName]: { prospectAssigneeId: [ userProfile.id ], prospectState: [ 'O' ] }},
                    title: 'Prospects',
                });
                marketingSubMenuItems.push({
                    sref: 'crm.contact.lists',
                    title: 'Contact Lists',
                });

                menuItems.push({
                    sref: '',
                    title: 'Marketing',
                    isActive: this.coalesceIsActive(marketingSubMenuItems),
                    hasAlert: this.coalesceHasAlert(marketingSubMenuItems),
                    items: marketingSubMenuItems,
                });
            }

            menuItems.push({
                sref: '/api/rest/tableau/redirect',
                title: 'Reports',
                rawHref: true,
            });
        }

        if (thinkificEnabled === 'true') {
            menuItems.push({
                sref: '/api/rest/thinkific/redirect',
                rawHref: true,
                rawHrefTarget: '_blank',
                srefOptions: {reload: true},
                title: 'Academy',
            });
        }

        return menuItems;
    }
}
