<form class="remote-resource" autocomplete="off" [class.resolved]="noUser || (!saving && !!contact && currentUser?.$resolved && roles?.$resolved)"  [formGroup]="settingsForm"  (onValidSubmit)="editing && triggerSave()">
	<disable-autofill></disable-autofill>
	<h3>
		User Settings
		<div class="clearfix" *ngIf="contact && (isOwnContact || hasPermission('MANAGE_USER') || User?.isComergence)">
			<br>

            <ng-container *ngIf="!contact?.isPrevious">
                <ng-container *ngIf="!isOwnContact && hasPermission('MANAGE_USER')">
                    <a *ngIf="contact?.userStatus?.id === USER.ACTIVE" class="btn btn-danger-wired" (click)="confirmDialog('deactivate')">Deactivate</a>
                    <a *ngIf="contact?.userStatus?.id === USER.INACTIVE || contact?.userStatus?.id === USER.REMOVED" class="btn btn-success-wired" (click)="confirmDialog('activate')">Activate</a>
                </ng-container>

                <a *ngIf="noUser && hasPermission('MANAGE_USER')" (click)="grantAccess()" class="btn btn-default">Grant Access</a>
            </ng-container>


            <div class="pull-right" *ngIf="!User?.isComergence || (User?.isComergence && User?.can('CCM_MANAGE_CLIENT_USERS'))">
				<button *ngIf="!noUser && !editing" (click)="initEditForm()" class="btn btn-primary" type="button"><span class="glyphicon glyphicon-pencil"></span> Edit</button>
				<button *ngIf="editing" type="submit" class="btn btn-primary">Save</button>
				<a *ngIf="editing" (click)="cancelEdit()" class="btn btn-default">Cancel</a>
			</div>

		</div>

	</h3>

	<form-errors *ngIf="editing" [form]="settingsForm"></form-errors>

	<div class="rows-striped" *ngIf="!noUser">

		<div class="row-fluid">
			<div class="col-md-5">
				<div class="form-group row">
					<label class="col-sm-5 control-label">Status</label>
					<div class="col-sm-7">
						<span class="control-value text-bigger">
							<span class="label" [ngClass]="StatusColors[contact?.userStatus?.id]">{{contact?.userStatus?.name}}</span>
						</span>
					</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="form-group row" has-error>
					<label class="col-sm-5 control-label" [class.required]="editing">Role</label>
					<div class="col-sm-7">
						<span *ngIf="!(editing && roles.$resolved && hasPermission('MANAGE_USER'))" class="control-value">
							{{currentUser?.role?.name || '&ndash;'}}
						</span>
						<ng-select
							*ngIf="editing && roles.$resolved && hasPermission('MANAGE_USER')"
							formControlName="id"
							[items]="roles"
							bindValue="id"
							bindLabel="name"
							placeholder="Select role">
						</ng-select>
					</div>
				</div>
			</div>
			<div class="col-md-1"></div>
		</div>
	</div>

	<div class="pull-right" *ngIf="editing && contact && hasPermission('MANAGE_USER') && isNew">
		<br>
		<button type="submit" class="btn btn-primary">Save</button>
		<a (click)="cancelEdit()" class="btn btn-default">Cancel</a>
	</div>
</form>
<br>
<div *ngIf="currentUser?.$resolved" class="remote-resource" [class.resolved]="!saving && currentRole?.$resolved">
	<div class="users-management">
		<permission-sections *ngIf="currentRole?.permissionsMap" permissionType="tpo" [editing]="false" [permissionState]="currentRole.permissionsMap"></permission-sections>
	</div>
</div>
