import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { PipesModule } from 'commons/pipes/pipes.module';
import { ComponentsModule } from 'commons/components/components.module';
import { DirectivesModule } from 'commons/directives/directives.module';
import { PaymentMethodsService } from "./payment-methods.service";
import { TpoPaymentMethodsComponent } from "./payment-methods.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
        DirectivesModule,
        PipesModule,
    ],
    declarations: [
        TpoPaymentMethodsComponent
    ],
    providers: [
        PaymentMethodsService
    ],
})
export class TpoPaymentMethodsModule { }
