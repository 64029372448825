import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from 'commons/directives/directives.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { ComponentsModule } from 'commons/components/components.module';

import { TpoContactsModule } from './contacts';
import { TpoPeopleMenuComponent } from './people-menu.component';
import { TpoPeopleComponent } from './people.component';
import { PeopleOwnershipListComponent } from './ownership-list/people-ownership-list.component';
import { PeopleCompanyDetailsComponent } from './contacts/company-details/people-company-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PeopleBothListsComponent } from 'tpo/people/embedded/both.component';
import { TpoEmbeddedContactDetailsComponent } from 'tpo/people/embedded/embedded-contact-details.component';
import { NewContactsModule } from 'shared/new-contacts';
import { LenderUserPanelProductsListComponent } from "./products/lender-user-panel-products-list.component";
import { LenderUserPanelProductsService } from "./products/lender-user-panel-products.service";
import { LenderUserPanelProductAccessModalComponent } from "./products/lender-user-panel-product-access.modal.component";

@NgModule({
    imports: [
        CommonModule,
		FormsModule,    // TODO: Remove this when PeopleCompanyDetailsComponent has been updated
		ReactiveFormsModule,    // TODO: Remove this when PeopleCompanyDetailsComponent has been updated
        TpoContactsModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        TpoContactsModule,
        NewContactsModule,
    ],
    providers: [
        LenderUserPanelProductsService,
    ],
    declarations: [
        TpoPeopleMenuComponent,
        TpoPeopleComponent,
        PeopleOwnershipListComponent,
        PeopleCompanyDetailsComponent,
        PeopleBothListsComponent,
        TpoEmbeddedContactDetailsComponent,
        LenderUserPanelProductsListComponent,
        LenderUserPanelProductAccessModalComponent
    ],
})
export class TpoPeopleModule {}
