import { ChangeDetectionStrategy, Component, forwardRef, inject } from '@angular/core';

import { UIRouter } from '@uirouter/core';
import { map, mapValues } from 'lodash';

import { Observable } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ListComponent } from 'commons/components/list';
import { ListParams, NewListComponent } from 'commons/components/new-list/list.component';

import {
     LenderSolutionCenterV2Service,
} from 'tpo/solution-center-v2/solution-center.service';
import {
    InvestorType,
    SCCustomerCard,
    Tag,
    LoanProgram,
} from 'shared/solution-center-v2/solution-center.common';

@Component({
    selector: 'solution-center-v2-list',
    templateUrl: './solution-center-list.component.html',
    viewProviders: [
        { provide: ListComponent, useExisting: forwardRef(() => LenderSolutionCenterListV2Component) },
        { provide: NewListComponent, useExisting: forwardRef(() => LenderSolutionCenterListV2Component) },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LenderSolutionCenterListV2Component extends NewListComponent<SCCustomerCard> {
    static listName = 'solutionCenterV2List' as const;
    protected readonly lenderSolutionCenterService = inject(LenderSolutionCenterV2Service);
    private readonly modalService = inject(BsModalService);
    private modalRef: BsModalRef;

    filters: Partial<{
        tags: Observable<Tag[]>,
        loanPrograms: Observable<LoanProgram[]>,
        investorType: Observable<InvestorType[]>,
    }> = {};

    constructor(
        uiRouter: UIRouter,
    ) {
        super(
            uiRouter,
        );
        this.prepareFilters();
    }

    prepareFilters() {
        this.filters = {
            tags: this.lenderSolutionCenterService.getAvailableTags(),
            loanPrograms: this.lenderSolutionCenterService.getAvailableLoanPrograms(),
            investorType: this.lenderSolutionCenterService.getAvailableInvestorsTypes(),
        };

        this.defaultParams = {
            ...this.defaultParams,
            tags: [],
            loanPrograms: [],
            investorType: [],
        }
    }

    setFilter(filters: ListParams): void {
        const processedFilters = mapValues(filters, (value: any, key: string) => {
            if (key === 'tags' || key === 'loanPrograms') {
                return map(value || [], 'code');
            } else if (key === 'investorType') {
                return value?.code;
            }

            return value;
        });

        super.setFilter(processedFilters);
    }

    loadList(params: ListParams): Observable<SCCustomerCard[]> {
        return this.lenderSolutionCenterService.getCustomersList(params);
    }
}
