import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { SCFullCompany } from 'tpo/solution-center-v2/solution-center.service';

@Component({
    selector: 'sc-cfc-v2-overview',
    templateUrl: './overview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ScCfcV2OverviewComponent {
    companyData = input.required<SCFullCompany>();
    alignClass = computed(() =>
        'align-img-' + (this.companyData()?.marketingLogoImagePosition || 'RIGHT').toLocaleLowerCase(),
    );
}
