import { RealmFormControl, RealmFormGroup } from 'commons/forms';

export const channelForm = () => (new RealmFormGroup({
    eligibilityDate: new RealmFormControl(
        'eligibilityDate',
        {
            label: 'Eligible to Apply',
        },
    ),
}));
