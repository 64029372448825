import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable, of, ReplaySubject, merge } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { PagedData, RealmHttpClient } from 'commons/services/http';
import { NgResourceObject } from 'commons/interfaces';

import { RELATION } from 'shared/models/Relation';
import { AccountInformationResourceService } from 'shared/account/company-information/account-information-resource.service';

import { UserService } from 'angularjs-providers/user.provider';

declare let apiPath;

const lenderInfoCommon = (id: number, isRegistered: boolean, accountInformationService: AccountInformationService) => {
    const identifier: AccountIdentifier = isRegistered ? { tpoId: id } : { nmlsId: id };
    return accountInformationService.getInfo(id, isRegistered).pipe(
        map(data => ({
            ...data,
            isRegistered,
            identifier,
            hasAccess: (...levels) => levels.includes(data.profileAccessLevel?.id),
        } as AccountInformation)),
        shareReplay(1),
    ).toPromise();
};

export const lenderInfo$: (UIRouter, AccountInformationService) => Promise<AccountInformation> = (router: UIRouter, accountInformationService: AccountInformationService) => {
    const isRegistered = !router.globals.transition.to().name.match(/^nmls\./);
    const { id } = router.globals.transition.params();
    return lenderInfoCommon(id, isRegistered, accountInformationService);
};

export const lenderInfoSelf$: (UserService, AccountInformationService) => Promise<AccountInformation> = ({ profile }: UserService, accountInformationService: AccountInformationService) => {
    const isRegistered = true;
    const { id } = profile.organization;
    return lenderInfoCommon(id, isRegistered, accountInformationService);
};

export const accountIdentifierSelf$: (userService: UserService) => AccountIdentifier = (userService: UserService) => {
    return { tpoId: userService.profile.organization.id };
}

export const accountIdentifier$: (router: UIRouter) => AccountIdentifier = (router: UIRouter) => {
    const isRegistered = !router.globals.transition.to().name.match(/^nmls\./);
    const { id } = router.globals.transition.params();

    return isRegistered ? { tpoId: id } : { nmlsId: id };
}

@Injectable()
export class AccountInformationService {
    private triggerBadgeUpdate$ = new ReplaySubject<void>(1);

	constructor(
		public accountInformationResource: AccountInformationResourceService,
        private http: RealmHttpClient,
	) {}

	public get(id: number, isRegistered: boolean): NgResourceObject<AccountInformation> {
		const context = isRegistered ? 'clientDetails' : 'nmlsClientDetails';
		const params = {
			[isRegistered ? 'tpoId' : 'nmlsId']: id,
		};
		return this.accountInformationResource[context].get(params);
	}

	public update(dto: AccountInformation): NgResourceObject<AccountInformation> {
        return this.accountInformationResource.clientDetails.update({ ...dto });
    }

    public canAccessClientUID(stateParamTpoId: string): NgResourceObject<CanAccessClientUid> {
        return this.accountInformationResource.canAccessClientUID.get({ tpoId: stateParamTpoId });
    }

    public setClientUID(stateParamTpoId: string, clientUID: string) {
        return this.accountInformationResource.clientUID.set({ tpoId: stateParamTpoId }, clientUID);
    }

    getInfo<T = AccountInformation>(id, isRegistered): Observable<T> {
        const url = isRegistered
            ? `${apiPath}/tpos/${id}/company-information`
            : `${apiPath}/nmls/company/${id}/company-information`;

        return this.http.request<T>(
            'GET',
            url,
        );
    }

    updateBadges(): void {
        this.triggerBadgeUpdate$.next();
    }

    getChannelBadges<T extends LenderBadges = LenderBadges>(id, lenderId): Observable<T> {
        return this.triggerBadgeUpdate$.pipe(
            switchMap(
                () => merge(
                    of({ loading: true } as T),
                    this.http.request<T>(
                        'GET',
                        `${apiPath}/lenders/${lenderId}/badges/tpos/${id}`,
                    ).pipe(map(({ channels }) => ({ channels, loading: false } as T))),
                ),
            ),
        );
    }

	public getAccountInformationHistory(nmlsId: number, listParams): Observable<PagedData<AccountInformationHistory[]>> {
		return this.http.pagedRequest<AccountInformationHistory[]>(
			'GET',
			`${apiPath}/nmls/company/${nmlsId}/company-information/history`,
			listParams,
		);
	}
}

export interface ProfileAccessLevel {
    id: string;
    name: string;
}

export type TpoIdentifier = { tpoId: number, nmlsId?: null };
export type NmlsIdentifier = { tpoId?: null, nmlsId: number };
export type AccountIdentifier = TpoIdentifier | NmlsIdentifier;
export type AccountInformation = {
    identifier?: AccountIdentifier;
	businessEntity: string | null;
	businessTaxId: string | null;
	city: string | null;
	companyAddress: string | null;
	companyName: string | null;
	dateFormed: string | null;
	fDICNCUACertificationNumber: string | null;
	fax: string | null;
	fiscalYearEnd: string | null;
	hasMcr: boolean;
	latitude: number | null;
	longitude: number | null;
	mersId: number | null;
	nmlsId: number | null;
	nmlsRemovedDate: string | null;
	organizationId: number | null;
	ownership99PercentRequired: boolean | null;
	phone: string | null;
	primaryFederalRegulator: string | null;
    profileAccessLevel?: {
        id: PROFILE_RELATION;
        name: string;
    };
	relationshipStatus: ProfileAccessLevel;
	registrationDate: string | null;
	registrationStatus: string | null;
	state: string | null;
	stateOfIncorporation: string | null;
	tickerSymbol: string | null;
	validTickerSymbol: boolean;
	websites: string[] | null;
	zip: string | null;
	clientSID: string | null; // only on the investor side
    clientUID: string | null; // only on the investor side
    isRegistered?: boolean;
    hasAccess?: (...level: PROFILE_RELATION[]) => boolean;
}

export interface CanAccessClientUid {
    tpoId: number;
    canAccessClientUid: boolean;
}

export type LenderBadges = {
    loading: boolean;
    channels?: {
        activeRelationships: number;
        lenderChannels: number;
    };
};

export type PROFILE_RELATION = typeof RELATION[keyof typeof RELATION];

export interface AccountInformationHistory {
	id: number;
	action: string;
	actionBy: string;
	actionAt: number;
}
