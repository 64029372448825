import { Injectable } from '@angular/core';
import { PagedData, RealmHttpClient, RealmPagedParams } from 'commons/services/http';
import {
    NewProductEntity,
    TpoAccessibleProducts,
    TpoProduct,
    TpoProductDetails,
    TpoProductEntity,
    TpoProductEntityGroup,
    UpdateProductEntity,
    TpoProductUpdateOptions,
    TpoProductUpdateRequestBody,
    CcmTpoProductUpdateRequestBody,
    TpoOrganizationUser
} from './tpo-product.interface';
import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

declare let apiPath;

@Injectable()
export class TpoProductsService {
    constructor(
        protected http: RealmHttpClient,
    ) {
    }

    getProductsByTpoId(tpoId: number, includeInactive: boolean): Observable<TpoProduct[]> {
        return this.http.request<TpoProduct[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/products?includeInactive=${includeInactive}`,
        ).pipe(
            shareReplay(1),
        );
    }

    getEntitiesGroupedByType(tpoId: number, productId: number): Observable<TpoProductEntityGroup[]> {
        return this.http.request<TpoProductEntityGroup[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/products/${productId}/entities`,
        );
    }

    getProductByTpoId(tpoId: number, productId: number): Observable<TpoProduct> {
        return this.http.request<TpoProduct>(
            'GET',
            `${apiPath}/tpos/${tpoId}/products/${productId}`,
        ).pipe(
            shareReplay(1),
        );
    }

    getTpoAccessibleProductAndPlan(tpoId: number): Observable<TpoAccessibleProducts[]> {
        return this.http.request<TpoAccessibleProducts[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/available-products`,
        );
    }

    addTPOProductAccess(tpoId: number, newTpoProduct: TpoProductDetails): Observable<TpoProduct> {
        return this.http.request<TpoProduct>(
            'POST',
            `${apiPath}/tpos/${tpoId}/products`,
            null,
            newTpoProduct,
        );
    }

    createNewEntity(tpoId: number, productId: number, newEntity: NewProductEntity): Observable<TpoProductEntity> {
        return this.http.request<TpoProductEntity>(
            'POST',
            `${apiPath}/tpos/${tpoId}/products/${productId}/entities`,
            null,
            newEntity,
        );
    }

    updateEntityName(tpoId: number, productId: number, entityToUpdate: UpdateProductEntity): Observable<TpoProductEntity> {
        return this.http.request<TpoProductEntity>(
            'PUT',
            `${apiPath}/tpos/${tpoId}/products/${productId}/entities/${entityToUpdate.entity.entityId}`,
            null,
            { entityName: entityToUpdate.entityName },
        );
    }

    getTPOProductUpdateOptions(tpoId: number): Observable<TpoProductUpdateOptions> {
        return this.http.request<TpoProductUpdateOptions>(
            'GET',
            `${apiPath}/tpos/${tpoId}/products/update-options`,
        );
    }

    ccmUpdateTPOProduct(
        tpoId: number,
        productId: number,
        updateRequest: CcmTpoProductUpdateRequestBody
    ): Observable<TpoProduct> {
        return this.http.request<TpoProduct>(
            'POST',
            `${apiPath}/tpos/${tpoId}/products/${productId}/update-access`,
            null,
            updateRequest,
        );
    }

    updateTPOProduct(
        tpoId: number,
        productId: number,
        updateRequest: TpoProductUpdateRequestBody
    ): Observable<TpoProduct> {
        return this.http.request<TpoProduct>(
            'PUT',
            `${apiPath}/tpos/${tpoId}/products/${productId}`,
            null,
            updateRequest,
        );
    }

    deleteEntity(tpoId: number, productId: number, entityId: number): Observable<void> {
        return this.http.request<void>(
            'DELETE',
            `${apiPath}/tpos/${tpoId}/products/${productId}/entities/${entityId}`,
        );
    }

    getActiveUsersLicense(tpoId: number, productId: number, listParam: RealmPagedParams): Observable<PagedData<TpoOrganizationUser[]>> {
        return this.http.pagedRequest<TpoOrganizationUser[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/products/${productId}/user-active-product-license`,
            listParam
        );
    }
}
