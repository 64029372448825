<div>
    <h2 class="flex-row">
       <span class="flex-grow">Products</span>
        <button class="btn btn-primary" *ngIf="canManageProducts" (click)="openTpoAddProductModal()">
            <span class="glyphicon glyphicon-plus small-icon"></span> Add Product
        </button>
    </h2>
    <div class="rows-bordered remote-resource" [class.resolved]="!resolving">
        <div class="row-fluid header">
            <div class="col-md-3">Company Name</div>
            <div class="col-md-2">Product</div>
            <div class="col-md-2">Plan</div>
            <div class="col-md-2">Payment Method</div>
            <div class="col-md-2">Status</div>
            <div class="col-md-1">Users</div>
        </div>
        <a class="row-fluid clickable"
           *ngFor="let product of products"
           uiSref=".:productId.settings"
           [uiParams]="{ productId: product.productId }"
        >
            <div class="col-md-3">
                {{ product?.companyName || '&ndash;' }}
            </div>
            <div class="col-md-2">
                {{ product?.productName || '&ndash;' }}
            </div>
            <div class="col-md-2">
                {{ product?.planName || '&ndash;' }}
            </div>
            <div class="col-md-2">
                {{ PaymentMethodTypes[product?.paymentMethodType]?.display || '&ndash;' }}
            </div>
            <div class="col-md-2">
                {{ PlanPaymentStatus[product?.planPaymentStatus] || '&ndash;' }}
            </div>
            <div class="col-md-1">
                {{ product?.userCount }}
            </div>
        </a>
        <div *ngIf="!resolving && !products?.length" class="row-fluid">
            <div class="col-md-a text-light text-bigger text-center">
                No Products Found
            </div>
        </div>
    </div>
</div>
