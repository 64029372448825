<a
    class="btn btn-tertiary btn-icon"
    *ngFor="let position of positions"
    [class.active]="currentPosition === position"
    (click)="setPosition(position)"
>
    <ng-container [ngSwitch]="position">
        <img *ngSwitchCase="'LEFT'" src="/static/images/solution-center/img-align-left.svg" class="icon-left" />
        <img *ngSwitchCase="'CENTER'" src="/static/images/solution-center/img-align-center.svg" class="icon-center" />
        <img *ngSwitchCase="'RIGHT'" src="/static/images/solution-center/img-align-right.svg" class="icon-right" />
    </ng-container>
</a>
