import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';
import { ProductPlan, TpoAccessibleProducts, TpoProduct, TpoProductDetails } from './tpo-product.interface';
import { TpoProductsService } from './tpo-products.service';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PaymentMethodTypes } from 'tpo/payment-methods/payment-methods.interface';
import { Validators } from '@angular/forms';


@Component({
    templateUrl: 'tpo-add-product.modal.component.html',
})
export class TpoAddProductModalComponent implements OnInit {
    tpoId: number;
    reloadProducts: () => void;

    tpoAccessibleProducts: TpoAccessibleProducts[];
    resolving: boolean;
    plans: ProductPlan[] = [];

    paymentMethods = [
        { label: PaymentMethodTypes.INVOICING.name, value: 'INVOICING' },
        { label: PaymentMethodTypes.NO_PAYMENT.name, value: 'NO_PAYMENT' },
    ];

    addProductForm = new RealmFormGroup (
        {
            product: new RealmFormControl(
                'product',
                {
                    label: 'Product',
                },
                Validators.required,
            ),
            planId: new RealmFormControl(
                'planId',
                {
                    label: 'Plan',
                    updateOn: 'change',
                },
                Validators.required,
            ),
            paymentMethodType: new RealmFormControl(
                'paymentMethodType',
                {
                    label: 'Payment Method',
                },
                Validators.required,
            ),
            poNumber: new RealmFormControl(
                'poNumber',
                {
                    label: 'PO Number',
                },
                Validators.maxLength(999),
            ),
            costCenter: new RealmFormControl(
                'costCenter',
                {
                    label: 'Cost Center',
                },
                Validators.maxLength(999),
            ),
        },
    );

    constructor(
        private tpoProductsService: TpoProductsService,
        private readonly globalNotificationsService: GlobalNotificationsService,
        protected readonly modalRef: BsModalRef,
    ) {
    }

    async ngOnInit(): Promise<void> {
        try {
            this.resolving = true;
            this.tpoAccessibleProducts = await firstValueFrom(this.tpoProductsService.getTpoAccessibleProductAndPlan(this.tpoId));
        } catch ({error: {message}, message: httpError}) {
            this.globalNotificationsService.add({
                type: GlobalNotificationType.ERROR,
                message,
            });
        } finally {
            this.resolving = false;
        }
    }

    close() {
        this.modalRef.hide();
    }

    onProductChange(changedProduct: TpoAccessibleProducts) {
        this.addProductForm.get('planId').reset();
        const selectedProduct = this.tpoAccessibleProducts.find(product => product.productId == changedProduct.productId);
        this.plans = selectedProduct ? selectedProduct.productPlanList : [];
    }

    async addProduct() {
        const { product, planId, paymentMethodType, poNumber, costCenter } = this.addProductForm.value;
        const newTpoProduct: TpoProductDetails = { planId: planId, paymentMethodType, poNumber, costCenter };
        try {
            this.resolving = true;
            const tpoProduct: TpoProduct = await firstValueFrom(this.tpoProductsService.addTPOProductAccess(this.tpoId, newTpoProduct));
            this.reloadProducts();
            this.close();
        } catch({error: {message}, message: httpError}) {
            this.addProductForm.setServerError({message: message});
        } finally {
            this.resolving = false;
        }
    }
}
