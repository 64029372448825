import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { UserService } from 'angularjs-providers/user.provider';
import { LenderUsersResourceService } from '../users-resource.service';

@Component({
	templateUrl: './my-settings.component.html',
})
export class LenderMySettingsComponent {
	user: any = {};
	userSettings: any = {};

	constructor(
		public User: UserService,
		public stateService: StateService,
		public LenderUsersService: LenderUsersResourceService,
	) {}

	ngOnInit() {
		this.user = this.LenderUsersService.get({
			userId: this.User.profile.id,
		});

		this.userSettings = this.LenderUsersService.getSettings({
			userId: this.User.profile.id,
		});
	}
}
