import { Component, forwardRef, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals, TransitionService } from '@uirouter/core';
import { HttpErrorResponse } from '@angular/common/http';
import { map, mapValues } from 'lodash';

import { NgResourceArray } from 'commons/interfaces';
import { ListComponent, PagedListComponent } from 'commons/components/list';
import {
    GlobalNotificationsService,
    GlobalNotificationType
} from 'global-elements/notication-center/notifications.service';

import { TpoDocument } from '../documents.interface';
import { TpoDocumentsService } from '../documents.service';

@Component({
	templateUrl: './list.component.html',
	viewProviders: [
		{ provide: ListComponent, useExisting: forwardRef(() => TpoDocumentListComponent) },
	],
})
export class TpoDocumentListComponent extends PagedListComponent implements OnInit {
	static listName = 'tpoDocumentList';
	list: NgResourceArray<TpoDocument> = [];
    private readonly notificationsService: GlobalNotificationsService;

	constructor(
		transitionService: TransitionService,
		stateService: StateService,
		private uiGlobals: UIRouterGlobals,
		private documentsService: TpoDocumentsService,
	) {
		super(transitionService, stateService);
	}

	ngOnInit() {
		this.configureList();

		super.ngOnInit();
	}

	private configureList(): void {
		const { lenderId } = this.uiGlobals.params[this.listName] || {};

		Object.assign(
			this.filters,
			{
				lenderId: this.documentsService.getPartners(),
				channelId: lenderId ? this.documentsService.getChannels({ lenderId }) : [],
				questionnaireId: lenderId ? this.documentsService.getQuestionnaires({ lenderId }) : [],
			},
		);

		Object.assign(
			this.defaultFilters,
			{
				size: 25,
				lenderId: null,
				channelId: [],
				questionnaireId: null,
			},
		);
	}

	setFilter(filters, resetPage: boolean = true): void {
		const pageParams = resetPage ? {page: 0} : {};

		mapValues(filters, (value, key: string) => {
			switch (key) {
				case 'lenderId':
					if (filters.lenderId) {
						filters.channelId = [];
						filters.questionnaireId = null;
						this.filters.channelId = this.documentsService.getChannels({ lenderId: value.id });
						this.filters.questionnaireId = this.documentsService.getQuestionnaires({ lenderId: value.id });
					}
					filters.lenderId = value?.id;
					break;
				case 'channelId':
					filters.channelId = map(value || [], 'id');
					break;
				case 'questionnaireId':
					filters.questionnaireId = value?.id;
					break;
			}
		});

		if (!this.params.lenderId) {
			filters.channelId = [];
			filters.questionnaireId = null;
		}

		this.params$.next({ ...this.params$.getValue(), ...pageParams, ...filters });
	}

	async loadList(queryParams) {
        try {
            this.list.$resolved = false;
            this.list = await this.documentsService.getDocuments(
                this.getClearParams(queryParams),
            ).$promise;
        } catch ({error, status}) {
            if (status === 403) {
                throw new HttpErrorResponse({ status: 403 });
            } else {
                this.notificationsService.add({
                    type: GlobalNotificationType.ERROR,
                    message: error.message,
                });
            }
        } finally {
            this.list.$resolved = true;
        }
	}

	async reloadList(): Promise<void> {
		await this.loadList(this.params);
	}
}
