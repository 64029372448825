import { Component, computed, inject, signal } from '@angular/core';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import { UserService } from 'angularjs-providers/user.provider';
import { LenderSolutionCenterV2Service, SCFullCompany } from 'tpo/solution-center-v2/solution-center.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import '@uirouter/rx/lib/core.augment';
import { SC_CARD_TAB } from 'shared/solution-center-v2/solution-center.common';

@Component({
    templateUrl: './company-card-container.component.html',
})
export class CompanyCardContainerComponent {
    private readonly stateService = inject(UIRouter).stateService;
    private readonly profile = inject(UserService).profile;
    private readonly service = inject(LenderSolutionCenterV2Service);
    private readonly params = toSignal(inject(UIRouterGlobals).params$);

    resolving = signal<boolean>(true);
    organizationId = computed(() => <number>(this.params() || {}).organizationId);
    currentTab = computed(() => (<string>(this.params() || {}).tab || '').toUpperCase() as SC_CARD_TAB);
    companyData = toSignal<SCFullCompany>(
        toObservable(this.organizationId).pipe(
            tap(() => this.resolving.set(true)),
            switchMap((organizationId) => organizationId ? this.service.getFullCustomer(organizationId) : of({})),
            tap(() => this.resolving.set(false)),
        )
    );
    visibleTabs = computed<Partial<Record<SC_CARD_TAB, boolean>>>(() => ({
        [SC_CARD_TAB.OVERVIEW]: true,
        ...((this.companyData()?.availableTabs || []).reduce((acc, tab) => ({ ...acc, [tab]: true }), {})),
    }));

    closeCard() {
        this.stateService.go('^');
    }
}
