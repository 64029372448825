import { ChangeDetectionStrategy, Component, ContentChild, contentChild, model, TemplateRef } from '@angular/core';
import { BoxShowAnimation } from 'commons/animation/simpleShowAnimation';
import { CardPersistentPartDirective } from 'commons/card/card.templates';

@Component({
    selector: 'card',
    templateUrl: './card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.expanded]': 'this.expanded()',
    },
    animations: [
        BoxShowAnimation,
    ],
})
export class CardComponent {
    expanded = model<boolean>(false);
    persistentTemplate = contentChild(CardPersistentPartDirective, { read: TemplateRef });

    toggle() {
        this.expanded.set(!this.expanded());
    }
}
