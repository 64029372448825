import { ChangeDetectionStrategy, Component, inject, Input, OnInit, signal } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AccountInformation } from 'shared/account/company-information/account-information.service';
import { ChannelsSummary } from 'lender/clients/channels/channels-summary.interface';
import { ChannelCardService } from 'lender/clients/channels/channel-card.service';
import { UserService } from 'angularjs-providers/user.provider';

@Component({
    selector: 'new-channels-list',
    templateUrl: './channels-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewChannelsListComponent implements OnInit {
    list = signal<ChannelsSummary[]>([]);
    resolved = signal<boolean>(false);
    @Input() lenderInfo: AccountInformation;

    private channelCardService = inject(ChannelCardService);
    private User = inject(UserService).profile;
    private investorId = this.User.organization.id;

    ngOnInit() {
        this.loadChannels();
    }

    async loadChannels() {
        try {
            this.resolved.set(false);
            this.list.set(await firstValueFrom(this.channelCardService.getChannels(this.lenderInfo, this.investorId)));
        } finally {
            this.resolved.set(true);
        }
    }
}
