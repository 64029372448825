<div class="gray-head">
    <div class="flex-row mb20">
        <h2 class="flex-grow">{{companyData()?.companyName || '&nbsp;'}}</h2>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeCard.emit()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>

    <div class="flex-row">
        <ul class="tab-menu flex-grow">
            <ng-container *ngFor="let tab of tabList">
                <li *ngIf="visibleTabs()[tab.code]" [class.active]="currentTab() === tab.code">
                    <a (click)="selectTab.emit(tab.code)"><b>{{tab.title}}</b></a>
                </li>
            </ng-container>
        </ul>
        <div *ngIf="companyData() as companyData" class="flex-row gap align-top">
            <a  *ngIf="companyData.companyEmail as link" [href]="link | hrefReady" target="_blank"
                class="btn btn-tertiary btn-icon sm-link-mini"><span class="glyphicon glyphicon-envelope"></span></a>
            <a  *ngIf="companyData.companyWebsite as link" [href]="link | hrefReady" target="_blank"
                class="btn btn-tertiary btn-icon sm-link-mini"><span class="glyphicon glyphicon-globe"></span></a>
            <a  *ngIf="companyData.medialinks?.FB as link" [href]="link | hrefReady" target="_blank"
                class="btn btn-tertiary btn-icon sm-link-mini"><img src="/static/images/solution-center/fb.svg"></a>
            <a  *ngIf="companyData.medialinks?.IG as link" [href]="link | hrefReady" target="_blank"
                class="btn btn-tertiary btn-icon sm-link-mini"><img src="/static/images/solution-center/ig.svg"></a>
            <a  *ngIf="companyData.medialinks?.LI as link" [href]="link | hrefReady" target="_blank"
                class="btn btn-tertiary btn-icon sm-link-mini"><img src="/static/images/solution-center/li.svg"></a>
            <a  *ngIf="companyData.medialinks?.X as link" [href]="link | hrefReady" target="_blank"
                class="btn btn-tertiary btn-icon sm-link-mini"><img src="/static/images/solution-center/x.svg"></a>
            <a  *ngIf="companyData.medialinks?.YT as link" [href]="link | hrefReady" target="_blank"
                class="btn btn-tertiary btn-icon sm-link-mini"><img src="/static/images/solution-center/yt.svg"></a>
        </div>
    </div>
</div>
<div class="tab-inner" [ngSwitch]="currentTab()">
    <sc-cfc-v2-overview [companyData]="companyData()" *ngSwitchCase="SC_CARD_TAB.OVERVIEW">OVERVIEW</sc-cfc-v2-overview>
</div>
