import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { SCCustomerCard } from 'shared/solution-center-v2/solution-center.common';

@Component({
    selector: 'sc-company-card-v2',
    templateUrl: './company-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScCompanyCardV2Component {
    companyData = input.required<SCCustomerCard>();
    isPreview = input<boolean>(false);
    @HostBinding('class.preview-mode') get preview() {
        return this.isPreview();
    }
}
