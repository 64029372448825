import { Injectable } from "@angular/core";
import { UserProfile, UserService } from "angularjs-providers/user.provider";
import { RealmHttpClient } from "commons/services/http";
import { Observable } from "rxjs";
import {
    EntityListItem,
    LenderUserPanelProduct,
    LenderUserPanelProductLicenseStatus
} from "./lender-user-panel-products.interface";
import {UIRouterGlobals} from "@uirouter/core";
import { TpoOrganizationUser, TpoProductEntityGroup } from 'shared/tpo-products/tpo-product.interface';

declare let apiPath: string;

@Injectable()
export class LenderUserPanelProductsService {
    protected user: UserProfile;
    protected tpoId: number;

    constructor(
        protected http: RealmHttpClient,
        { profile }: UserService,
    ) {
        this.user = profile;
        if (this.user.isTpo) {
            this.tpoId = this.user.organization.id;
        }
    }

    getLenderUserProductsList = (contactId: number): Observable<LenderUserPanelProduct[]> =>
        this.http.request<LenderUserPanelProduct[]>(
            'GET',
            `${apiPath}/tpos/${this.tpoId}/contacts/${contactId}/products-list`,
        );

    getEntityListForProduct = (contactId: number, productId: number): Observable<EntityListItem[]> =>
        this.http.request<EntityListItem[]>(
            'GET',
            `${apiPath}/tpos/${this.tpoId}/contacts/${contactId}/product/${productId}/entities-and-access`,
        );

    getEntitiesAssignedToContact = (contactId: number, productId: number): Observable<number[]> =>
        this.http.request<number[]>(
            'GET',
            `${apiPath}/tpos/${this.tpoId}/contacts/${contactId}/product/${productId}/access`,
        );

    updateLicenseStatusAndAssignedEntities = (
        contactId: number,
        productId: number,
        licenseStatus: LenderUserPanelProductLicenseStatus,
        entityIds: number[]
    ): Observable<number[]> => {
        const licenseStatusParam = LenderUserPanelProductLicenseStatus[licenseStatus].toUpperCase();
        return this.http.request<number[]>(
            'PUT',
            `${apiPath}/tpos/${this.tpoId}/contacts/${contactId}/product/${productId}/access?access=${licenseStatusParam}`,
            null,
            entityIds
        );
    }

    getEntitiesGroupedByType(productId: number): Observable<TpoProductEntityGroup[]> {
        return this.http.request<TpoProductEntityGroup[]>(
            'GET',
            `${apiPath}/tpos/${this.tpoId}/products/${productId}/entities`,
        );
    }

    getInactiveLicenseUsers(productId: number): Observable<TpoOrganizationUser[]> {
        return this.http.request<TpoOrganizationUser[]>(
            'GET',
            `${apiPath}/tpos/${this.tpoId}/products/${productId}/user-inactive-product-license`,
        );
    }
}
