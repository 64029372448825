/* eslint-disable comma-dangle */
import { Type } from '@angular/core';
import SharedRoutes from './sharedRoutes';

import { EmptyContainerComponent } from 'commons/components';

import { SharedQuestionnairesListComponent } from 'shared/questionnaires';

import { HomeComponent } from 'tpo/home/home.component';

import { RecurlyResolver } from 'commons/recurly/recurly-resolver';

// Social Compliance
import {
	GetStartedComponent as SocialComplianceGetStartedComponent,
} from 'tpo/social-compliance';

// Publisher
import {
	PostEditPageComponent,
	PostViewPageComponent,
	PostTypeComponent,
	TemplatesListComponent,
	FolderTemplatesListComponent,
	GroupsListComponent,
	GroupComponent,
	GroupContactsComponent,
	GroupContactsManageComponent,
	GroupSharingCampaignsComponent,
	GroupSharingTemplatesComponentComponent,
	GroupSharingComponent,
	ContentListComponent,
	PublishedListComponent,
	LibraryContentManageFeedComponent,
	PostsComponent,
} from 'tpo/social-compliance/publisher';
import {
	ReviewsComponent,
	ReviewsListComponent,
	ReviewViewComponent,
	ReviewsSettingsImagesComponent,
    ReviewSettingsAccountsComponent,
	WidgetsListComponent,
	WidgetDetailsComponent,
	EditWidgetComponent,
} from 'tpo/social-compliance/reviews';
import { TpoNewOrderComponent, TpoPaymentOrderComponent } from 'tpo/social-compliance/orders/new';
import { TpoComplianceScheduleComponent } from 'tpo/social-compliance/schedule/schedule.component';
import { ScSettingsComponent } from 'tpo/social-compliance/settings/sc-settings.component';
import {
	ChecklistSettingsComponent,
	ChecklistSettingsRegulationsComponent,
	ChecklistSettingsStateChecksComponent,
} from 'tpo/social-compliance/settings/checklist';
import { ExportListComponent } from 'tpo/export';
import { AccountInformationComponent } from 'shared/account/company-information/details/account-information.component';
import { ArchiveComponent, TpoArchiveListComponent } from 'tpo/social-compliance/archive';
import { FeedComponent } from 'shared/social-compliance/archive/feed';
import { TpoMonitoringBoardListComponent } from 'tpo/social-compliance/findings/list';
import { TaskListComponent } from 'tpo/social-compliance/tasks';

import {
	AppPaymentComponent,
} from 'tpo/application';
import {
	TpoDocumentsComponent,
	TpoDocumentListComponent,
	TpoDocumentComponent,
	TpoDocumentHistoryComponent,
	TpoLibraryDocumentListComponent,
	TpoLibraryDocumentComponent,
	TpoLibraryDocumentHistoryComponent,
} from 'tpo/documents';
import { TpoRolePermissionsComponent } from 'tpo/role-permissions';
import { PublisherComponent } from 'tpo/social-compliance/publisher/publisher.component';
import {
    TpoPeopleComponent,
    TpoContactsListComponent,
} from 'tpo/people';
import { TpoContactDetailsComponent } from 'tpo/people/contacts/details/tpo-contact-details.component';
import {
    TpoContactGeneralInformationComponent,
} from 'tpo/people/contacts/details/general-information/general-information.component';
import {
    NewContactAuditLogComponent,
} from 'shared/new-contacts/audit-log';
import { NewContactRemovedSocialAccountsComponent } from 'shared/new-contacts/social-accounts';
import { NewContactSocialAccountsComponent } from 'shared/new-contacts/social-accounts/social-accounts.component';
import { NewContactWebPresenceComponent } from 'shared/new-contacts/web-presence/web-presence.component';
import { IndividualPublishingPermissionsComponent } from 'tpo/social-compliance/publishing-permissions';
import { SharedContactSettingsComponent } from 'shared/new-contacts/settings/contact-settings.component';
import { NewPublisherRequestsComponent } from 'shared/new-contacts/publisher-requests/new-publisher-requests.component';
import { NewContactsAuditsListComponent } from 'shared/new-contacts/compliance/audits/new-contacts-audits-list.component';
import { NewContactFindingsListComponent } from 'shared/new-contacts/compliance/findings/new-findings-list.component';
import { PeopleOwnershipListComponent } from 'tpo/people/ownership-list/people-ownership-list.component';
import { PeopleCompanyDetailsComponent } from 'tpo/people/contacts/company-details/people-company-details.component';
import { NewContactFindingComponent } from 'shared/findings';
import { MarketingComponent } from 'tpo/social-compliance/marketing/marketing.component';
import { ReputationComponent } from 'tpo/social-compliance/reputation/reputation.component';
import { LibraryMenuComponent } from 'tpo/social-compliance/publisher/library/library-menu.component';
import { SubscriptionComponent } from 'tpo/social-media/subscription/subscription.component';
import { SubscriptionPaymentComponent } from 'tpo/social-media/subscription/payment/payment.component';
import { TpoFinancialsComponent } from 'shared/account/financials/tpo-financials.component';
import { CommonProductionComponent } from 'shared/production/production.component';
import {
	FinancialDocumentHistoryComponent,
} from 'shared/account/financials/tpo-financial-document-list/tpo-financial-document-history';
import {
    AccountInformationService,
    accountIdentifierSelf$,
    lenderInfoSelf$
} from 'shared/account/company-information/account-information.service';
import {
	FinancialDocumentRequestsHistoryComponent,
} from 'shared/account/financials/tpo-financial-document-list/tpo-financial-requests-history';
import { UserService } from 'angularjs-providers/user.provider';
import { DbaListComponent } from 'shared/account/dba/dba-list.component';
import { FederalRegistrationsListComponent } from 'shared/account/federal-registrations/federal-registrations-list.component';
import { AgencyComponent } from 'tpo/account/agency/agency.component';
import { LocationsListComponent } from 'shared/account/locations/locations-list.component';
import { InsuranceBondsComponent } from 'shared/account/information/insurance-bonds/insurance-bonds.component';
import { InvestorsComponent } from 'tpo/account/investors/investors.component';
import { SharedLicenseListComponent } from 'shared/account/licenses';
import { TpoQuestionnaireComponent } from 'tpo/questionnaire/questionnaire.component';
import { AffiliatesAddEditViewComponent } from 'tpo/account/affiliates/affiliates-add-edit-view.component';
import { NewWarehousesComponent } from 'shared/account/information/new-warehouse/warehouses.component';
import { AffiliatesListComponent } from 'tpo/account/affiliates/affiliates-list.component';
import { UnderwritingComponent } from 'tpo/account/underwriting/underwriting.component';
import { IndividualNmlsComponent } from 'shared/nmls/individual/individual-nmls.component';
import { TpoAccountComponent } from 'tpo/account/tpo-account.component';
import { ApplicationCommonComponent } from 'tpo/application/common/application-common.component';
import { AppSummaryComponent } from 'tpo/application/summary/app-summary.component';
import { SharedTechOpsComponent } from 'shared/account/information/tech-ops/tech-ops.component';
import { TpoPaymentsComponent } from 'tpo/payments/payments.component';
import { TpoPaymentMethodsComponent } from 'tpo/payment-methods/payment-methods.component';
import { ContactsImportUploadComponent } from 'tpo/people/contacts/import/upload.component';
import { ContactImportContainerComponent } from 'tpo/people/contacts/import/import.container';
import { ContactsImportMappingComponent } from 'tpo/people/contacts/import/mapping.component';
import { ContactImportsListComponent } from 'tpo/people/contacts/import/list/imports-list.component';
import { LenderSolutionCenterComponent } from 'tpo/solution-center/solution-center.component';
import { LenderSolutionCenterListComponent } from 'tpo/solution-center/search/solution-center-list.component';
import commonRoutes from 'routes/commonRoutes';
import { ProductSubscriptionComponent } from 'tpo/solution-center/tabs/products/products-subscription/product-subscription.component';
import { LenderUserPanelProductsListComponent } from 'tpo/people/products/lender-user-panel-products-list.component';
import { LenderSolutionCenterListV2Component } from 'tpo/solution-center-v2/list/solution-center-list.component';
import { TpoProductsComponent } from 'shared/tpo-products/tpo-products.component';
import { TpoProductDetailsComponent } from 'shared/tpo-products/product-details/tpo-product-details.component';
import {
    TpoProductSettingsComponent
} from 'shared/tpo-products/product-details/product-settings/tpo-product-settings.component';
import {
    TpoProductAddOnsComponent
} from 'shared/tpo-products/product-details/product-add-ons/tpo-product-add-ons.component';
import { TpoProductUserLicensesComponent } from 'shared/tpo-products/product-details/product-user-licenses/tpo-product-user-licenses.component';
import { CompanyCardContainerComponent } from 'tpo/solution-center-v2/single/company-card-container.component';

export default [
	{
		name: 'home',
		allowAccess: [
			'ownershipVerificationRequired'
		],
		component: HomeComponent,
	},
    ...commonRoutes,
	{
		name: `export?${ExportListComponent.listName}`,
		component: ExportListComponent,
		params: {
			[ExportListComponent.listName]: {
				type: 'jsonWithDate'
			}
		}
	},
	{
		name: 'nmls',
		abstract: true,
		component: EmptyContainerComponent,
	},
	{
		name: 'nmls.individual',
		abstract: true,
		component: EmptyContainerComponent,
	},
	{
		name: 'nmls.individual.:nmlsId',
		component: IndividualNmlsComponent,
	},
	{
		name: 'documents',
		component: TpoDocumentsComponent,
		redirectTo: 'documents.list',
		children: [
			{
				name: `.list?${TpoDocumentListComponent.listName}`,
				params: {
					[TpoDocumentListComponent.listName]: { type: 'json' },
				},
				reloadOnSearch: false,
				component: TpoDocumentListComponent,
				children: [
					{
						name: '.:documentId',
						abstract: true,
						views: {
							'@': {
								component: TpoDocumentComponent,
							},
						},
						children: [
							{
								name: `.history?${TpoDocumentHistoryComponent.listName}`,
								params: {
									[TpoDocumentHistoryComponent.listName]: { type: 'json' },
								},
								reloadOnSearch: false,
								component: TpoDocumentHistoryComponent,
							},
						],
					},
					{
						name: '.library',
						abstract: true,
						component: EmptyContainerComponent,
						children: [
							{
								name: '.:libraryId',
								abstract: true,
								views: {
									'@': {
										component: TpoDocumentComponent,
									},
								},
								children: [
									{
										name: `.history?${TpoDocumentHistoryComponent.listName}`,
										params: {
											[TpoDocumentHistoryComponent.listName]: { type: 'json' },
										},
										reloadOnSearch: false,
										component: TpoDocumentHistoryComponent,
									},
								],
							},
						],
					},
				],
			},
			{
				name: `.library?${TpoLibraryDocumentListComponent.listName}`,
				params: {
					[TpoLibraryDocumentListComponent.listName]: { type: 'json' },
				},
				reloadOnSearch: false,
				component: TpoLibraryDocumentListComponent,
				children: [
					{
						name: '.:libraryId',
						abstract: true,
						views: {
							'@': {
								component: TpoLibraryDocumentComponent,
							},
						},
						children: [
							{
								name: `.history?${TpoLibraryDocumentHistoryComponent.listName}`,
								params: {
									[TpoLibraryDocumentHistoryComponent.listName]: { type: 'json' },
								},
								reloadOnSearch: false,
								component: TpoLibraryDocumentHistoryComponent,
							},
						],
					},
				],
			},
		],
	},
    {
        name: 'account?channelId',
        resolve: [
            { token: 'lenderInfo', deps: [UserService, AccountInformationService], resolveFn: lenderInfoSelf$ },
        ],
		component: TpoAccountComponent,
		abstract: true,
		params: {
			channelId: {
				type: 'int',
				value: null,
				squash: true,
				dynamic: true,
			},
		},
    },
	{
		name: `account.questionnaires?${SharedQuestionnairesListComponent.listName}`,
		reloadOnSearch: false,
		component: SharedQuestionnairesListComponent,
		params: {
			[SharedQuestionnairesListComponent.listName]: {
				type: 'json'
			}
		}
	},
	{
		name: 'account.questionnaires.:questionnaireId',
        views: {
            '@account': {
                component: TpoQuestionnaireComponent,
            }
        }
	},
	{
		name: 'account.information',
		component: AccountInformationComponent,
	},
	{
        name: `account.dba?{${DbaListComponent.listName}:json}`,
        reloadOnSearch: false,
        component: DbaListComponent,
	},
	{
        name: `account.federal-registration?{${FederalRegistrationsListComponent.listName}:json}`,
        reloadOnSearch: false,
        component: FederalRegistrationsListComponent,
	},
	{
		name: `account.financials`,
		reloadOnSearch: false,
		component: TpoFinancialsComponent,
		children: [
			{
				name: '.:documentType',
				abstract: true,
				component: EmptyContainerComponent,
				children: [
					{
						name: `.history?${FinancialDocumentHistoryComponent.listName}`,
						params: {
							documentType: {
								type: 'string',
								value: null,
							},
							[FinancialDocumentHistoryComponent.listName]: { type: 'json' },
						},
						reloadOnSearch: false,
						views: {
							'@account': {
								component: FinancialDocumentHistoryComponent,
							},
						},
					},
					{
						name: `.requests?${FinancialDocumentRequestsHistoryComponent.listName}`,
						params: {
							documentType: {
								type: 'string',
								value: null,
							},
							[FinancialDocumentRequestsHistoryComponent.listName]: { type: 'json' },
						},
						reloadOnSearch: false,
						views: {
							'@account': {
								component: FinancialDocumentRequestsHistoryComponent,
							},
						},
					},
				]
			}

		]
	},
	{
		name: `account.production`,
		component: CommonProductionComponent,
		resolve: [
			{ token: 'lenderInfo', deps: [UserService, AccountInformationService], resolveFn: lenderInfoSelf$ },
		],
	},
	{
		name: `account.licenses?${SharedLicenseListComponent.listName}`,
		params: {
			[SharedLicenseListComponent.listName]: {
				type: 'json',
			},
		},
		reloadOnSearch: false,
		component: SharedLicenseListComponent,
	},
	{
		name: 'account.licenses.nmls',
        abstract: true,
        component: EmptyContainerComponent,
	},
	{
		name: 'account.licenses.nmls.:nmlsId',
		views: {
			'@account': {
				component: IndividualNmlsComponent,
			}
		}
	},
	{
		name: `account.locations?{${LocationsListComponent.listName}:json}`,
        reloadOnSearch: false,
        component: LocationsListComponent,
        children: SharedRoutes.locations
	},
	{
		name: 'account.relationships',
        abstract: true,
        component: EmptyContainerComponent,
	},
    {
        name: `account.relationships.affiliates?{${AffiliatesListComponent.listName}:json}`,
        component: AffiliatesListComponent,
        children: [
            {
                name: '.new',
                views: {
                    '^':{
                        component: AffiliatesAddEditViewComponent,
                    }
                }
            },
            {
                name: '.:affiliateId',
                views:{
                    '^':{
                        component: AffiliatesAddEditViewComponent,
                    }
                }
            }
        ]
    },
    {
        name: 'account.relationships.investors',
        component: InvestorsComponent,
    },
    {
        name: 'account.relationships.agency',
        component: AgencyComponent
    },
	{
		name: 'account.relationships.warehouse',
		component: NewWarehousesComponent,
	},
    {
        name: 'account.relationships.insuranceBonds',
        component: InsuranceBondsComponent,
    },
    {
        name: 'account.relationships.underwriting',
        component: UnderwritingComponent,
    },
    {
        name: 'account.relationships.tech-ops',
        component: SharedTechOpsComponent,
    },
    {
        name: 'account.products',
        component: TpoProductsComponent,
        resolve: [
            { token: 'accountIdentifier', deps: [ UserService ], resolveFn: accountIdentifierSelf$ },
        ],
    },
    {
        name: 'account.products.:productId',
        redirectTo: 'account.products.:productId.settings',
        views: {
            '@account': { component: TpoProductDetailsComponent, }
        },
        children: [
            {
                name: '.settings',
                component: TpoProductSettingsComponent,
            },
            {
                name: '.add-ons',
                component: TpoProductAddOnsComponent,
            },
            {
                name: `.user-licenses?${TpoProductUserLicensesComponent.listName}`,
                component: TpoProductUserLicensesComponent,
                reloadOnSearch: false,
                params: {
                    [TpoProductUserLicensesComponent.listName]: {
                        type: 'json'
                    },
                }
            },
        ],
    },
    {
        name: 'new-application',
        url: '/app/{channelId:int}',
        component: ApplicationCommonComponent,
        children: [
            {
                name: '.payment',
                component: AppPaymentComponent,
                resolve: {
                    recurlyConfig: RecurlyResolver
                }
            },
            {
                name: '.summary',
                component: AppSummaryComponent,
            },
        ],
    },
	{
		name: 'payments',
		component: TpoPaymentsComponent,
	},
    {
        name: 'payment-methods',
        component: TpoPaymentMethodsComponent,
    },
	{
		name: 'users-management',
		component: TpoRolePermissionsComponent,
	},
	...SharedRoutes.roles,
	SharedRoutes.SocialCompliance,
	{
		name: `social-compliance.orders.new?${TpoNewOrderComponent.listName}`,
		reloadOnSearch: false,
		views: {
			'@social-compliance': {
				component: TpoNewOrderComponent
			}
		},
		params: {
			[TpoNewOrderComponent.listName]: {
				type: 'json'
			}
		}
	},
	{
		name: 'social-compliance.orders.new.payment',
		component: TpoPaymentOrderComponent,
		resolve: {
			recurlyConfig: RecurlyResolver
		}
	},
	{
		name: `social-compliance.tasks?${TaskListComponent.listName}`,
		params: {
			[TaskListComponent.listName]: {
				type: 'jsonWithDate',
			}
		},
		reloadOnSearch: false,
		component: TaskListComponent,
	},
	{
		name: 'social-compliance.settings',
		component: ScSettingsComponent,
	},
	{
		name: 'social-compliance.subscription',
		component: SubscriptionComponent,
	},
	{
		name: 'social-compliance.subscription.payment',
        resolve: {
            recurlyConfig: RecurlyResolver
        },
        views: {
            '@social-compliance': {
                component: SubscriptionPaymentComponent,
            }
        },
	},
	{
		name: 'social-compliance.compliance-schedule',
		component: TpoComplianceScheduleComponent,
	},
    {
		name: `social-compliance.archive`,
		component: ArchiveComponent,
		redirectTo: 'social-compliance.archive.connected',
		children: [
			{
				name: `.connected?${TpoArchiveListComponent.listName}`,
				component: TpoArchiveListComponent,
				params: {
					[TpoArchiveListComponent.listName]: {
						type: 'jsonWithDate'
					}
				},
				reloadOnSearch: false,
			},
			{
				name: `.feed?${FeedComponent.listName}`,
				component: FeedComponent,
				params: {
					[FeedComponent.listName]: {
						type: 'jsonWithDate'
					}
				},
				reloadOnSearch: false,
			},
		]
	},
	{
		name: `social-compliance.monitoring-board?${TpoMonitoringBoardListComponent.listName}`,
		reloadOnSearch: false,
		component: TpoMonitoringBoardListComponent,
		params: {
			[TpoMonitoringBoardListComponent.listName]: {
				type: 'jsonWithDate'
			}
		}
	},
	// {
	// 	name: 'social-compliance.monitoring-settings',
	// 	component: MonitoringSettingsComponent,
	// 	redirectTo: 'social-compliance.monitoring-settings.company',
	// },
	// {
	// 	name: `social-compliance.monitoring-settings.company?${CompanyListComponent.listName}`,
	// 	component: CompanyListComponent,
	// 	params: {
	// 		[CompanyListComponent.listName]: {
	// 			type: 'json'
	// 		}
	// 	}
	// },
	// {
	// 	name: `social-compliance.monitoring-settings.individuals?${IndividualsListComponent.listName}`,
	// 	component: IndividualsListComponent,
	// 	params: {
	// 		[IndividualsListComponent.listName]: {
	// 			type: 'json'
	// 		}
	// 	}
	// },
	// {
	// 	name: 'social-compliance.monitoring-settings.settings',
	// 	component: SettingsComponent,
	// 	resolve: {
	// 		recurlyConfig: RecurlyResolver
	// 	}
	// },
	{
		name: 'social-compliance.audit-checklist',
		component: ChecklistSettingsComponent,
		redirectTo: 'social-compliance.audit-checklist.regulations',
		children: [
			{
				name: '.regulations',
				component: ChecklistSettingsRegulationsComponent
			},
			{
				name: '.state-checks',
				component: ChecklistSettingsStateChecksComponent
			}
		]
	},
	{
		name: 'social-compliance.get-started',
		component: SocialComplianceGetStartedComponent,
	},
    {
        name: 'social-compliance.marketing',
        component: MarketingComponent,
    },
	{
        name: 'social-compliance.reputation',
        component: ReputationComponent,
    },
	{
		name: 'social-compliance.publisher',
		views: {
			'@': {
				component: PublisherComponent,
			}
		},
		// abstract: true,
		redirectTo: 'social-compliance.publisher.posts',
		children: [
			{
				name: '.posts',
				component: PostsComponent,
				children: [
					{
						name: '.new?templateId&campaignId&rssId&review',
						params: {
							review: {
								value: null,
								type: 'json',
								squash: true,
							}
						},
						isFullPageView: true,
						views: {
							'@': {
								component: PostEditPageComponent
							}
						}
					},
					{
						name: `.:postType?${PostTypeComponent.listName}`,
						views: {
							'@social-compliance.publisher': {
								component: PostTypeComponent
							}
						},
						params: {
							[PostTypeComponent.listName]: {
								type: 'json'
							}
						},
						children: [
							{
								name: '.:postId',
								isFullPageView: true,
								views: {
									'@': {
										component: PostViewPageComponent
									}
								},
								children: [
									{
										name: '.edit?templateId',
										isFullPageView: true,
										views: {
											'@': {
												component: PostEditPageComponent
											}
										}
									}
								]
							}
						]
					}
				]
			},
			{
				name: 'social-compliance.publisher.library',
                component: LibraryMenuComponent,
				children: [
					{
						name: `.templates?${TemplatesListComponent.listName}`,
						reloadOnSearch: false,
						params: {
							[TemplatesListComponent.listName]: {
								type: 'json'
							}
						},
						views: {
							'@social-compliance.publisher': {
								component: TemplatesListComponent
							}
						},
						children: [
							...SharedRoutes.templates,
							{
								name: '.folder',
                                component: EmptyContainerComponent,
								redirectTo: 'social-compliance.publisher.library.templates',
							},
							{
								name: `.folder.:folderId?${FolderTemplatesListComponent.listName}`,
								reloadOnSearch: false,
								params: {
									[FolderTemplatesListComponent.listName]: {
										type: 'json'
									}
								},
								views: {
									'@social-compliance.publisher': {
										component: FolderTemplatesListComponent
									}
								},
								children: SharedRoutes.templates,
							},
						]
					},
					{
						// NOTICE: for future use, right now there is no filters on InfiniteScroll ContentListComponent
						name: `.content?${ContentListComponent.listName}`,
						views: {
							'@social-compliance.publisher': {
								component: ContentListComponent
							}
						},
						params: {
							[ContentListComponent.listName]: {
								type: 'json'
							}
						},
						children: [
							{
								name: `.manage-feed?${LibraryContentManageFeedComponent.listName}`,
								views: {
									'@social-compliance.publisher': {
										component: LibraryContentManageFeedComponent
									}
								},
								params: {
									[LibraryContentManageFeedComponent.listName]: {
										type: 'json'
									}
								}
							},
							{
								name: `.:contentId?${PublishedListComponent.listName}`,
								views: {
									'@social-compliance.publisher': {
										component: PublishedListComponent
									}
								},
								params: {
									[PublishedListComponent.listName]: {
										type: 'json'
									}
								}
							}
						]
					}
				]
			},
			// {
			// 	name: `social-compliance.publisher.campaigns?${CampaignsListComponent.listName}`,
			// 	params: {
			// 		[CampaignsListComponent.listName]: {
			// 			type: 'json'
			// 		}
			// 	},
			// 	component: CampaignsListComponent,
			// 	reloadOnSearch: false,
			// 	children: [
			// 		{
			// 			name: '.new',
			// 			views: {
			// 				'@social-compliance.publisher': {
			// 					component: CampaignEditComponent
			// 				}
			// 			}
			// 		},
			// 		{
			// 			name: '.:campaignId',
			// 			views: {
			// 				'@social-compliance.publisher': {
			// 					component: CampaignComponent
			// 				}
			// 			},
			// 			redirectTo: 'social-compliance.publisher.campaigns.:campaignId.details',
			// 			children: [
			// 				{
			// 					name: '.edit',
			// 					views: {
			// 						'@social-compliance.publisher': {
			// 							component: CampaignEditComponent
			// 						}
			// 					}
			// 				},
			// 				{
			// 					name: '.details',
			// 					views: {
			// 						'@social-compliance.publisher.campaigns.:campaignId': {
			// 							component: CampaignDetailsComponent
			// 						}
			// 					},
			// 					children: [
			// 						{
			// 							name: `.:folder?${CampaignFolderComponent.listName}`,
			// 							reloadOnSearch: false,
			// 							views: {
			// 								'@social-compliance.publisher': {
			// 									component: CampaignFolderComponent
			// 								}
			// 							},
			// 							params: {
			// 								[CampaignFolderComponent.listName]: {
			// 									type: 'json'
			// 								}
			// 							}
			// 						}
			// 					]
			// 				},
			// 				{
			// 					name: `.templates?${CampaignTemplatesComponent.listName}`,
			// 					reloadOnSearch: false,
			// 					views: {
			// 						'@social-compliance.publisher.campaigns.:campaignId': {
			// 							component: CampaignTemplatesComponent
			// 						}
			// 					},
			// 					params: {
			// 						[CampaignTemplatesComponent.listName]: {
			// 							type: 'json'
			// 						}
			// 					}
			// 				},
			// 				{
			// 					name: `.participants?${CampaignParticipantsComponent.listName}`,
			// 					reloadOnSearch: false,
			// 					views: {
			// 						'@social-compliance.publisher.campaigns.:campaignId': {
			// 							component: CampaignParticipantsComponent
			// 						}
			// 					},
			// 					params: {
			// 						[CampaignParticipantsComponent.listName]: {
			// 							type: 'json'
			// 						}
			// 					}
			// 				}
			// 			]
			// 		}
			// 	]
			// },
			// {
			// 	name: 'social-compliance.publisher.calendar',
            //     component: EmptyContainerComponent,
			// 	redirectTo: 'social-compliance.publisher.calendar.content',
			// 	class: 'calendar',
			// 	isFullPageView: true,
			// 	children: [
			// 		{
			// 			name: '.content?selectedMonth&selectedDate&postId',
			// 			isFullPageView: true,
			// 			reloadOnSearch: false,
			// 			class: 'calendar',
			// 			params: {
			// 				selectedMonth: null,
			// 				selectedDate: null,
			// 				postId: null
			// 			},
			// 			views: {
			// 				'@': {
			// 					component: CalendarMonthComponent
			// 				}
			// 			}
			// 		},
			// 		{
			// 			name: '.campaigns?selectedYear&campaignId',
			// 			isFullPageView: true,
			// 			reloadOnSearch: false,
			// 			class: 'calendar calendar-campaigns',
			// 			params: {
			// 				selectedYear: null,
			// 				campaignId: null
			// 			},
			// 			views: {
			// 				'@': {
			// 					component: CalendarYearComponent
			// 				}
			// 			}
			// 		}
			// 	]
			// },

			{
				name: `social-compliance.publisher.groups?${GroupsListComponent.listName}`,
				reloadOnSearch: false,
				params: {
					[GroupsListComponent.listName]: {
						type: 'json'
					}
				},
				component: GroupsListComponent,
				children: [
					{
						name: '.:groupId',
						views: {
							'@social-compliance.publisher': {
								component: GroupComponent
							}
						},
						redirectTo: 'social-compliance.publisher.groups.:groupId.contacts',
						children: [
							{
								name: `.contacts?${GroupContactsComponent.listName}`,
								reloadOnSearch: false,
								params: {
									[GroupContactsComponent.listName]: {
										type: 'json'
									}
								},
								component: GroupContactsComponent,
								children: [
									{
										name: `.manage?${GroupContactsManageComponent.listName}`,
										reloadOnSearch: false,
										params: {
											[GroupContactsManageComponent.listName]: {
												type: 'json'
											}
										},
										views: {
											'^': {
												component: GroupContactsManageComponent
											}
										}
									}
								]
							},
							{
								name: `.sharing?${GroupSharingCampaignsComponent.listName}&${GroupSharingTemplatesComponentComponent.listName}`,
								reloadOnSearch: false,
								params: {
									[GroupSharingCampaignsComponent.listName]: {
										type: 'json'
									},
									[GroupSharingTemplatesComponentComponent.listName]: {
										type: 'json'
									}
								},
								component: GroupSharingComponent
							}
						]
					}
				]
			}
		]
	},
	{
		name: 'reviews',
		component: ReviewsComponent,
		redirectTo: 'reviews.list',
		children: [
			{
				name: `reviews.list?${ReviewsListComponent.listName}`,
				reloadOnSearch: false,
				params: {
					[ReviewsListComponent.listName]: {
						type: 'json'
					}
				},
				views: {
					'@reviews': {
						component: ReviewsListComponent
					}
				},
				children: [
					{
						name: '.:reviewId',
						views: {
							'@': {
								component: ReviewViewComponent
							}
						}
					}
				]
			},
			{
				name: '.settings',
				component: EmptyContainerComponent,
				redirectTo: 'reviews.settings.accounts',
				children: [
					{
						name: '.accounts',
						views: {
							'@reviews': {
								component: ReviewSettingsAccountsComponent
							}
						}
					},
					{
						name: '.images',
						views: {
							'@reviews': {
								component: ReviewsSettingsImagesComponent
							}
						}
					},
				],

			},
			{
				name: `.widgets?${WidgetsListComponent.listName}`,
				reloadOnSearch: false,
				params: {
					[WidgetsListComponent.listName]: {
						type: 'json'
					}
				},
				views: {
					'@reviews': {
						component: WidgetsListComponent
					}
				},
				children: [
					{
						name: `.new?${EditWidgetComponent.listName}`,
						reloadOnSearch: false,
						views: {
							'@reviews': {
								component: EditWidgetComponent
							}
						},
						params: {
							[EditWidgetComponent.listName]: {
								type: 'json'
							}
						},
					},
					{
						name: `.:widgetId?${WidgetDetailsComponent.listName}`,
						reloadOnSearch: false,
						views: {
							'@reviews': {
								component: WidgetDetailsComponent
							}
						},
						params: {
							[WidgetDetailsComponent.listName]: {
								type: 'json'
							}
						},
						children: [
							{
								name: `.edit?${EditWidgetComponent.listName}`,
								reloadOnSearch: false,
								views: {
									'@reviews': {
										component: EditWidgetComponent
									}
								},
								params: {
									[EditWidgetComponent.listName]: {
										type: 'json'
									}
								},
							},
						],
					},
				]
			}
		]
	},
	// {
	// 	name: 'listings',
	// 	component: ListingsComponent,
	// 	children: [
	// 		{
	// 			name: `.company?${CompanyListingGroupsComponent.listName}`,
	// 			component: CompanyListingGroupsComponent,
	// 			reloadOnSearch: false,
	// 			params: {
	// 				[CompanyListingGroupsComponent.listName]: {
	// 					type: 'json'
	// 				}
	// 			},
	// 			children: [
	// 				{
	// 					name: '.:listingGroupId',
	// 					views: {
	// 						'@listings': {
	// 							component: CompanyListingGroupDetailsComponent
	// 						}
	// 					},
	// 					redirectTo: 'listings.company.:listingGroupId.overview',
	// 					children: [
	// 						{
	// 							name: '.overview',
	// 							component: CompanyListingGroupDetailsOverviewComponent,
	// 						},
	// 						{
	// 							name: '.settings',
	// 							component: CompanyListingGroupDetailsSettingsComponent,
	// 						},
	// 						{
	// 							name: '.alerts',
	// 							redirectTo: 'listings.company.:listingGroupId.alerts.open',
	// 							component: CompanyListingGroupAlertsComponent,
	// 							children: [
	// 								{
	// 									name: `.open?${CompanyListingGroupOpenAlertsComponent.listName}`,
	// 									params: {
	// 										[CompanyListingGroupOpenAlertsComponent.listName]: {
	// 											type: 'json'
	// 										}
	// 									},
	// 									reloadOnSearch: false,
	// 									component: CompanyListingGroupOpenAlertsComponent,
	// 								},
	// 								{
	// 									name: `.resolved?${CompanyListingGroupResolvedAlertsComponent.listName}`,
	// 									params: {
	// 										[CompanyListingGroupResolvedAlertsComponent.listName]: {
	// 											type: 'json'
	// 										}
	// 									},
	// 									reloadOnSearch: false,
	// 									component: CompanyListingGroupResolvedAlertsComponent,
	// 								},
	// 							],
	// 						},
	// 						{
	// 							name: '.:listingDetailsId',
	// 							params: {
	// 								notification: null
	// 							},
	// 							views: {
	// 								'@listings': {
	// 									component: ListingGroupListingDetailsComponent
	// 								}
	// 							}
	// 						},
	// 					]
	// 				}
	// 			]
	// 		},
	// 		{
	// 			name: `.alerts?${CompanyAlertedListingsComponent.listName}`,
	// 			params: {
	// 				[CompanyAlertedListingsComponent.listName]: {
	// 					type: 'json'
	// 				}
	// 			},
	// 			reloadOnSearch: false,
	// 			component: CompanyAlertedListingsComponent
	// 		}
	// 	]
	// },
	...SharedRoutes.SocialAccounts(''),
    {
        name: 'people?channelId',
        params: {
            channelId: {
                type: 'int',
                value: null,
                squash: true,
                dynamic: true,
            },
        },
        redirectTo: 'people.contacts',
        component: TpoPeopleComponent,
        children: [
            {
                name: '.contacts-import',
                url: '/contacts-import/:importId',
                params: {
                    importId: {
                        type: 'int',
                        squash: true,
                        value: null,
                    },
                },
                views: {
                    '@': {
                        component: ContactImportContainerComponent,
                    },
                },
                children: [
                    {
                        name: '.upload',
                        component: ContactsImportUploadComponent,
                    },
                    {
                        name: '.match',
                        component: ContactsImportMappingComponent,
                    },
                ],
            },
            {
                name: `.contacts?${TpoContactsListComponent.listName}`,
                params: {
                    [TpoContactsListComponent.listName]: {
                        type: 'jsonWithDate',
                    },
                },
                reloadOnSearch: false,
                component: TpoContactsListComponent,
                children: [
                    {
                        name: '.new',
                        views: {
                            '^': {
                                component: TpoContactGeneralInformationComponent,
                            },
                        },
                    },
                    {
                        name: '.:contactId',
                        url: '/{contactId:int}',
                        redirectTo: 'people.contacts.:contactId.information',
                        views: {
                            '@': {
                                component: TpoContactDetailsComponent,
                            },
                        },
                        children: [
                            {
                                name: '.information',
                                component: TpoContactGeneralInformationComponent,
                            },
                            {
                                name: '.settings',
                                component: SharedContactSettingsComponent,
                            },
                            {
                                name: `.audit-log?${NewContactAuditLogComponent.listName}`,
                                params: {
                                    [NewContactAuditLogComponent.listName]: {
                                        type: 'jsonWithDate',
                                    },
                                },
                                reloadOnSearch: false,
                                component: NewContactAuditLogComponent,
                            },
                            {
                                name: `.social-media?${NewContactSocialAccountsComponent.listName}`,
                                component: NewContactSocialAccountsComponent,
                                reloadOnSearch: false,
                                params: {
                                    [NewContactSocialAccountsComponent.listName]: {
                                        type: 'json',
                                    },
                                },
                            },
							{
                                name: `.web-presence?${NewContactWebPresenceComponent.listName}`,
                                component: NewContactWebPresenceComponent,
                                reloadOnSearch: false,
                                params: {
                                    [NewContactWebPresenceComponent.listName]: {
                                        type: 'json',
                                    },
                                },
                            },
                            {
                                name: `.publishing-permissions?{${IndividualPublishingPermissionsComponent.listName}:json}`,
                                url: '/publishing-permissions/:linkId',
                                reloadOnSearch: false,
                                views: {
                                    '@': {
                                        component: IndividualPublishingPermissionsComponent,
                                    }
                                },
                            },
                            {
                                name: `.publisher-requests`,
                                component: NewPublisherRequestsComponent,
                            },
                            {
                                name: `.removed?${NewContactRemovedSocialAccountsComponent.listName}`,
                                params: {
                                    [NewContactRemovedSocialAccountsComponent.listName]: {
                                        type: 'json',
                                    },
                                },
                                reloadOnSearch: false,
                                component: NewContactRemovedSocialAccountsComponent,
                            },
                            {
                                name: `.compliance`,
                                abstract: true,
                                component: EmptyContainerComponent,
                                children: [
                                    {
                                        name: `.audits?${NewContactsAuditsListComponent.listName}`,
                                        params: {
                                            [NewContactsAuditsListComponent.listName]: {
                                                type: 'json',
                                            },
                                        },
                                        reloadOnSearch: false,
                                        component: NewContactsAuditsListComponent,
                                    },
                                    {
                                        name: `.findings?${NewContactFindingsListComponent.listName}`,
                                        params: {
                                            [NewContactFindingsListComponent.listName]: {
                                                type: 'json',
                                            },
                                        },
                                        reloadOnSearch: false,
                                        component: NewContactFindingsListComponent,
                                    },
                                    {
                                        name: '.new-finding',
                                        views: {
                                            '^': {
                                                component: NewContactFindingComponent,
                                            },
                                        },
                                    },
                                ],
                            },
                            {
                                name: '.products',
                                component: LenderUserPanelProductsListComponent,
                            },
                        ],
                    },
                ],
            },
            {
                name: `.ownership?${PeopleOwnershipListComponent.listName}`,
                params: {
                    [PeopleOwnershipListComponent.listName]: {
                        type: 'jsonWithDate',
                    },
                },
                reloadOnSearch: false,
                component: PeopleOwnershipListComponent,
                children: [
                    {
                        name: '.company',
                        abstract: true,
                        views: {
                            '^': {
                                component: EmptyContainerComponent,
                                // component: TpoContactDetailsComponent,
                            },
                        },
                        children: [
                            {
                                name: '.:contactId',
                                url: '/{contactId:int}',
                                component: PeopleCompanyDetailsComponent,
                            },
                        ],
                    },
                ],
            },
            {
                name: `.imports?${ContactImportsListComponent.listName}`,
                params: {
                    [ContactImportsListComponent.listName]: {
                        type: 'jsonWithDate',
                    },
                },
                reloadOnSearch: false,
                component: ContactImportsListComponent,
            },
        ],
    },
	{
		name: 'solution-center',
		component: LenderSolutionCenterComponent,
		redirectTo: 'solution-center.search',
		children: [
			{
				name: `.search?${LenderSolutionCenterListComponent.listName}`,
				params: {
					[LenderSolutionCenterListComponent.listName]: {
						type: 'json',
					},
				},
				reloadOnSearch: false,
				component: LenderSolutionCenterListComponent,
			},
		]
	},
    {
		name: `solution-center-v2?{${LenderSolutionCenterListV2Component.listName}:json}`,
		component: LenderSolutionCenterListV2Component,
        children: [
            {
                name: '.:organizationId',
                url: '/{organizationId:int}/:tab',
                params: {
                    tab: {
                        type: 'string',
                        value: 'overview',
                    },
                },
                component: CompanyCardContainerComponent,
            },
        ]
	},
    {
        name: 'customers',
        component: EmptyContainerComponent,
    },
    {
        name: 'customers.:customerId',
        component: EmptyContainerComponent,
    },
    {
        name: 'customers.:customerId.products',
        component: EmptyContainerComponent,
    },
    {
        name: 'customers.:customerId.products.:productId',
        component: EmptyContainerComponent,
    },
    {
        name: 'customers.:customerId.products.:productId.plan',
        component: EmptyContainerComponent,
    },
    {
        name: 'customers.:customerId.products.:productId.plan.:planId',
        component: EmptyContainerComponent,
    },
    {
        name: 'customers.:customerId.products.:productId.plan.:planId.subscriptions',
        resolve: {
            recurlyConfig: RecurlyResolver
        },
        component: ProductSubscriptionComponent,
    },

];
