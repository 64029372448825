<form [formGroup]="entityForm" (onValidSubmit)="save()" class="remote-resource" [class.resolved]="!resolving">
    <div class="col-md-6 va-m form-group" has-error [isEditable]="editing">
        <span view class="control-value">{{ entity.entityName }}</span>
        <input edit class="form-control" type="text" name="entityName" formControlName="entityName"/>
    </div>
    <div class="col-md-6">
        <div *ngIf="editing" class="pull-right">
            <button class="btn btn-icon btn-tertiary" type="submit">
                <span class="glyphicon glyphicon-ok"></span>
            </button>
            <button class="btn btn-icon btn-tertiary" (click)="cancel()">
                <span class="glyphicon glyphicon-remove"></span>
            </button>
        </div>
        <div *ngIf="!editing && userCanAddEntity">
            <div class="pull-right">
                <div class="inline-menu" dropdown>
                    <a class="dropdown-toggle btn btn-icon btn-tertiary" dropdownToggle>
                        <span class="c-icon c-icon-dot-3"></span>
                    </a>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                        <li>
                            <a class="dropdown-item" (click)="edit()">
                                Edit
                            </a>
                        </li>
                        <li *ngIf="canBeDeleted">
                            <a class="dropdown-item" (click)="openDeleteModal()">
                                Delete
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</form>
