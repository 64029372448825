import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { SideMenuContainerComponent } from 'commons/components/navigation/menu/side/side-menu-container.component';
import { UIRouterGlobals } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { combineLatest, Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountInformation, AccountInformationService, LenderBadges } from 'shared/account/company-information/account-information.service';
import { GenericSideMenuItem } from 'commons/components/navigation/menu/side/side-menu.component';
import { RELATION } from 'shared/models/Relation';

interface ClientSideMenuItem extends GenericSideMenuItem {
    items?: ClientSideMenuItem[];
    badges?: LenderBadges,
}

@Component({
	selector: 'investor-client-menu',
	templateUrl: './investor-client-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestorClientMenuComponent extends SideMenuContainerComponent<ClientSideMenuItem> {
    @Input() lenderInfo: AccountInformation;
	protected user$: Observable<UserProfile>;
	protected user: UserProfile;

	constructor(
		{ profile$, profile }: UserService,
		changeDetector: ChangeDetectorRef,
		router: UIRouterGlobals,
        protected accountInformationService: AccountInformationService,
	) {
		super(changeDetector, router);
		this.user$ = profile$.asObservable();
		this.user = profile;
	}

	ngOnInit() {
		super.ngOnInit();
        const { isRegistered } = this.lenderInfo;
        const { id: lenderId } = this.user.organization;
        const { id } = this.router.params;
        const badges$ = this.hasHadRelation && isRegistered ? this.accountInformationService.getChannelBadges(id, lenderId).pipe(takeUntil(this.destroyed$)) : of(null);
        this.menuUpdate$ = combineLatest([this.user$, this.router.success$]).pipe(
            takeUntil(this.destroyed$),
        );
		combineLatest([this.user$, badges$]).pipe(
			takeUntil(this.destroyed$),
		).subscribe(this.populateMenu);
	}

    get hasHadRelation(): boolean {
        return this.lenderInfo.hasAccess(RELATION.TERMINATED, RELATION.ASSIGNED, RELATION.NOT_ACCESSIBLE, RELATION.NOT_ASSIGNED)
    }

	populateMenu = ([User, badges]: [UserProfile, LenderBadges]) => {
        const { hasAccess, isRegistered } = this.lenderInfo;
        const can = (...permissions: string[]) => permissions.some(permission => User.can(permission));

		const clientRoute = isRegistered ? 'client.:id' : 'nmls.client.:id';
		const accountRoute = `${clientRoute}.account`;
		const relationshipRoute = `${clientRoute}.relationships`;
		const reviewsRoute = `${clientRoute}.reviews`;

		this.menuItems = [
			{
				title: 'Account',
				link: {
					sref: `${accountRoute}.information`,
				},
				items: [
					{ title: 'Company Information', link: `${accountRoute}.information` },
                    ...(isRegistered ? [{
                        title: 'Channels',
                        link: `${accountRoute}.channels`,
                        badges: badges,

                    }] : []),
					{ title: 'DBA', link: `${accountRoute}.dba` },
					{ title: 'Federal Registration', link: `${accountRoute}.federal-registration` },
					{ title: 'Licenses', link: `${accountRoute}.licenses` },
					{ title: 'Locations', link: `${accountRoute}.locations` },
				],
			},

			...(isRegistered && this.hasHadRelation ? [
				{
					title: 'Account Information',
					link: `${relationshipRoute}.affiliates`,
					items: [
						{ title: 'Affiliates', link: `${relationshipRoute}.affiliates` },
						{ title: 'Agency', link: `${relationshipRoute}.agency` },
						{ title: 'Insurance & Bonds', link: `${relationshipRoute}.insuranceBonds` },
						{ title: 'Investors', link: `${relationshipRoute}.investors` },
                        { title: 'Technology & Operations', link: `${relationshipRoute}.tech-ops` },
						{ title: 'Underwriting', link: `${relationshipRoute}.underwriting` },
						{ title: 'Warehouse', link: `${relationshipRoute}.warehouse` },
					],
				},
			] : []),

            ...(User.crmEnabled ? [{
                title: 'Activities',
                link: `${clientRoute}.activities`,
            }] : []),

            ...(isRegistered && hasAccess(RELATION.TERMINATED, RELATION.ASSIGNED) ? [{
                title: 'Comments',
                link: `${clientRoute}.comments`,
            }] : []),

            ...(hasAccess(RELATION.TERMINATED, RELATION.ASSIGNED) || User.crmEnabled ? [{
                title: 'Contacts',
                link: `${clientRoute}.contacts`,
            }] : []),

            ...(isRegistered && hasAccess(RELATION.TERMINATED, RELATION.ASSIGNED) && can('VIEW_CLIENT_DOCUMENTS', 'VIEW_INTERNAL_DOCUMENTS', 'VIEW_DOCUMENT_LIBRARY') ? [{
                title: 'Documents',
                link: `${clientRoute}.documents`,
            }] : []),

            ...(isRegistered && hasAccess(RELATION.ASSIGNED) && can('VIEW_SCORECARDS', 'VIEW_ALERTS') ? [{
                title: 'Due Diligence',
                items: [
                    ...(this.user.can('VIEW_ALERTS') ? [{
                        title: 'Alerts',
                        link: `${reviewsRoute}.alerts`,
                    }] : []),
                    ...(this.user.can('VIEW_SCORECARDS') ? [{
                        title: 'Scorecard',
                        link: `${reviewsRoute}.scorecard.summary`,
                    }] : []),
                ],
            }] : []),

            ...(isRegistered ? [{
                title: 'Financials',
                link: `${clientRoute}.financials`,
                badge: 'Beta',
            }] : []),

            {
                title: 'Production',
                link: `${clientRoute}.production`,
            },

            ...(isRegistered && (hasAccess(RELATION.TERMINATED, RELATION.ASSIGNED, RELATION.NOT_ACCESSIBLE, RELATION.NOT_ASSIGNED) || User.crmEnabled) && User.can('VIEW_CLIENT_QUESTIONNAIRE') ? [
				{
					title: 'Questionnaires',
					link: `${clientRoute}.questionnaires`,
				},
			] : []),

            {
                title: 'Social Accounts',
                link: {
                    sref: `${clientRoute}.social-accounts.company`,
                    params: { linkListType: 'social' },
                },
            },
		];
		this.changeDetector.markForCheck();
	}
}
