import { Component } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual, map as _map } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserService } from 'angularjs-providers/user.provider';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { NgResourceObject } from 'commons/interfaces';

import { getForm } from './request-document-modal.form';
import { ClientDocumentsService } from 'lender/clients/$id/documents/client-documents.service';

import {
	ClientDocument,
} from 'lender/clients/$id/documents/client-documents.interface';
import { RequestDocumentModalComponent } from 'lender/clients/$id/documents/common';
import { FinancialDocumentResourceService } from 'commons/services/financial-documents-resource.service';
import {
    FulfilledFinancialDocumentRequest,
    FinancialNotificationRequest,
    FinancialQuarters,
    FinancialDocumentRequestYears,
} from 'commons/services/financial-documents.interface';

@Component({
	templateUrl: './request-document-modal.component.html',
})
export class RequestFinancialDocumentModalComponent extends RequestDocumentModalComponent {
    years: number[] = FinancialDocumentRequestYears;
    quarters = FinancialQuarters;
	assignForm: RealmFormGroup = getForm();
    fulfilledRequests: FulfilledFinancialDocumentRequest[];
    isDuplicateRequest: boolean = false;

	constructor(
		modalRef: BsModalRef,
		User: UserService,
		clientDocumentsResource: ClientDocumentsService,
        readonly financialDocumentResourceService: FinancialDocumentResourceService,
	) {
        super(modalRef, User, clientDocumentsResource);
	}

    ngOnInit(): void {
        this.getFulfilledRequests();
        this.assignForm.valueChanges
            .pipe(
                map(({ isAudited, year, period }) => ({ isAudited, year, period })),
                distinctUntilChanged(isEqual),
            )
            .subscribe(this.updateMessage);

        if (this.document) {
           this.updateMessage();
        }
    }

    async getFulfilledRequests() {
        const params = this.getResourceParams();
        this.fulfilledRequests = await this.financialDocumentResourceService.getFulfilledRequests(<number>params.lenderId, <number>params.tpoId, this.document.type).toPromise();
    }

    checkForDuplicateRequest() {
        const currentPeriod = this.assignForm.get('period').value;
        const currentYear = this.assignForm.get('year').value;
        const audited = this.assignForm.get('isAudited').value;
        const matchingExistingRequest = this.fulfilledRequests.find((request) => {
            return this.checkIfRequestMatches(request, currentPeriod, currentYear, audited);
        });
        this.isDuplicateRequest = !!matchingExistingRequest;
    }

    checkIfRequestMatches(
        request: FulfilledFinancialDocumentRequest,
        period: string,
        year: number,
        audited: boolean
    ): FulfilledFinancialDocumentRequest | undefined {
        if(request.period == period && request.year == year && audited != null) {
            if(!audited || request.audited == audited) {
                return request;
            }
        }
        return null;
    }

    updateMessage = () => {
        this.assignForm.get('message').patchValue(this.getMessage());
    }

    getAuditedText(value: boolean): string {
        if (value === true) return 'Audited';
        if (value === false) return 'Unaudited';
        return '';
    }

    getMessage(): string {
        const { name: organizationName } = this.User?.profile?.organization;
        const { name: documentName } = this.document;
        const { isAudited, year, period } = this.assignForm.value;
        const periodText = (year && period) ? `for ${period} ${year}` : ''
        return `${organizationName} is requesting that you upload ${ this.getAuditedText(isAudited) } ${documentName} ${periodText}.
Navigate to Financials: https://comergence.com/#/account/financials

Thank you,
Optimal Blue Customer Support`;
    }

    resetPeriod() {
        const periodField =  (this.assignForm.get('period') as RealmFormControl);
        if (periodField.serverError) {
            periodField.serverError = '';
            periodField.updateValueAndValidity();
        }
    }

	async save(): Promise<void> {
		this.saving = true;

		const {
			_notify_upon_assignment,
			toClients,
			ccRealmUserIds,
			message,
			_notify_upon_completion,
			notifyUponCompletionRealmUserIds,
			isAudited,
            period,
            year,
		} = this.assignForm.value;

		const hasDocumentId = this.document.id || this.document.libraryId;
        const toRealmUserIds = _map(toClients, 'realmUserId');
		const assignment: FinancialNotificationRequest = {
            isAudited,
            period,
            year,
			...(_notify_upon_assignment ? {
				message,
				ccRealmUserIds,
                toRealmUserIds,
			} : {}),
			...(_notify_upon_completion ? { notifyUponCompletionRealmUserIds } : {}),
			// ...(this.mode === RequestModalMode.FINANCIAL_REQUEST_DOCUMENT ? { dueDate } : {}),
		}

		if (!hasDocumentId) {
			this.document.assignment = assignment;
		}

		if (this.sendRequest) {
			try {
				const updatedDocument: NgResourceObject<ClientDocument> = await this.sendRequest(
					this.getResourceParams(),
					hasDocumentId ? assignment : this.document,
				).$promise;
				this.modalRef.hide();
				this.reload(updatedDocument);
			} catch ({ data }) {
				this.saving = false;
				this.assignForm.setServerError(data);
			}
		} else if (this.onRequest) {
			this.onRequest(assignment);
		}
	}
}
