import { Component } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { asyncScheduler } from 'rxjs';
import { AbstractRoleNavComponent } from '../abstract-role-nav.component';
import { NavigationMenuItem } from '../../../../utils/navigation/navigation.interface';
import { TpoIssuesStatusService } from 'tpo/status';
import { skip, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { PACKAGE_FEATURE } from 'tpo/social-media/sm-package.service';
import { debounceTime } from 'rxjs/operators';

const {
    featureToggle_thinkific_enabled: thinkificEnabled,
} = window.env;

@Component({
	selector: 'tpo-topnav',
	templateUrl: './role-topnav.component.html',
})
export class TpoTopNavComponent extends AbstractRoleNavComponent {
    constructor(
        stateService: StateService,
        userService: UserService,
        private readonly tpoIssuesStatusService: TpoIssuesStatusService,
    ) {
        super(stateService, userService);
    }

	// Override
	public ngOnInit(): void {
        this.userService.profile$.pipe(
            debounceTime(100),
            skip(1), //Skip first update
            takeUntil(this.userProfileUnsubscribe$),
        ).subscribe(() => {
            this.tpoIssuesStatusService.documents.outstandingRequired.update();
            this.tpoIssuesStatusService.socialMedia.socialCompliance.schedule.update();
            this.tpoIssuesStatusService.socialMedia.socialCompliance.orderPaymentFailed.update();
            // PP TODO: Update all other Session flags services
        });

        this.userService.profile$.pipe(
            debounceTime(100),
            skip(1), //Skip first update
            throttleTime((60 * 1000), asyncScheduler, { leading: true, trailing: true }),
            takeUntil(this.userProfileUnsubscribe$),
        ).subscribe(() => {
            this.tpoIssuesStatusService.account.questionnaires.update();
            this.tpoIssuesStatusService.financial.update();
        });

		super.ngOnInit();
    }

	// Override
	protected rebuildMenuItems(user: UserProfile): NavigationMenuItem[] {
		const menuItems: NavigationMenuItem[] = [];
        const srefParams = { channelId: null }; //reset channelId

		menuItems.push({
			sref: 'home',
			title: 'Home',
			isActive: () => this.stateService.includes('home'),
		});

		menuItems.push({
			sref: 'account.information',
            srefParams,
			title: 'Account',
			isActive: () => this.stateService.includes('account'),
			hasAlert: this.coalesceHasAlertObservables([
				this.tpoIssuesStatusService.account.questionnaires.get(),
				this.tpoIssuesStatusService.financial.get(),
			]),
		});

		if (user.can('TPO_DOCUMENTS')) {
			menuItems.push({
				sref: 'documents',
				title: 'Documents',
				isActive: () => this.stateService.includes('documents'),
				hasAlert: this.coalesceHasAlertObservables([
					this.tpoIssuesStatusService.documents.outstandingRequired.get(),
				]),
			});
		}

        menuItems.push({
            sref: 'people',
            srefParams,
            title: 'People',
            isActive: () => this.stateService.includes('people'),
        });

		menuItems.push({
			sref: 'solution-center',
			srefParams,
			title: 'Solution Center',
			isActive: () => this.stateService.includes('solution-center'),
		});

		const socialMediaSubMenuItems: NavigationMenuItem[] = [];

        // if (user.hasFeature(PACKAGE_FEATURE.MARKETING)) {
        //     socialMediaSubMenuItems.push({
        //         sref: 'social-compliance.marketing',
        //         title: 'Marketing',
        //     });
        // }

        if (user.hasFeature(PACKAGE_FEATURE.REPUTATION_MANAGEMENT)) {
            socialMediaSubMenuItems.push({
                sref: 'social-compliance.reputation',
                title: 'Reviews',
            });
        }

        // if (user.hasFeature(PACKAGE_FEATURE.LISTINGS)) {
        //     socialMediaSubMenuItems.push({
        //         sref: 'listings',
        //         title: 'Listings',
        //         hasAlert: this.coalesceHasAlertObservables([
        //             this.tpoIssuesStatusService.socialMedia.listings.groups.get(),
        //             this.tpoIssuesStatusService.socialMedia.listings.alerts.get(),
        //         ]),
        //     });
        // }

        socialMediaSubMenuItems.push({
			sref: 'social-compliance.get-started',
			srefOptions: { reload: true },
			title: 'Compliance',
			hasAlert: this.coalesceHasAlertObservables([
				this.tpoIssuesStatusService.socialMedia.socialCompliance.schedule.get(),
				this.tpoIssuesStatusService.socialMedia.subscription.hasPastDueInvoice.get(),
				this.tpoIssuesStatusService.socialMedia.socialCompliance.orderPaymentFailed.get(),
			]),
		});

		socialMediaSubMenuItems.push({
			sref: 'social-accounts.company',
			srefParams: { linkListType: 'social' },
			title: 'Social Accounts',
		});

		menuItems.push({
			sref: 'social-accounts',
			title: 'Social Media',
			isActive: () => {
				return (this.stateService.includes('**.social-compliance.**') ||
					this.stateService.includes('**.social-accounts.**') ||
					this.stateService.includes('**.reviews.**') ||
					this.stateService.includes('**.listings.**')
				);
			},
			hasAlert: this.coalesceHasAlert(socialMediaSubMenuItems),
		});

        menuItems.push({
            sref: '/api/rest/tableau/redirect',
            rawHref: true,
            rawHrefTarget: '_blank',
            srefOptions: { reload: true },
            title: 'Reports',
        });

        if (thinkificEnabled === 'true') {
            menuItems.push({
                sref: '/api/rest/thinkific/redirect',
                rawHref: true,
                rawHrefTarget: '_blank',
                srefOptions: { reload: true },
                title: 'Academy',
            });
        }

		return menuItems;
	}
}
