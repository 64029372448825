import { Injectable } from '@angular/core';
import { RealmHttpClient } from 'commons/services/http';
import { Observable } from 'rxjs';
import {
    CompanyInformation,
    EmailCheck,
    EmailCheckResult,
    NewUserRegistrationInformation,
} from 'login/registration/registration-interface.interface';


declare let apiPath: string;

@Injectable()
export class RegistrationService {
    constructor( private readonly http: RealmHttpClient ) {}

    public validateEmail(email: string): Observable<EmailCheckResult> {
        const emailCheck: EmailCheck = {
            emailAddress: email,
        };

        return this.http.request<EmailCheckResult>(
            'POST',
            `${apiPath}/emailaddresscheck`,
            {},
            emailCheck
        );
    }

    public validateNmlsId(nmlsId: number, token): Observable<CompanyInformation> {
        return this.http.requestWithHeaders<CompanyInformation>(
            'GET',
            `${apiPath}/nmls/company/${nmlsId}`,
            {},
            null,
            {
                'recaptcha-token': token,
            },
        );
    }

    public registerUser(newUserInformation: NewUserRegistrationInformation): Observable<void> {
        return this.http.request<void>(
            'POST',
            `${apiPath}/register`,
            null,
            newUserInformation
        )
    }
}
