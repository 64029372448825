import {Component, Input, OnInit} from '@angular/core';
import { TpoProductsService } from 'shared/tpo-products/tpo-products.service';
import {
    TpoProduct,
    PlanPaymentStatus,
    TpoProductUpdateOptions,
    TpoProductPaymentMethodOption,
    TpoProductStatusOption,
    TpoProductUpdateRequestBody,
    CcmTpoProductUpdateRequestBody
} from 'shared/tpo-products/tpo-product.interface';
import { UIRouter } from '@uirouter/core';
import { firstValueFrom } from 'rxjs';
import { UserService } from 'angularjs-providers/user.provider';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { PaymentMethodType, PaymentMethodTypes } from 'tpo/payment-methods/payment-methods.interface';
import { CustomRequiredValidator } from 'commons/validators';
import { Validators } from '@angular/forms';
import { AccountIdentifier } from 'shared/account/company-information/account-information.service';

@Component({
    templateUrl: 'tpo-product-settings.component.html',
})
export class TpoProductSettingsComponent implements OnInit {

    @Input() accountIdentifier: AccountIdentifier;

    productId: number
    product: TpoProduct;
    productUpdateOptions: TpoProductUpdateOptions;
    tpoId: number;
    resolving: boolean = false;
    editing: boolean = false;
    canManageProducts: boolean;
    isComergence: boolean;
    isTpo: boolean;
    updateProductForm: RealmFormGroup;

    constructor(
        private tpoProductsService: TpoProductsService,
        private readonly uiRouter: UIRouter,
        private readonly userService: UserService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.tpoId = this.accountIdentifier.tpoId;
        this.productId = this.uiRouter.globals.params.productId;
        this.isComergence= this.userService.profile.isComergence;
        this.isTpo = this.userService.profile.isTpo;

        this.canManageProducts =  (this.isTpo && this.userService.profile.can('TPO_MANAGE_PAYMENTS')) ||
            (this.isComergence && this.userService.profile.can('MANAGE_PRODUCTS'));

        await this.retrieveProductAndUpdateOptions();

        this.initializeProductForm();
    }

    private async retrieveProduct(): Promise<void> {
        this.product = await firstValueFrom(this.tpoProductsService.getProductByTpoId(this.tpoId, this.productId));
    }

    private async retrieveProductUpdateOptions(): Promise<void> {
        this.productUpdateOptions = await firstValueFrom(this.tpoProductsService.getTPOProductUpdateOptions(this.tpoId));
    }

    private async retrieveProductAndUpdateOptions(): Promise<void> {
        try {
            this.resolving = true;

            if (this.isComergence) {
                await Promise.all([this.retrieveProduct(), this.retrieveProductUpdateOptions()]);
            }
            else {
                await this.retrieveProduct();
            }
        } finally {
            this.resolving = false;
        }
    }

    private initializeProductForm(): void {
        const cancellationDateDisabled =
            PaymentMethodTypes[this.product?.paymentMethodType] === PaymentMethodTypes.CREDIT_CARD
            || PlanPaymentStatus[this.product?.planPaymentStatus] !== PlanPaymentStatus.PENDING_CANCELLATION;

        this.updateProductForm =  new RealmFormGroup({
            poNumber: new RealmFormControl(
                'poNumber',
                {
                    label: 'PO Number',
                    value: this.product?.poNumber,
                    disabled: !this.isTpo,
                },
                Validators.maxLength(999),
            ),
            costCenter: new RealmFormControl(
                'costCenter',
                {
                    label: 'Cost Center',
                    value: this.product?.costCenter,
                    disabled: !this.isTpo,
                },
                Validators.maxLength(999),
            ),
            paymentMethodType: new RealmFormControl(
                'paymentMethodType',
                {
                    label: 'Payment Method',
                    value: this.product?.paymentMethodType,
                    disabled: !this.isComergence,
                },
            ),
            planPaymentStatus: new RealmFormControl(
                'planPaymentStatus',
                {
                    label: 'Status',
                    value: this.product?.planPaymentStatus,
                    disabled: !this.isComergence,
                },
            ),
            requestedCancellationDate: new RealmFormControl(
                'requestedCancellationDate',
                {
                    label: 'Cancellation Date',
                    value: this.getCancellationDate(),
                    disabled: !this.isComergence || cancellationDateDisabled,
                },
                CustomRequiredValidator("<b>{field}</b> must be provided when status is 'Pending Cancellation'"),
            ),
        });
    }

    protected getDateFromValue(dateValue: number): Date {
        return dateValue ? new Date(dateValue) : null;
    }

    protected getCancellationDate(): Date {
        return this.getDateFromValue(this.product?.requestedCancellationDate);
    }

    protected getPurchaseDate(): Date {
       if ('CREDIT_CARD' === this.product?.paymentMethodType) {
           return this.getDateFromValue(this.product?.purchaseDate);
       }
       else {
           return null;
       }
    }

    protected getPurchasedBy(): string {
        if ('CREDIT_CARD' === this.product?.paymentMethodType) {
            return this.product?.purchasedBy;
        }
        else {
            return null;
        }
    }

    protected getPaymentMethodUpdateOptions(): TpoProductPaymentMethodOption[] {
        const currentPaymentMethod = this.product?.paymentMethodType;
        const allowedPaymentMethods: PaymentMethodType[] =
            this.productUpdateOptions.paymentMethodUpdateOptions[currentPaymentMethod];

        const paymentMethodOptions: TpoProductPaymentMethodOption[] =
            allowedPaymentMethods.map(option => ({ value: option, label: PaymentMethodTypes[option].display }));

        paymentMethodOptions.push({ value: currentPaymentMethod, label: PaymentMethodTypes[currentPaymentMethod].display })

        return paymentMethodOptions;
    }

    protected trackByPaymentMethodType(item: TpoProductPaymentMethodOption) : PaymentMethodType {
        return item.value;
    }

    protected getStatusUpdateOptions(): TpoProductStatusOption[] {
        const selectedPaymentMethod = this.updateProductForm.value.paymentMethodType;
        const currentStatus = this.product?.planPaymentStatus;

        const allowedStatuses: PlanPaymentStatus[] = 'CREDIT_CARD' === selectedPaymentMethod
            ? this.productUpdateOptions.subscriptionStatusUpdateOptions[currentStatus]
            : this.productUpdateOptions.statusUpdateOptions[currentStatus];

        const statusOptions: TpoProductStatusOption[] =
            allowedStatuses.map(option => ({ value: option, label: PlanPaymentStatus[option] }));

        statusOptions.push({ value: currentStatus, label: PlanPaymentStatus[currentStatus] })

        return statusOptions;
    }

    protected trackByPlanPaymentStatus(item: TpoProductStatusOption) : PlanPaymentStatus {
        return item.value;
    }

    protected valuesChanged(): boolean {
        return Object.entries(this.updateProductForm.value).some( (value) => {
            const fieldName = value[0];
            const fieldValue = value[1];
            return this.product[fieldName] !== fieldValue;
        });
    }

    protected hasError(controlName: string): boolean {
        const { invalid, touched } = this.updateProductForm.get(controlName);

        return invalid && touched;
    }

    protected toggleCancellationDateBasedOnStatus(status: PlanPaymentStatus): void {
        const requestedCancellationDateControl = this.updateProductForm.controls.requestedCancellationDate;

        if (PlanPaymentStatus[status] === PlanPaymentStatus.PENDING_CANCELLATION) {
            const paymentMethodChanged = this.updateProductForm.value.paymentMethodType !== this.product.paymentMethodType;

            requestedCancellationDateControl.enable();
            requestedCancellationDateControl.patchValue(paymentMethodChanged ? null : this.getCancellationDate());
        }
        else {
            requestedCancellationDateControl.patchValue(null);
            requestedCancellationDateControl.disable();
        }
    }

    protected clearCancellationDateOnPaymentMethodChange(paymentMethodType: PaymentMethodType): void {
        const status = this.updateProductForm.value.planPaymentStatus;

        if (PlanPaymentStatus[status] === PlanPaymentStatus.PENDING_CANCELLATION) {
            const requestedCancellationDateControl = this.updateProductForm.controls.requestedCancellationDate;
            const paymentMethodChanged = this.product.paymentMethodType !== paymentMethodType;

            requestedCancellationDateControl.patchValue(paymentMethodChanged ? null : this.getCancellationDate());
        }
    }

    protected getEarliestValidCancellationDate(): Date {
        const currentDate = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(currentDate.getDate() + 1)

        return tomorrow;
    }

    protected enterEdit(): void {
        this.editing = true;
    }

    protected cancelEdit(): void {
        this.editing = false;
        this.updateProductForm.reset();
        this.initializeProductForm();
    }

    private async processUpdateForTPOUser(): Promise<TpoProduct> {
        const updatedValues = this.updateProductForm.value;
        const updateRequest: TpoProductUpdateRequestBody = {
            poNumber: updatedValues.poNumber ?? null,
            costCenter: updatedValues.costCenter ?? null,
        };

        return firstValueFrom(
            this.tpoProductsService.updateTPOProduct(this.tpoId, this.productId, updateRequest)
        );
    }

    private async processUpdateForCCMUser(): Promise<TpoProduct> {
        const updatedValues = this.updateProductForm.value;
        const updateRequest: CcmTpoProductUpdateRequestBody = {
            planPaymentStatus: updatedValues.planPaymentStatus ?? null,
            paymentMethodType: updatedValues.paymentMethodType ?? null,
            requestedCancellationDate: updatedValues.requestedCancellationDate ?? null
        };

        return firstValueFrom(
            this.tpoProductsService.ccmUpdateTPOProduct(this.tpoId, this.productId, updateRequest)
        );
    }

    protected async updateProduct(): Promise<void> {
        try {
            this.resolving = true;
            this.product = await (this.isComergence ? this.processUpdateForCCMUser() : this.processUpdateForTPOUser());
        }
        finally {
            this.editing = false;
            this.resolving = false;
        }
    }

    protected readonly PlanPaymentStatus = PlanPaymentStatus;
    protected readonly PaymentMethodTypes = PaymentMethodTypes;

}
