import { Component } from '@angular/core';
import { UserService } from "angularjs-providers/user.provider";
import { PaymentMethodsService } from "./payment-methods.service";
import { PaymentMethod } from "./payment-methods.interface";
import { firstValueFrom } from "rxjs";
import {
    GlobalNotificationsService,
    GlobalNotificationType
} from "global-elements/notication-center/notifications.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    selector: 'payment-methods',
    templateUrl: './payment-methods.component.html',
})
export class TpoPaymentMethodsComponent {
    tpoId: number;
    paymentMethods: PaymentMethod[];
    resolving: boolean;

    constructor(
        private userService: UserService,
        private paymentMethodsService: PaymentMethodsService,
        private readonly globalNotificationsService: GlobalNotificationsService,
    ) {
    }

   async ngOnInit(): Promise<void> {
        if (this.userService.profile.can('TPO_MANAGE_PAYMENTS')) {
            await this.setPaymentMethods();
        }
        else {
            throw new HttpErrorResponse({ status: 403 });
        }
   }

   private async setPaymentMethods(): Promise<void> {
       try {
           this.resolving = true;
           this.tpoId = this.userService.profile.organization.id;
           this.paymentMethods = await firstValueFrom(this.paymentMethodsService.getPaymentMethods(this.tpoId, true));
       } catch ({ error: { message }, message: httpError }) {
           this.globalNotificationsService.add({
               type: GlobalNotificationType.ERROR,
               message,
           });
       } finally {
           this.resolving = false;
       }
   }

}
