import { Component, input, model, OnInit, output } from '@angular/core';
import { Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { ToggleComponent } from 'commons/components/toggle';

import { CcmCustomerProductsNewService } from 'comergence/ccm-customer-product-new/ccm-customer-products-new.service';
import { CcmCustomerProductNew, ProductFeature } from 'comergence/ccm-customer-product-new/ccm-customer-product-new.interface';
import { CcmServiceStatusService } from 'comergence/customers/ccm-service-status.service';


@Component({
    selector: 'product-item',
    templateUrl: 'ccm-customer-product-item-new.component.html',
})
export class CcmCustomerProductItemNewComponent implements OnInit {
    public item = model<CcmCustomerProductNew>();
    public userCanAddEdit = input<boolean>();
    public organizationId = input<number>();
    public productIndex = input<number>();
    public onNewItemCancel = output<CcmCustomerProductNew>();
    public onDelete = output<number>();
    isEditing: boolean = false;
    resolving: boolean;
    toggleLoading: boolean;
    protected readonly productFeature = ProductFeature;

    productForm = new RealmFormGroup({
        productName: new RealmFormControl(
            'productName',
            {
                label: 'Product Name',
            },
            Validators.required,
        ),
    });

    constructor(
        private ccmProductService: CcmCustomerProductsNewService,
        private readonly modalService: BsModalService,
        private readonly ccmServiceStatusService: CcmServiceStatusService,
    ) {}

    ngOnInit() {
        if (!this.item().productId) {
            return this.isEditing = true;
        }
    }

    protected async toggleFeature(item: CcmCustomerProductNew, newValue:boolean, key: ProductFeature, self: ToggleComponent) {
        try {
            self.loading = true
            const updatedProduct: CcmCustomerProductNew = await firstValueFrom(
                this.ccmProductService.updateCustomerProduct(item.productId, this.organizationId(), newValue, key)
            );
            this.item.update(item => updatedProduct);
            self.loading = false;
        } finally {
            this.toggleLoading = false;
        }
    }

    doSave() {
        let result: CcmCustomerProductNew = this.productForm.value;
        result = { ...this.item(), ...result };
        this.updateProductName(result);
    }

    private async updateProductName(result: CcmCustomerProductNew) {
        try {
            this.resolving = true;
            const updatedProduct: CcmCustomerProductNew = await firstValueFrom(
                this.ccmProductService.updateCustomerProductName(result.productId, this.organizationId(), {name: result.productName})
            );
            this.item.update(currentItem => ({ ...currentItem, productName: updatedProduct.productName }));
            this.isEditing = false;
        } finally {
            this.resolving = false;
        }
    }

    doEdit() {
        const formValue = ({ ...this.item() } as CcmCustomerProductNew);
        this.productForm.patchValue(formValue);
        this.isEditing = true;
    }

    doCancel() {
        this.isEditing = false;
        if (!this.item().productId) {
            this.onNewItemCancel.emit(this.item());
        }
    }
}


