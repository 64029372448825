import { AfterViewInit, Component, Input, OnInit, signal } from '@angular/core';
import { ProductSubscriptionService } from "./product-subscription.service";
import { UIRouter } from '@uirouter/core';
import { ProductSubscriptionPlan } from './product-subscription.interface';
import { RecurlyService } from 'commons/recurly';
import { UserService } from 'angularjs-providers/user.provider';
import { firstValueFrom } from 'rxjs';
import { PaymentMethod } from 'tpo/payment-methods/payment-methods.interface';
import { PaymentMethodsService } from 'tpo/payment-methods/payment-methods.service';
import { GlobalNotificationsService, GlobalNotificationType } from 'global-elements/notication-center/notifications.service';

@Component({
    templateUrl: 'product-subscription.component.html',
    selector: 'products-subscriptions',
})
export class ProductSubscriptionComponent implements OnInit, AfterViewInit {
    planId: number;
    productId: number;
    productPlan: ProductSubscriptionPlan;
    @Input() recurlyConfig;
    tpoId: number;
    organizationId: number;
    resolved = signal<boolean>(false);
    paymentMethods: PaymentMethod[];
    paymentMethodSelection: string = 'NP';

    constructor(
        private readonly productSubscriptionService: ProductSubscriptionService,
        private readonly router: UIRouter,
        private recurly: RecurlyService,
        private userService: UserService,
        private paymentMethodsService: PaymentMethodsService,
        private globalNotificationsService: GlobalNotificationsService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.productId = this.router.globals.params.productId;
        this.planId = this.router.globals.params.planId;
        this.organizationId = this.router.globals.params.customerId;
        this.tpoId = this.userService.profile.organization.id;
        try {
            this.resolved.set(false);
            this.productPlan = await firstValueFrom(this.productSubscriptionService.getProductPlanSummary(
                this.tpoId,
                this.organizationId,
                this.planId,
                this.productId));
        } catch ({ error: { message }, message: httpError }) {
            this.globalNotificationsService.add({
                type: GlobalNotificationType.ERROR,
                message,
            });
        } finally {
            this.resolved.set(true);
        }
        try {
            this.paymentMethods = await firstValueFrom(this.paymentMethodsService.getPaymentMethods(
                this.tpoId,
                false));
        } catch ({ error: { message }, message: httpError }) {
            this.globalNotificationsService.add({
                type: GlobalNotificationType.ERROR,
                message,
            });
        } finally {
            this.resolved.set(true);
        }

        this.paymentMethodSelection = this.paymentMethods?.length > 0 ? 'OP' : 'NP';
    }

    ngAfterViewInit() {
        const config = {
            ...this.recurlyConfig,
        };
         this.recurly.configure(config);
    }

    setResolvedValue(newValue: boolean) {
        this.resolved.set(newValue);
    }
}
