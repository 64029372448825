import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';

import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { PipesModule } from 'commons/pipes/pipes.module';
import { SolutionCenterV2Component } from 'lender/solution-center-v2/solution-center.component';
import { InvestorSolutionCenterV2Service } from 'lender/solution-center-v2/solution-center.service';
import { SCCompanyInfoV2Component } from 'lender/solution-center-v2/tabs/company-info/company-info.component';
import { SCOverviewV2Component } from 'lender/solution-center-v2/tabs/overview/overview.component';
import { ShareSolutionCenterV2Module } from 'shared/solution-center-v2/solution-center.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
        DirectivesModule,
        QuillModule,
        ShareSolutionCenterV2Module,
    ],
    declarations: [
        SolutionCenterV2Component,
        SCCompanyInfoV2Component,
        SCOverviewV2Component,
    ],
    providers: [
        InvestorSolutionCenterV2Service,
    ],
})
export class SolutionCenterV2Module {
}
