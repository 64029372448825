<div class="content">
    <div *ngIf="!editing()" class="pull-right margin-bottom-sm">
        <button type="button" class="btn btn-primary" (click)="edit()">
            <span class="glyphicon glyphicon-pencil"></span> Edit
        </button>
    </div>
    <form [formGroup]="form" (onValidSubmit)="submit()">
        <form-errors [form]="form"></form-errors>
        <div class="row-fluid form-vertical" *ngIf="solutionCenter.companyInfo() as companyInfo">
            <div class="rows-white">
                <div class="row-fluid">
                    <div class="col-md-6">
                        <div class="flex-row space-between">
                            <label class="control-label" [class.required]="editing()">
                                {{form.getFieldLabel('companyLogo')}}
                            </label>
                            <div *ngIf="editing()">
                                <uploader #uploader [customTemplate]="fileTemplate" [config]="{ accept: '.png, .jpg, .jpeg' }" (select)="onLogoPick($event)">
                                    <ng-template #fileTemplate>
                                        <label class="text-link weight-normal">
                                            Replace Image&nbsp;
                                            <input type="file" (input)="uploader.onFileInput($event)" [multiple]="uploader.config.multiple" [accept]="uploader.config.accept">
                                        </label>
                                    </ng-template>
                                </uploader>
                                <a class="btn btn-icon btn-tertiary" (click)="removeLogo()">
                                    <span class="glyphicon glyphicon-trash"></span>
                                </a>
                            </div>
                        </div>

                        <div *ngIf="!companyInfo.hasCompanyLogo && !editing()">
                            {{ null | empty }}
                        </div>

                        <div class="image-wrapper" *ngIf="companyInfo.hasCompanyLogo && !editing()">
                            <img [src]="companyInfo.companyLogo" />
                        </div>

                        <ng-container *ngIf="editing()">
                            <div [ngSwitch]="!companyInfo.originalCompanyLogo">
                                <uploader #uploader [customTemplate]="fileTemplate" *ngSwitchCase="true" [config]="{ accept: '.png, .jpg, .jpeg' }" (select)="onLogoPick($event)">
                                    <ng-template #fileTemplate>
                                        <div [ngClass]="{'file-error': uploadLogoError }" class="uploader-container custom-uploader-container flex-row center gap" (fileDrop)="uploader.select.emit($event)">
                                            <label class="btn btn-secondary">
                                                Upload File
                                                <input
                                                    type="file"
                                                    (input)="uploader.onFileInput($event)"
                                                    [multiple]="uploader.config.multiple"
                                                    [accept]="uploader.config.accept">
                                            </label>
                                            <span class="text-muted">or Drag and Drop here</span>
                                        </div>
                                    </ng-template>
                                </uploader>
                                <ng-container *ngSwitchDefault>
                                    <realm-image-cropper
                                            [image]="companyInfo.originalCompanyLogo"
                                            [crop]="companyInfo.companyLogoCoordinates"
                                            (cropChange)="onCropChange($event)"
                                            minHeight="256"
                                            maxHeight="256"
                                            [aspectRange]="[1, 4]"
                                            (update)="onCroppedLogoUpdate($event)"
                                            style="--aspect: 16/9;">
                                    </realm-image-cropper>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-md-6">
                        <div class="info-block" *ngIf="editing()">
                            <div class="pd10">
                                <i class="c-icon c-icon-circle-exclamation"></i>
                                &nbsp;
                                <b>Recommended sizes</b>
                            </div>
                            <ul>
                                <li>
                                    <b>Square Logo:</b> 256 x 256 pixels.
                                </li>
                                <li>
                                    <b>Rectangular Logo:</b> 800 x 200 pixels.
                                </li>
                            </ul>
                        </div>
                        <label class="control-label" [class.required]="editing()">
                            {{form.getFieldLabel('companyDescription')}}
                        </label>
                        <div [ngSwitch]="editing()">
                            <span *ngSwitchDefault >
                                {{companyInfo.companyDescription | empty}}
                            </span>
                            <ng-container *ngSwitchCase="true">
                                <textarea class="form-control resize-vertical" rows="8" [maxlength]="maxLength" formControlName="companyDescription"></textarea>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h3>Social Media Links</h3>
        <div class="form-vertical">
            <div class="rows-striped" *ngIf="solutionCenter.companyInfo() as companyInfo">
                <ng-container formGroupName="medialinks" *ngIf="companyInfo.medialinks as medialinks">
                    <div class="row-fluid">
                        <div class="col-md-6 form-group" has-error>
                            <label class="control-label">
                                {{form.getFieldLabel('medialinks.FB')}}
                            </label>
                            <div [isEditable]="editing()">
                                <span view class="control-value">{{medialinks.FB | empty}}</span>
                                <input
                                    edit
                                    type="text"
                                    formControlName="FB"
                                    class="form-control"
                                    maxlength="100" />
                            </div>
                        </div>
                        <div class="col-md-6 form-group" has-error>
                            <label class="control-label">
                                {{form.getFieldLabel('medialinks.IG')}}
                            </label>
                            <div [isEditable]="editing()">
                                <span view class="control-value">{{medialinks.IG | empty}}</span>
                                <input
                                    edit
                                    formControlName="IG"
                                    class="form-control"
                                    maxlength="100" />
                            </div>
                        </div>
                    </div>
                    <div class="row-fluid">
                        <div class="col-md-6 form-group" has-error>
                            <label class="control-label">
                                {{form.getFieldLabel('medialinks.LI')}}
                            </label>
                            <div [isEditable]="editing()">
                                <span view class="control-value">{{medialinks.LI | empty}}</span>
                                <input
                                    edit
                                    type="text"
                                    formControlName="LI"
                                    class="form-control"
                                    maxlength="100" />
                            </div>
                        </div>
                        <div class="col-md-6 form-group" has-error>
                            <label class="control-label">
                                {{form.getFieldLabel('medialinks.X')}}
                            </label>
                            <div [isEditable]="editing()">
                                <span view class="control-value">{{medialinks.X | empty}}</span>
                                <input
                                    edit
                                    formControlName="X"
                                    class="form-control"
                                    maxlength="100" />
                            </div>
                        </div>
                    </div>
                    <div class="row-fluid">
                        <div class="col-md-6 form-group" has-error>
                            <label class="control-label">
                                {{form.getFieldLabel('medialinks.YT')}}
                            </label>
                            <div [isEditable]="editing()">
                                <span view class="control-value">{{medialinks.YT | empty}}</span>
                                <input
                                    edit
                                    type="text"
                                    formControlName="YT"
                                    class="form-control"
                                    maxlength="100" />
                            </div>
                        </div>
                        <div class="col-md-6"></div>
                    </div>
                </ng-container>
            </div>
        </div>

        <h3>Company Information</h3>
        <div class="form-vertical">
            <div class="rows-striped" *ngIf="solutionCenter.companyInfo() as companyInfo">
                <div class="row-fluid">
                    <div class="col-md-6 form-group" has-error>
                        <label class="control-label" [class.required]="editing()">
                            {{form.getFieldLabel('companyName')}}
                        </label>
                        <div [isEditable]="editing()">
                            <span view class="control-value">{{companyInfo.companyName | empty}}</span>
                            <input
                                edit
                                type="text"
                                formControlName="companyName"
                                class="form-control"
                                maxlength="100" />
                        </div>
                    </div>
                    <div class="col-md-6 form-group" has-error>
                        <label class="control-label">
                            {{form.getFieldLabel('companyContactName')}}
                        </label>
                        <div [isEditable]="editing()">
                            <span view class="control-value">{{companyInfo.companyContactName | empty}}</span>
                            <input
                                edit
                                type="text"
                                formControlName="companyContactName"
                                class="form-control"
                                maxlength="100" />
                        </div>
                    </div>
                </div>
                <div class="row-fluid">
                    <div class="col-md-6 form-group" has-error>
                        <label class="control-label">
                            {{form.getFieldLabel('companyPhone')}}
                        </label>
                        <div [isEditable]="editing()">
                            <span view class="control-value">{{companyInfo.companyPhone | tel | empty}}</span>
                            <input
                                phoneFormatter
                                edit
                                type="text"
                                formControlName="companyPhone"
                                class="form-control"
                                maxlength="100" />
                        </div>
                    </div>
                    <div class="col-md-6 form-group" has-error>
                        <label class="control-label" tooltip="This email receives general requests to contact your company" placement="right">
                            {{form.getFieldLabel('companyEmail')}} <span class="glyphicon glyphicon-question-sign text-medium"></span>
                        </label>
                        <div [isEditable]="editing()">
                            <span view class="control-value">{{companyInfo.companyEmail | empty}}</span>
                            <input
                                edit
                                type="text"
                                formControlName="companyEmail"
                                class="form-control"
                                maxlength="100" />
                        </div>
                    </div>
                </div>
                <div class="row-fluid">
                    <div class="col-md-6 form-group" has-error>
                        <label class="control-label">
                            {{form.getFieldLabel('companyWebsite')}}
                        </label>
                        <div [isEditable]="editing()">
                            <span view class="control-value">{{companyInfo.companyWebsite | empty}}</span>
                            <input
                                edit
                                type="text"
                                formControlName="companyWebsite"
                                class="form-control"
                                maxlength="100" />
                        </div>
                    </div>
                    <div class="col-md-6"></div>
                </div>
            </div>
        </div>

        <h3>Company Characteristics</h3>
        <div class="form-vertical">
            <div class="rows-striped" *ngIf="solutionCenter.companyInfo() as companyInfo">
                <div class="row-fluid">
                    <div class="col-md-12">
                        <label class="control-label">{{form.getFieldLabel('loanPrograms')}}</label>
                        <div [isEditable]="editing()">
                            <div class="label-tags control-value" view *ngIf="companyInfo.loanPrograms?.length; else dash;">
                                <span class="label-tag" *ngFor="let program of companyInfo.loanPrograms;">
                                    {{ program.name }}
                                </span>
                            </div>
                            <ng-select
                                edit
                                name="loanPrograms"
                                formControlName="loanPrograms"
                                [items]="loanPrograms | async"
                                [multiple]="true"
                                [clearable]="true"
                                [closeOnSelect]="false"
                                placeholder="Select Programs"
                                bindLabel="name">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row-fluid">
                    <div class="col-md-12">
                        <label class="control-label">{{form.getFieldLabel('tags')}}</label>
                        <div [isEditable]="editing()">
                            <div class="label-tags control-value" view *ngIf="companyInfo.tags?.length; else dash;">
                                <span class="label-tag" *ngFor="let tag of companyInfo.tags;">
                                    {{ tag.name }}
                                </span>
                            </div>
                            <ng-select
                                edit
                                name="tags"
                                formControlName="tags"
                                [items]="tags | async"
                                [multiple]="true"
                                [clearable]="true"
                                [closeOnSelect]="false"
                                placeholder="Select Tags"
                                bindLabel="name">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Comment until needed -->
        <div *ngIf="editing()" class="sticky-footer text-right z-index-9">
            <button type="submit" class="btn btn-primary">Publish</button>
            <a (click)="cancel()" class="btn btn-secondary">Cancel</a>
        </div>
    </form>
</div>

<ng-template #dash><div class="control-value">{{ null | empty }}</div></ng-template>
