<div class="gray-head">
    <h2>
        <span>Subscribe to {{ productPlan?.plan?.productName }}</span>
    </h2>
</div>

<div class="remote-resource products-subscription" [class.resolved]="resolved()">
    <div class="container">
        <div class="column">
            <product-plan-view
                [productPlan]="productPlan"
            >
            </product-plan-view>
        </div>
        <div class="column right-column">
           <product-plan-purchase
           [tpoId]="tpoId"
           [organizationId]="organizationId"
           [planId]="planId"
           [productId]="productId"
           [paymentMethods]="paymentMethods"
           [paymentMethodSelection]="paymentMethodSelection"
           (resolved)="setResolvedValue($event)"
           >
           </product-plan-purchase>
        </div>
     </div>
</div>
