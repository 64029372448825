import { Component, Input, OnInit } from '@angular/core';
import { TpoProductsService } from 'shared/tpo-products/tpo-products.service';
import {
    PLAN_PAYMENT_STATUSES_WITH_ACCESS,
    PlanPaymentStatus,
    TpoProductEntity,
    TpoProductEntityGroup,
} from 'shared/tpo-products/tpo-product.interface';
import { firstValueFrom } from 'rxjs';
import { UIRouterGlobals } from '@uirouter/core';
import { UserService } from 'angularjs-providers/user.provider';
import { AccountIdentifier } from 'shared/account/company-information/account-information.service';

@Component({
    templateUrl: 'tpo-product-add-ons.component.html',
})
export class TpoProductAddOnsComponent implements OnInit {

    @Input() accountIdentifier: AccountIdentifier;

    resolving: boolean;
    userCanAddEntity: boolean;

    tpoId: number;
    productId: number;

    entitiesGroupedByType: TpoProductEntityGroup[];

    constructor(
        private tpoProductsService: TpoProductsService,
        private uiRouter: UIRouterGlobals,
        private userService: UserService
    ) {
    }

    async ngOnInit() {
        try {
            this.resolving = true;
            this.tpoId = this.accountIdentifier.tpoId;
            this.productId = this.uiRouter.params.productId;
            await Promise.all([this.retrieveEntitiesGroupedByType(), this.retrieveUserCanEdit()]);
        } finally {
            this.resolving = false;
        }
    }

    async retrieveUserCanEdit() {
       const productDTO = await firstValueFrom(this.tpoProductsService.getProductByTpoId(this.tpoId, this.productId));
       this.userCanAddEntity = this.userService.profile.can('MANAGE_PRODUCTS')
           && PLAN_PAYMENT_STATUSES_WITH_ACCESS.includes(PlanPaymentStatus[productDTO?.planPaymentStatus]);
    }

    async retrieveEntitiesGroupedByType() {
        this.entitiesGroupedByType = await firstValueFrom(this.tpoProductsService.getEntitiesGroupedByType(this.tpoId, this.productId));
    }

    addNewEntity(entityTypeId: number) {
        let entityGroup = this.entitiesGroupedByType.find(group => group.entityTypeId === entityTypeId);
        entityGroup.entities.push({
            entityId: null,
            entityName: null,
            entityTypeId: entityTypeId,
            entityTypeName: null,
            organizationId: null,
            productId: null,
        });
    }

    updateEntity(updatedEntity: TpoProductEntity, entities: TpoProductEntity[], entityIndex: number) {
        entities[entityIndex] = updatedEntity;
    }

    removeEntityRow(entities: TpoProductEntity[], entityIndex: number) {
        entities.splice(entityIndex, 1);
    }
}
