import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';

import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { CrmSettings, UserInformation  } from './investor-marketing.interface';
import { LenderUsersResourceService } from '../users-resource.service';

@Component({
    templateUrl: './investor-marketing-visibility.component.html',
    selector: 'investor-marketing-visibility-form',
})
export class InvestorMarketingVisibilityComponent implements OnInit {

    public user: UserProfile;
    userList: Observable<UserInformation[]>;
    resolving: boolean;
    editing: boolean;
    isEditable: boolean;

    crmSettings: CrmSettings;

    organizationId: number;

    visibilityForm = new RealmFormGroup({
        enabled: new RealmFormControl(
            'enabled',
            {
                label: 'Visibility',
                updateOn: 'change',
            },
            Validators.required,
        ),
        designatedUsers: new RealmFormControl(
            'designatedUsers',
            {},
        ),
    })

    constructor(
        protected router: UIRouter,
        private investorMarketingService: LenderUsersResourceService,
        private userService: UserService,
    ) {
        this.user = this.userService.profile;
        this.organizationId = this.user.organization.organizationId;
        this.isEditable = this.editing;
    }

    async ngOnInit() {
        this.crmSettings = await this.investorMarketingService.getCrmSettings(this.organizationId).toPromise();
        this.visibilityForm.patchValue(this.crmSettings.designatedOptions);
        this.userList = this.investorMarketingService.getUsers(this.organizationId, { status: 'A' });
    }

    setEditing() {
        this.editing = true;
        this.isEditable = this.editing;
    }

    cancelEdit() {
        this.editing = false;
        this.isEditable = this.editing;
        this.visibilityForm.reset();
        this.visibilityForm.patchValue(this.crmSettings.designatedOptions);
    }

    async save() {
        const newSettings = this.crmSettings
        newSettings.designatedOptions = this.visibilityForm.value;

        try {
            this.resolving = true;
            this.crmSettings = await this.investorMarketingService.saveCrmSettings(this.organizationId, newSettings).toPromise();
            this.editing = false;
            this.isEditable = this.editing;
        } catch(e) {
            this.visibilityForm.setServerError(e);
        } finally {
            this.resolving = false;
        }
    }

    compareUsers(user1: UserInformation, user2: UserInformation) {
        return user1.id == user2.id;
    }
}
