<div class="content">
    <div *ngIf="!editing()" class="pull-right margin-bottom-sm">
        <button type="button" class="btn btn-primary" (click)="edit()">
            <span class="glyphicon glyphicon-pencil"></span> Edit
        </button>
    </div>
    <form [formGroup]="form" (onValidSubmit)="submit()">
        <form-errors [form]="form"></form-errors>
        <div class="info-block" *ngIf="editing()">
            <div class="pd10">
                <i class="c-icon c-icon-circle-exclamation"></i>
                &nbsp;
                <b>Recommended Image Size:</b> to fill the entire view, use an image with dimensions 2120 x 1060 px.
            </div>
        </div>

        <div class="row-fluid form-vertical" *ngIf="solutionCenter.overview() as overview">
            <div class="rows-white">
                <div class="row-fluid">
                    <div class="col-md-6">
                        <div class="flex-row space-between">
                            <label class="control-label">
                                {{form.getFieldLabel('originalMarketingLogo')}}
                            </label>
                            <div *ngIf="editing()">
                                <uploader #uploader [customTemplate]="fileTemplate" [config]="{ accept: '.png, .jpg, .jpeg, .webp' }" (select)="onLogoPick($event)">
                                    <ng-template #fileTemplate>
                                        <label class="text-link weight-normal" *ngIf="overview.marketingLogo">
                                            Replace Image&nbsp;
                                            <input type="file" (input)="uploader.onFileInput($event)" [multiple]="uploader.config.multiple" [accept]="uploader.config.accept">
                                        </label>
                                    </ng-template>
                                </uploader>
                                <a class="btn btn-icon btn-tertiary" (click)="removeLogo()" *ngIf="overview.marketingLogo">
                                    <span class="glyphicon glyphicon-trash"></span>
                                </a>
                            </div>
                        </div>

                        <div *ngIf="!overview.originalMarketingLogo && !editing()">
                            {{ null | empty }}
                        </div>

                        <div class="image-wrapper" *ngIf="overview.marketingLogo && !editing()">
                            <img [src]="overview.marketingLogo" />
                        </div>

                        <ng-container *ngIf="editing()">
                            <div [ngSwitch]="!overview.originalMarketingLogo">
                                <uploader #uploader [customTemplate]="fileTemplate" *ngSwitchCase="true" [config]="{ accept: '.png, .jpg, .jpeg, .webp' }" (select)="onLogoPick($event)">
                                    <ng-template #fileTemplate>
                                        <div [ngClass]="{'file-error': uploadLogoError }" class="uploader-container custom-uploader-container flex-row center gap" (fileDrop)="uploader.select.emit($event)">
                                            <label class="btn btn-secondary">
                                                Upload File
                                                <input
                                                    type="file"
                                                    (input)="uploader.onFileInput($event)"
                                                    [multiple]="uploader.config.multiple"
                                                    [accept]="uploader.config.accept">
                                            </label>
                                            <span class="text-muted">or Drag and Drop here</span>
                                        </div>
                                    </ng-template>
                                </uploader>
                                <ng-container *ngSwitchDefault>
                                    <realm-image-cropper
                                            [image]="overview.originalMarketingLogo"
                                            [crop]="overview.marketingLogoCoordinates"
                                            (cropChange)="onCropChange($event)"
                                            (update)="onCroppedLogoUpdate($event)"
                                            style="--aspect: 16/9;">
                                    </realm-image-cropper>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-md-6">
                        <div class="image-settings" *ngIf="editing() && overview.originalMarketingLogo">
                            <div class="mb20">
                                <label class="control-label">
                                    {{form.getFieldLabel('marketingLogoPageWidth')}}
                                </label>
                                <br>
                                <br>
                                <image-size-slider (sizeChanged)="onSizeChange($event)" [initialSize]="overview.marketingLogoPageWidth" />
                            </div>
                            <div class="mb20">
                                <label class="control-label">
                                    {{form.getFieldLabel('marketingLogoImagePosition')}}
                                </label>
                                <br>
                                <br>
                                <image-position [initialPosition]="overview.marketingLogoImagePosition" (positionChange)="onPositionChange($event)"></image-position>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rows-white">
                <div class="row-fluid">
                    <div class="col-md-12">
                        <div class="flex-row">
                            <label class="control-label">
                                {{form.getFieldLabel('description')}}
                                <span *ngIf="editing()" class="glyphicon glyphicon-question-sign text-medium" tooltip="Links must be formatted https://" placement="right"></span>
                            </label>
                        </div>
                        <div [ngSwitch]="editing()">
                            <ng-container *ngSwitchDefault>
                                <ng-container *ngIf="overview.description; else dash;">
                                    <quill-view [content]="overview.description" [sanitize]="true" theme="snow"></quill-view>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="true">
                                <quill-editor
                                    [styles]="{ height: '200px' }"
                                    formControlName="description"
                                    theme="snow">
                                </quill-editor>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Comment until needed -->
        <div *ngIf="editing()" class="sticky-footer text-right z-index-9">
            <button type="submit" class="btn btn-primary">Publish</button>
            <a (click)="cancel()" class="btn btn-secondary">Cancel</a>
        </div>
    </form>
</div>

<ng-template #dash><div class="control-value">{{ null | empty }}</div></ng-template>
