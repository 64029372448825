<h3>Order Summary</h3>
<div class="container inner-column">
    <div class="column">
        <span class="text-big text-left">{{ productPlan()?.plan?.recurlyPlanName }}</span>
    </div>
    <div class="column">
        <span class="text-big">${{ productPlan()?.plan?.pricePerBillingPeriod }} </span>
    </div>
</div>
<div class="container inner-column" *ngFor="let addOn of productPlan()?.addOns">
    <div class="column">
        <span class="text-light text-left">{{ addOn?.name }}</span>
    </div>
    <div class="column">
        <span class="text-light">${{ addOn?.pricePerUnit }} </span>
    </div>
</div>
<div class="charge-amount-container">
    <hr>
</div>
<div class="container inner-column">
    <div class="column">
        <span class="text-big text-left">Total</span>
    </div>
    <div class="column">
        <span class="text-big">${{ productPlan()?.totalPricePerBillingPeriod }} </span>
    </div>
</div>


