import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';

import { SideMenuContainerComponent } from 'commons/components/navigation/menu/side/side-menu-container.component';
import { TpoIssuesStatusService } from 'tpo/status';
import { TpoContactDetailsComponent } from 'tpo/people/contacts/details/tpo-contact-details.component';
import { FullTpoContact } from 'shared/new-contacts/contacts.interface';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PublishingPermissionsResourceService } from 'tpo/social-compliance/publishing-permissions';
import { PublisherRequestCount } from 'tpo/social-compliance/publishing-permissions/publisher-request.interface';
import { GenericSideMenuItem } from 'commons/components/navigation/menu/side/side-menu.component';

@Component({
    selector: 'tpo-contact-menu',
    templateUrl: '../../../../commons/components/navigation/menu/side/side-menu-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TpoContactMenuComponent extends SideMenuContainerComponent {
    private profile: UserProfile;
    private contact: FullTpoContact;
    private publisherRequests: Observable<boolean>;

    constructor(
        changeDetector: ChangeDetectorRef,
        router: UIRouterGlobals,
        { profile, profile$ }: UserService,
        private readonly tpoContactDetails: TpoContactDetailsComponent,
        private readonly tpoIssuesStatusService: TpoIssuesStatusService,
        private readonly publishingPermissionsResourceService: PublishingPermissionsResourceService,
    ) {
        super(changeDetector, router);

        this.menuUpdate$ = combineLatest([profile$, router.success$]).pipe(
            takeUntil(this.destroyed$),
        );
        this.profile = profile;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initAlerts();
        this.populateMenu();
        this.tpoContactDetails.contact.pipe(
            takeUntil(this.destroyed$),
        ).subscribe((contact) => {
            this.contact = contact;
            this.populateMenu();
        });
    }

    initAlerts(): void {
        this.publisherRequests = this.publishingPermissionsResourceService.individual.getRequestCount()
            .pipe(
                map((publisherRequestCount: PublisherRequestCount) => (publisherRequestCount.count > 0)),
                takeUntil(this.destroyed$),
            );
    }

    populateMenu = async () => {
        const thisRoute = 'people.contacts.:contactId';
        const { realmUserId } = this.contact || { realmUserId: Infinity };
        const { id } = this.profile;
        const isOwnContact = realmUserId === id;

        const showProducts: boolean = (
            !!realmUserId
            && this.contact?.isActive
            && (this.contact?.userStatus?.id !== 'I')
        );
        const productsMenuItems: GenericSideMenuItem[] = (
            showProducts ? [{
                title: 'Products',
                link: `${thisRoute}.products`,
            }] : []
        );

        this.menuItems = [
            {
                title: 'General Information',
                link: `${thisRoute}.information`,
            },
            {
                title: 'Social Accounts',
                items: [
                    {
                        title: 'Social Media',
                        link: `${thisRoute}.social-media`,
                    },
                    {
                        title: 'Web Presence',
                        link: `${thisRoute}.web-presence`,
                    },
                    {
                        title: 'Removed',
                        link: `${thisRoute}.removed`,
                    },
                ],
            },
            ...(this.profile.can('TPO_SMC_VIEW_ALL') ? [{
                title: 'Compliance',
                items: [
                    {
                        title: 'Audits',
                        link: `${thisRoute}.compliance.audits`,
                    },
                    {
                        title: 'Findings',
                        link: `${thisRoute}.compliance.findings`,
                    },
                ],
            }] : []),
            ...productsMenuItems,
            ...(this.profile.can('TPO_USERS') || isOwnContact? [{
                title: 'User Settings',
                link: `${thisRoute}.settings`,
            }] : []),
            ...(this.profile.can('TPO_USERS') ? [{
                title: 'Audit Log',
                link: `${thisRoute}.audit-log`,
            }] : []),
        ];
        this.changeDetector.detectChanges();
    };
}
