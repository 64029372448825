import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { CcmCustomerProductsNewService } from 'comergence/ccm-customer-product-new/ccm-customer-products-new.service';
import { UIRouterGlobals } from '@uirouter/core';
import { CcmCustomerProduct } from 'comergence/ccm-customer-product/ccm-customer-product.interface';
import { UserService } from 'angularjs-providers/user.provider';
import { firstValueFrom } from 'rxjs';
import {CcmCustomerProductNew} from "../ccm-customer-product-new.interface";

@Component({
    selector: 'ccm-customer-products',
    templateUrl: 'ccm-customer-products-new.component.html',
})
export class CcmCustomerProductsComponentNew implements OnInit {
    public customerType: string;
    public organizationId: number;
    resolved: boolean = false;
    public productList: WritableSignal<CcmCustomerProductNew[]> = signal<CcmCustomerProductNew[]>([]);
    userCanAddEdit: boolean = false;

    constructor(
        public ccmProductsService: CcmCustomerProductsNewService,
        public routerGlobals: UIRouterGlobals,
        private readonly userService: UserService,
    ) {
        this.customerType = this.routerGlobals.params.customerType;
        this.organizationId = this.routerGlobals.params.organizationId;
        this.userCanAddEdit = this.userService.profile.can('MANAGE_PRODUCTS');
    }

    async ngOnInit(): Promise<void> {
        this.loadProducts();
    }

    async loadProducts() {
        try {
            this.productList.set(await firstValueFrom(this.ccmProductsService.getCustomerProductDetails(this.organizationId)))
        } finally {
            this.resolved = true;
        }
    }
}
