import { RealmHttpClient } from "commons/services/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaymentMethod } from "./payment-methods.interface";
import { shareReplay } from "rxjs/operators";

declare let apiPath: string;

@Injectable()
export class PaymentMethodsService {
    constructor(
        private http: RealmHttpClient
    ) {}

    getPaymentMethods(tpoId: number, includeExpired: boolean): Observable<PaymentMethod[]> {
        return this.http.request<PaymentMethod[]>(
            'GET',
            `${apiPath}/tpos/${tpoId}/payment-methods?includeExpired=${includeExpired}`,
        ).pipe(
            shareReplay(1),
        );
    }
}
