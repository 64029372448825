import { PaymentMethodType } from 'tpo/payment-methods/payment-methods.interface';

export interface TpoProduct {
    productId: number;
    productName: string,
    companyName: string;
    planId: number;
    planName: string;
    planPaymentStatus: PlanPaymentStatus;
    paymentMethodType: PaymentMethodType;
    poNumber: string;
    costCenter: string;
    userCount: number;
    requestedCancellationDate: number;
    cancellationDate: number;
    purchaseDate: number;
    purchasedBy: string;
}

export enum PlanPaymentStatus {
    ACTIVE = 'Active',
    CANCELLED = 'Cancelled',
    PENDING_CANCELLATION = 'Pending Cancellation',
    SUSPENDED = 'Suspended',
    TERMINATED = 'Terminated',
}

export const PLAN_PAYMENT_STATUSES_WITH_ACCESS = [
 PlanPaymentStatus.ACTIVE, PlanPaymentStatus.PENDING_CANCELLATION
];

export const PLAN_PAYMENT_STATUSES_WITH_RE_SUBSCRIPTION_ACCESS = [
    PlanPaymentStatus.CANCELLED, PlanPaymentStatus.TERMINATED
];


export interface ProductPlan {
    planId: number;
    planName: string;
}

export interface TpoAccessibleProducts {
    productId: number;
    productName: string;
    productPlanList: ProductPlan[];
}

export interface TpoProductDetails {
    costCenter: string;
    paymentMethodType: PaymentMethodType;
    planId: number;
    poNumber: string;
}

export interface TpoProductEntity {
    entityId: number;
    entityName: string;
    entityTypeId: number;
    entityTypeName: string;
    organizationId: number;
    productId: number;
}

export interface TpoProductEntityGroup {
    entityTypeId: number;
    entityTypeName: string;
    entities: TpoProductEntity[];
}

export interface NewProductEntity {
    entityName: string;
    entityTypeId: number;
}

export interface UpdateProductEntity {
    entityName: string;
    entity: TpoProductEntity;
}

export interface CcmTpoProductUpdateRequestBody {
    planPaymentStatus: PlanPaymentStatus;
    paymentMethodType: PaymentMethodType;
    requestedCancellationDate: string;
}

export interface TpoProductUpdateRequestBody {
    poNumber: string;
    costCenter: string;
}

export interface TpoProductUpdateOptions {
    paymentMethodUpdateOptions: { [ key in keyof PaymentMethodType ]: PaymentMethodType[] };
    statusUpdateOptions: { [ key in keyof PlanPaymentStatus ]: PlanPaymentStatus[] };
    subscriptionStatusUpdateOptions: { [ key in keyof PlanPaymentStatus ]: PlanPaymentStatus[] };
}

export interface TpoProductPaymentMethodOption {
    value: PaymentMethodType;
    label: string;
}

export interface TpoProductStatusOption {
    value: PlanPaymentStatus;
    label: string;
}

export interface TpoOrganizationUser {
    userId: number;
    userName: string;
}
