import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CallToAction, SCProduct } from 'lender/solution-center/solution-center.service';
import { RealmFormControl, RealmFormGroup } from 'commons/forms';
import { UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MaxLengthValidator } from 'commons/validators';

@Component({
    templateUrl: './manage-product-modal.component.html',
})
export class ManageProductModalComponent implements OnInit {
    product: SCProduct;
    save: (params?) => any;
    resolving: boolean;

    protected readonly CallToAction = CallToAction;

    manageProductForm = new RealmFormGroup({
        email: new RealmFormControl(
            'email',
            {
                label: 'Lead Notification Email',
                updateOn: 'change',
            },
        ),
        productDescription: new RealmFormControl(
            'productDescription',
            {
                label: 'Product Description'
            },
            Validators.compose([ MaxLengthValidator(1000), Validators.required ]),
        ),
        productId: new RealmFormControl(
            'productId',
            {
                label: 'Product Id'
            }
        ),
        productName: new RealmFormControl(
            'productName',
            {
                label: 'Product Name'
            }
        ),
        type: new RealmFormControl(
            'type',
            {
                label: 'Call To Action',
                updateOn: 'change',
            }
        ),
        visibilityAllowedByCustomer: new RealmFormControl(
            'visibilityAllowedByCustomer',
            {
                label: 'Visibility Allowed By Customer'
            }
        ),
        visibilityAllowedByComergence: new RealmFormControl(
            'visibilityAllowedByComergence',
            {
                label: 'Visibility Allowed By Comergence'
            }
        ),
      },
        {
            validators: this.emailFieldValidator,
        }
    );

    constructor(public modalRef: BsModalRef) {}

    ngOnInit() {
        this.manageProductForm.patchValue(this.product);
    }

    onCancel() {
        this.modalRef.hide();
    }

    async onConfirm(productToUpdate: SCProduct) {
        if (productToUpdate.type === CallToAction.SIGN_UP) {
            productToUpdate.email = null;
        }

        try {
            this.resolving = true;
            await this.save(productToUpdate);
        } finally {
            this.resolving = false;
        }
    }

    emailFieldValidator(formGroup: UntypedFormGroup): ValidationErrors {
        const emailControl = formGroup.controls['email'];
        const typeControl = formGroup.controls['type'];

        if (typeControl.value === CallToAction.CONTACT_US) {
            emailControl.setValidators([Validators.required, Validators.email, MaxLengthValidator(100)]);
        } else {
            emailControl.markAsUntouched();
            emailControl.clearValidators();
        }
        return null;
    }
}
