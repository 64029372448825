import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function atLeastOneFieldValidator(fields: string[], messageTemplate: string = 'At least one of the fields ({fields}) is required'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const isValid = fields.some(field => !!control.get(field)?.value);

        if (!isValid) {
            return { atLeastOneFieldRequired: { message: messageTemplate.replace('{fields}', fields.join(', ')) } };
        }
        return null;
    };
}
