import { Component, EventEmitter, Input, Output, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'image-size-slider',
    templateUrl: './image-size-slider.component.html',
})
export class ImageSizeSliderComponent implements AfterViewInit {
    @Input() initialSize: number = 100;
    @Output() sizeChanged = new EventEmitter<number>();
    @ViewChild('rangeInput') rangeInput: ElementRef;
    size: number;

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.size = this.initialSize;
        this.updateBackground(this.rangeInput.nativeElement);
        this.cdr.detectChanges();
    }

    ngOnChanges() {
        if (this.rangeInput) {
            this.size = this.initialSize;
            this.updateBackground(this.rangeInput.nativeElement);
            this.cdr.detectChanges();
        }
    }

    onSizeChange(event: Event) {
        const input = event.target as HTMLInputElement;
        this.size = Number(input.value);
        this.sizeChanged.emit(this.size);
        this.updateBackground(input);
    }

    updateBackground(input: HTMLInputElement) {
        const value = (this.size - 30) / (100 - 30) * 100;
        input.style.background = `linear-gradient(to right, #66A1DD 0%, #66A1DD ${value}%, #F4F5F7 ${value}%, #F4F5F7 100%)`;
    }
}
