<div class="remote-resource" [class.resolved]="!resolving">
    <form *ngIf="!resolving" [formGroup]="updateProductForm" (onValidSubmit)="updateProduct()">
        <h3 class="flex-row">
            <span class="flex-grow">Billing Settings</span>
            <div *ngIf="canManageProducts" [isEditable]="editing">
                <button view type="button" class="btn btn-primary" (click)="enterEdit()">
                    <span class="glyphicon glyphicon-pencil small-icon"></span>  Edit
                </button>
                <ng-container edit>
                    <button type="submit" class="btn btn-primary" [disabled]="!valuesChanged()">Save</button>
                    <button type="button" class="btn btn-default" (click)="cancelEdit()">Cancel</button>
                </ng-container>
            </div>
        </h3>
        <form-errors [form]="updateProductForm"></form-errors>
        <div class="rows-striped">
            <div class="row-fluid">
                <div class="col-sm-2">
                    <label class="control-label">Company Name</label>
                </div>
                <div class="col-sm-3">
                    <span class="control-value">{{product.companyName}}</span>
                </div>

                <div class="col-sm-1"></div>

                <div class="col-sm-2">
                    <label class="control-label">Product</label>
                </div>
                <div class="col-sm-3">
                    <span class="control-value">{{product.productName}}</span>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-sm-2">
                    <label class="control-label">Plan Name</label>
                </div>
                <div class="col-sm-3">
                    <span class="control-value">{{product.planName}}</span>
                </div>

                <div class="col-sm-1"></div>

                <div class="col-sm-2">
                    <label class="control-label">Payment Method</label>
                </div>
                <div class="col-sm-3" [isEditable]="editing && isComergence">
                    <span view class="control-value">{{PaymentMethodTypes[product.paymentMethodType]?.display}}</span>
                    <ng-select *ngIf="isComergence"
                        edit
                        formControlName="paymentMethodType"
                        [items]="getPaymentMethodUpdateOptions()"
                        [trackByFn]="trackByPaymentMethodType"
                        (change)="clearCancellationDateOnPaymentMethodChange($event?.value)"
                        [clearable]="false"
                        bindValue="value"
                        bindLabel="label"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-sm-2">
                    <label class="control-label">Cost Center</label>
                </div>
                <div class="col-sm-3" [isEditable]="editing && !isComergence" [class.has-error]="hasError('costCenter')">
                    <span view class="control-value">{{product.costCenter || '&ndash;'}}</span>
                    <input edit type="text" class="form-control" name="costCenter" formControlName="costCenter">
                </div>

                <div class="col-sm-1"></div>

                <div class="col-sm-2">
                    <label class="control-label">Status</label>
                </div>
                <div class="col-sm-3" [isEditable]="editing && isComergence">
                    <span view class="control-value">{{PlanPaymentStatus[product.planPaymentStatus]}}</span>
                    <ng-select *ngIf="isComergence"
                        edit
                        formControlName="planPaymentStatus"
                        [items]="getStatusUpdateOptions()"
                        (change)="toggleCancellationDateBasedOnStatus($event?.value)"
                        [trackByFn]="trackByPlanPaymentStatus"
                        [clearable]="false"
                        bindValue="value"
                        bindLabel="label"
                    >
                    </ng-select>
                </div>
            </div>

            <div class="row-fluid">
                <div class="col-sm-2">
                    <label class="control-label">PO Number</label>
                </div>
                <div class="col-sm-3" [isEditable]="editing && !isComergence" [class.has-error]="hasError('poNumber')">
                    <span view class="control-value">{{product.poNumber || '&ndash;'}}</span>
                    <input edit type="text" class="form-control" name="poNumber" formControlName="poNumber">
                </div>

                <div class="col-sm-1"></div>

                <div class="col-sm-2">
                    <label class="control-label">Cancellation Date</label>
                </div>
                <div
                    class="col-sm-3"
                    [isEditable]="editing && isComergence"
                    [class.has-error]="hasError('requestedCancellationDate')"
                >
                    <span view class="control-value">{{getCancellationDate()?.toLocaleDateString() || '&ndash;'}}</span>
                    <input
                        edit
                        formControlName="requestedCancellationDate"
                        type="text"
                        class="form-control input-date"
                        bsDatepicker
                        data-date-type="string"
                        data-model-date-format="MM/dd/yyyy"
                        placeholder="mm/dd/yyyy"
                        [minDate]="getEarliestValidCancellationDate()"
                    />
                </div>
            </div>

            <div class="row-fluid">
                <div class="col-sm-2">
                    <label class="control-label">Purchased By</label>
                </div>
                <div class="col-sm-3">
                    <span class="control-value">{{getPurchasedBy() || '&ndash;'}}</span>
                </div>

                <div class="col-sm-1"></div>

                <div class="col-sm-2">
                    <label class="control-label">Purchase Date</label>
                </div>
                <div class="col-sm-3">
                    <span class="control-value">{{getPurchaseDate()?.toLocaleDateString() || '&ndash;'}}</span>
                </div>
            </div>

            <div class="row-fluid">
                <div class="col-sm-2">
                    <label class="control-label">Users</label>
                </div>
                <div class="col-sm-3">
                    <span class="control-value">{{product.userCount}}</span>
                </div>
                <div class="col-sm-6"></div>
            </div>
        </div>
    </form>
</div>
