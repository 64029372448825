<card [expanded]="expanded()">
    <span title>{{ channel().channelName }}</span>

    <ng-template persistent>
        <div class="form-row">
            <div class="form-cell">
                <label class="control-label">Relationship Status</label>
                <div>
                    <approval-status [status]="channel().approvalStatus"></approval-status>
                </div>
            </div>
            <div class="form-cell">
                <label class="control-label">Status Date</label>
                <div>
                    {{ channel().approvalStatusChangedDate | date: 'MM/dd/yyyy'| empty : minLength }}
                </div>
            </div>
        </div>
    </ng-template>

    <hr>

    <div class="form-row">
        <div class="form-cell">
            <label class="control-label">Invite Status</label>
            <div>
                <a uiSref="client.:id.account.channels.invite.:channelId.history" [aDisabled]="!channel().inviteStatus" [uiParams]="{ channelId: channel().channelId, id: clientId() }">
                    {{channel().inviteStatus?.name | empty }}
                </a>
            </div>
        </div>
        <div class="form-cell">
            <label class="control-label">Renewal Date</label>
            <div>
                {{ channel().approvalRenewalDate | date: 'MM/dd/yyyy' }}
            </div>
        </div>
    </div>

    <hr>

    <div class="form-row">
        <div class="form-cell">
            <label class="control-label">Assigned</label>
            <div>
                {{channel().accountExecutive?.name | empty : minLength }}
            </div>
        </div>
        <div class="form-cell">
            <label class="control-label">Status Reason</label>
            <div>
                {{ channel().statusReason?.reason | empty : minLength}}
            </div>
        </div>
    </div>

    <hr>

    <div class="form-row">
        <div class="form-cell">
            <label class="control-label">Channel UID</label>
            <div>
                {{channel().channelUID | empty : minLength }}
            </div>
        </div>
        <div class="form-cell">
            <label class="control-label">Channel SID</label>
            <div>
                {{ channel().channelSID | empty : minLength}}
            </div>
        </div>
    </div>

    <hr>

    <div class="form-row">
        <div class="form-cell">
            <label class="control-label">Eligible to Apply</label>
            <div *ngIf="inlineConfig('eligibilityDate') as cfg">
                <new-inline-edit #ie [editable]="canManageEligibility()" [name]="cfg.name" [control]="cfg.control" [value]="cfg.value" type="date" [options]="{ placeholder: 'mm/dd/yyyy', clearable: true, minDate: tomorrow }" (save)="patch($event, ie)">
                    {{channel().eligibilityDate | date: 'MM/dd/yyyy' | empty : minLength }}
                </new-inline-edit>
            </div>
        </div>
        <div class="form-cell">
            <label class="control-label">Prospecting</label>
            <div>
                <ng-container [ngSwitch]="channel().prospectStatusForUser">
                    <prospecting-status *ngSwitchDefault [canAdd]="false" [prospectInfo]="channel()"></prospecting-status>
                    <span *ngSwitchCase="'NONE'">&ndash;</span>
                </ng-container>
            </div>
        </div>
    </div>
</card>

