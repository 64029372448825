import { Component, inject, Input } from '@angular/core';
import { UserService } from 'angularjs-providers/user.provider';

declare let window;

@Component({
	selector: 'public-task-sign-in',
	templateUrl: './public.sign-in.component.html',
})
export class PublicSignInComponent {
    userService = inject(UserService);

	@Input()
	follow: string = null;

	constructor() {}

	signIn() {
		window.accessToken = null;
        this.userService.redirectToLogin(this.follow);
	}
}
