import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { SideMenuContainerComponent } from 'commons/components/navigation/menu/side/side-menu-container.component';
import { UIRouterGlobals } from '@uirouter/core';
import { UserProfile, UserService } from 'angularjs-providers/user.provider';
import { combineLatest, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TpoIssuesStatusService } from 'tpo/status';

@Component({
	selector: 'tpo-account-menu',
	templateUrl: '../../commons/components/navigation/menu/side/side-menu-container.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TpoAccountMenuComponent extends SideMenuContainerComponent {
	protected user$: Observable<UserProfile>;

	constructor(
		private readonly tpoIssuesStatusService: TpoIssuesStatusService,
		{ profile$ }: UserService,
		changeDetector: ChangeDetectorRef,
		router: UIRouterGlobals,
	) {
		super(changeDetector, router);
		this.user$ = profile$.asObservable();
		this.menuUpdate$ = combineLatest([profile$, router.success$]).pipe(
			takeUntil(this.destroyed$),
		);
	}

	ngOnInit() {
		super.ngOnInit();
		this.user$.pipe(
			takeUntil(this.destroyed$),
		).subscribe(this.populateMenu);
	}

	populateMenu = (user: UserProfile) => {
		const accountRoute = 'account';
		const relationshipRoute = `${accountRoute}.relationships`;
		const contactsRoute = `${accountRoute}.contacts`;
		const questionnairesRoute = `${accountRoute}.questionnaires`;
		const onboardingRoute = `${accountRoute}.onboarding.contacts`;
		const productionRoute = `${accountRoute}.production`;
		const financialsRoute = `${accountRoute}.financials`;
        const productsRoute = `${accountRoute}.products`;
		const { account } = this.tpoIssuesStatusService;

		this.menuItems = [
			{
				title: 'Account',
				link: {
					sref: `${accountRoute}.information`,
					exclude: [relationshipRoute, productionRoute, contactsRoute, questionnairesRoute, onboardingRoute],
				},
				items: [
					{ title: 'Company Information', link: `${accountRoute}.information` },
					{ title: 'DBA', link: `${accountRoute}.dba` },
					{ title: 'Federal Registration', link: `${accountRoute}.federal-registration` },
					{ title: 'Licenses', link: `${accountRoute}.licenses` },
					{ title: 'Locations', link: `${accountRoute}.locations` },
				],
			},

			...(!user.can('TPO_APPLICATIONS') ? [] : [
				{
					title: 'Account Information',
					link: `${relationshipRoute}.affiliates`,
					items: [
						{ title: 'Affiliates', link: `${relationshipRoute}.affiliates` },
						{ title: 'Agency', link: `${relationshipRoute}.agency` },
						{ title: 'Insurance & Bonds', link: `${relationshipRoute}.insuranceBonds` },
						{ title: 'Investors', link: `${relationshipRoute}.investors` },
						{ title: 'Technology & Operations', link: `${relationshipRoute}.tech-ops` },
						{ title: 'Underwriting', link: `${relationshipRoute}.underwriting` },
						{ title: 'Warehouse', link: `${relationshipRoute}.warehouse` },
					],
				},
			]),

			{
				title: 'Financials',
				link: `${financialsRoute}`,
				badge: 'Beta',
                hasAlert: this.tpoIssuesStatusService.financial.get(),
			},

			...(!user.can('TPO_VIEW_ONBOARDING') ? [] : [
				{
					title: 'Onboarding',
					link: `${onboardingRoute}`,
				},
			]),

			{
				title: 'Production',
                link: `${productionRoute}`,
			},

            {
                title: 'Products',
                link: `${productsRoute}`,
            },

			...(!user.can('TPO_APPLICATIONS') ? [] : [
				{
					title: 'Questionnaires',
					link: `${questionnairesRoute}`,
					hasAlert: account.questionnaires.get(),
				},
			]),
		];
		this.changeDetector.markForCheck();
	}
}
