import { NgModule } from '@angular/core';
import { RegistrationComponent } from 'login/registration/registration.component';
import { RegistrationService } from 'login/registration/registration.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from 'commons/directives/directives.module';
import { ComponentsModule } from 'commons/components/components.module';
import { CommonModule } from '@angular/common';
import {
    RegistrationFinalStepComponent,
} from './registration/registration-final-step/registration-final-step.component';
import { InvalidTokenComponent } from 'login/invalid-token/invalid-token.component';


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        ComponentsModule,
        CommonModule,
    ],
    declarations: [
        RegistrationComponent,
        RegistrationFinalStepComponent,
        InvalidTokenComponent,
    ],
    providers: [
        RegistrationService,
    ],
})
export class LoginModule {}
